import { FETCHTASK, FETCHSUBTASKSPEC, FETCHJOBSTATUS, ADDSUBTASK, FETCHSUBTASKTRACKER} from '../Actions/types';

const initialState = {
    SubTaskID: 0,
    SubTaskName: '',
    Description:'',
    TaskID: 0,
    StatusID:0,
    errSubTaskName: false,
    errTaskID: false,
    errDescription: false,
    errStatusID: false,
    Snack: false,
    SnackNegative: false,
    SnackTimer: false,
    SnackNegativeTimer: false,
    SnackTimerUpdate: false,
    isEd: false,
    subtaskpath: '',
    subtaskOpen: false,
    DisplaySubTasks: false,
    subtask:[],
    status: [],
    task:[],
    SubTaskType:[],
    Snacks: false,
    SnackNegatives: false,
    startdisST: false,
    closedisST: false,
    jobdisST: false,
    reasonST: '',
    reasondialogST: true,
    taskTrackerST: [],
    trackerdialogST: false,
    errReasonST: false,
    disable:false,
}

export default function red(state = initialState ,action)
{
    switch(action.type)
    {
        case ADDSUBTASK:
            return{
            ...state,
            [action.objName] : action.payload
        }
        case FETCHSUBTASKSPEC : 
            return{
                ...state , 
                subtask : action.payload
            }
        case FETCHTASK : 
            return{
                ...state , 
                task : action.payload
            }
        case FETCHJOBSTATUS : 
            return{
                ...state , 
                status : action.payload
            }
           
            case FETCHSUBTASKTRACKER : 
            return{
                ...state , 
                taskTrackerST : action.payload
            } 

            default:
                 return state ; 

    }

}