import React, { useEffect } from "react";
import { Container, Grid, Button, Paper, Dialog, DialogTitle, DialogContent, TextField, FormControl, InputLabel, Snackbar, DialogActions, DialogContentText, Select, MenuItem, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  jobAdd,
  custAdd,
  fetchCustomer,
  fetchJob,
  fetchJobType,
  fetchProject,
  fetchTeamAssigned,
  fetchReg,
  fetchTeam,
  teamNameAdd,
  fetchJobAll,
  fetchUsers,
  fetchTaskSpec,
  fetchSelectedJOb,
  fetchJobSP
} from "../../Actions/JobAction";
import { connect } from "react-redux";
import auth from "../../Authentication/auth";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TaskViewDialog from "./TaskViewDialog";
import { fetchProj } from "../../Actions/ProjectAction";
import { fetchHelper } from '../../RequetsHandler/FetchHandler';
import { fetchTask, taskAdd, } from '../../Actions/TaskAction';
import Tooltip from '@material-ui/core/Tooltip';
import UpdateIcon from '@material-ui/icons/Update';
import JobCust from "../../Pages/Job/JobCustomer";
import JobProj from "../../Pages/Job/JobProject";
import JobTeam from "../../Pages/SiteSurvey/MobileJobTeam";
import JobType from "../../Pages/Job/JobJobType";
import JobFleet from "../../Pages/Job/JobVehicle";
import AddBoxIcon from '@material-ui/icons/AddBox';

const SiteSurveyDisp = ({

  fetchTeamAssigned,
  tasks,
  fetchSelectedJOb,
  job,
  proj,
  fetchProj,
  fetchJobAll,
  fetchTask,
  dialogOpen,
  isEd,
  jobAdd,
  jobId,
  jobName,
  startTime,
  endTime,
  vehicle,
  delays,
  risk,
  weather,
  projId,
  custId,
  jobtypeId,
  teamassignedId,
  errjobName,
  errstartTime,
  errendTime,
  errdelays,
  errrisk,
  errweather,
  cust,
  jobtype,
  fleet,
  custName,
  teamName,
  dialogCust,
  dialogMobileTeam,
  custAdd,
  fetchCustomer,
  fetchJobType,
  fetchProject,
  addTeamAssigned,
  fetchTaskSpec,
  teamMem,
  fetchTeam,
  teams,
  teamNameAdd,
  fetchReg,
  errJobType,
  errTeamName,
  errProject,
  errCustomer,
  errVehicle,
  users,
  userId,
  errUserid,
  fetchUsers,
  jobpath,
  taskAdd,
  jobproj,
  dialogOpenjm,
  fetchJobSP,
  snack,
  snackNegative,
  fetchJob,
  jobuser,
  jobdis,
  notes,
  note,
  DialogNote,
  SnackNote,
  SnackNegativeNote,
  errnotes,
}) => {

  // for (let i = 0; i < job.length; i++) {
  //   console.log(job[i].jobid);
  //   console.log("jobid");
  // }

  // for (let i = 0; i < job.length; i++) {
  //   console.log(job[i].jobid);
  //   var jobids = job[i].jobid;
  // }

  useEffect(() => {
    if (auth.getRoleID() === 1) {
      fetchJobAll();
    } else {
      //console.log("in fetch respect");
      fetchJob((a) => {
        fetchTaskSpec(a);
      });
    }
    fetchUsers();
    fetchCustomer();
    fetchJobType();
    fetchProject();
    fetchTeamAssigned();
    fetchTeam();
    fetchReg();
    fetchProj();
    fetchJobAll();
    fetchTask();
    return () => { };
  }, [fetchJobAll, fetchJob, fetchTaskSpec, fetchUsers, fetchCustomer, fetchJobType, fetchProject, fetchTeamAssigned, fetchTeam, fetchReg, fetchProj, fetchTask]);

  const useStyles = makeStyles((theme) => ({
    table: {
      align: "center",
    },
    tableCell: {
      borderStyle: "none",
      width: 250,
    },
    tableHead: {
      paddingLeft: 10,
      borderStyle: "none",
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.primary,
    },
  }));
  const btnStyle = makeStyles((theme) => ({
    root: {
      width: "99%",
      margin: 2,
    },
  }));


  const classes = useStyles();
  const classBtn = btnStyle();
  const minDate = new Date().toISOString().split("T")[0];

  const changeValueJob = (e) => {
    jobAdd(e.target.name, e.target.value);
  };

  const changeValueTeam = (e) => {
    teamNameAdd(e.target.name, e.target.value);
  };
  const changeValueCust = (e) => {
    custAdd(e.target.name, e.target.value);
  };

  function customerSuccess() {
    jobAdd("DialogCust", false);
    fetchCustomer();
  }
  function customerFailure(e) {
    console.log(e)
  }

  const finaliseCustomer = async (CustomerName) => {

    fetchHelper.postData('api/Customer', CustomerName, customerSuccess, customerFailure);

  };

  const handleCust = () => {
    jobAdd("DialogCust", true);
  };

  const handleCustClose = () => {
    jobAdd("DialogCust", false);
  };

  const HandleCustAdd = () => {
    var customer = {
      customerid: 0,
      CustomerName: custName,
    };
    finaliseCustomer(customer);
  };

  function teamFailure() {

  }

  function TeamAssignedSuccess() {
    fetchTeam();
    jobAdd("DialogTeam", false);
  }

  function TeamAssignedFailure() {

  }


  const finaliseTeam = async (team, teamArr) => {

    fetchHelper.postData('api/team', team, (res) => {

      //       res.json().then(data => fetchHelper.postData(`api/TeamAssigned?TeamID=${data}`, teamArr, TeamAssignedSuccess, TeamAssignedFailure))

    }, teamFailure)

  };

  const HandleTeamOkay = (e) => {
    var values = addTeamAssigned.map((item) => item.teammemberid);

    teamNameAdd(e.target.name, teamName);

    var teams = {
      teamid: 0,
      teamname: teamName,
      userid: userId,
    };

    finaliseTeam(teams, values);
  };

  const HandleTeamClose = () => {
    jobAdd("DialogTeam", false);
  };

  const handleMobileTeam = () => {
    jobAdd("DialogTeam", true);
  };

  const ShowDialog = (a) => {
    fetchSelectedJOb(a);
    jobAdd("selectedShown", true);

  }
  const HandleClose = () => {
    jobAdd("isEd", false);
    jobAdd("dialogOpen", false);
    jobAdd("dialogOpenjm", false);
    taskAdd("taskOpen", false);
  };

  // const handleOpen = (jobId) => {
  //     taskAdd("taskOpen", true);
  //     fetchJob();
  //     taskAdd("TaskID", 0);
  //     taskAdd("TaskName", "");
  //     taskAdd("TaskType", "");
  //     taskAdd("EndTime", "");
  //     taskAdd("StartTime", "");
  //     taskAdd("StatusID", 0);
  //     taskAdd("JobID", jobId);
  // };



  function updateJobSuccess() {
    fetchJobAll();
    jobAdd("JobID", 0);
    jobAdd("JobName", "");
    jobAdd("StartTime", "");
    jobAdd("EndTime", "");
    jobAdd("Vehicle", 0);
    jobAdd("Delays", "");
    jobAdd("Risk", "");
    jobAdd("Weather", "");
    jobAdd("ProjectID", 0);
    jobAdd("CustomerID", 0);
    jobAdd("JobTypeID", 0);
    jobAdd("TeamAssignedID", 0);
    HandleClose();
    jobAdd("Snack", true);
  }
  function updateJobFailure() {
    jobAdd("SnackNegative", true);
  }


  const editJob = async (ab) => {

    fetchHelper.updateData('api/Job', ab, updateJobSuccess, updateJobFailure)

  };

  const handleLink = (row) => {
    jobAdd("JobID", row.jobid);
    jobAdd("JobName", row.jobname);
    jobAdd("StartTime", row.starttime);
    jobAdd("EndTime", row.endtime);
    jobAdd("Vehicle", row.vehicleid);
    jobAdd("Delays", row.delays);
    jobAdd("Risk", row.risk);
    jobAdd("Weather", row.weather);
    jobAdd("ProjectID", row.projectid);
    jobAdd("CustomerID", row.customerid);
    jobAdd("JobTypeID", row.jobtypeid);
    jobAdd("TeamAssignedID", row.teamid);
    jobAdd("isEd", true);
    jobAdd("dialogOpenjm", true);
    fetchCustomer();
    fetchJobType();
    fetchProject();
    fetchReg();
    fetchTeam();
  };

  const submitValue = () => {

    if (isEd) {
      if (!endTime) {
        jobAdd("errEndTime", true);
        jobAdd("SnackNegative", true);
        return;
      }

      if (!weather) {
        jobAdd("errWeather", true);
        jobAdd("SnackNegative", true);
        return;
      }

      if (!delays) {
        jobAdd("errDelays", true);
        jobAdd("SnackNegative", true);
        return;
      }

      if (!risk) {
        jobAdd("errRisk", true);
        jobAdd("SnackNegative", true);
        return;
      }

      var obj = {
        jobid: jobId,
        starttime: startTime,
        endtime: endTime,
        delays: delays,
        risk: risk,
        weather: weather,
        vehicleid: vehicle,
        projectid: projId,
        customerid: custId,
        jobtypeid: jobtypeId,
        teamid: teamassignedId,
        jobname: jobName,
        jobpath: jobpath
      };

      editJob(obj);
    }
  }


  const handleNoteClose = () => {
    jobAdd("errnotes", false);
    jobAdd("DialogNote", false);
  };


  const handleNoteOpen = (row) => {
    jobAdd("JobID", row.jobid);
    jobAdd("DialogNote", true);
  };


  function NoteSuccess() {

    jobAdd("DialogTeam", false);
    jobAdd("SnackNote", true);
    jobAdd("note", "");

  }

  function NoteFailure() {
    jobAdd("SnackNegativeNote", true);
  }


  const Notes = async (ab) => {

    fetchHelper.postData('api/Notes', ab, NoteSuccess, NoteFailure);

  };


  const submitValues = (row) => {

    if (note.trim() === "") {
      jobAdd("errnotes", true);
      jobAdd("SnackNegativeNote", true);
      return;
    }

    var obj = {
      noteId: 0,
      note: note,
      jobid: jobId,
    };

    Notes(obj);
    console.log(obj)

  }

  const changeValueNote = (e) => {
    //console.log(e.target.name)
    //console.log(e.target.value)
    jobAdd(e.target.name, e.target.value);
  };

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xl={12} sm={12} md={12}>

          <Accordion expanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={useStyles.heading} variant="h5">
                Jobs
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.root}>
                <Grid container spacing={1}>
                  {jobuser.map((row) => (
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Paper className={classes.paper}>

                        <Grid container spacing={1}>
                          <Button color="primary" variant="outlined" size="small" onClick={() => { ShowDialog(row.jobid) }} disabled={jobdis}> {row.jobname}</Button>
                        </Grid>

                        <Grid container spacing={2}>

                          <Grid item>
                            <b>Site:</b> {row.sitename}{" "}
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item>
                            <b>Weather:</b> {row.weather}{" "}
                          </Grid>
                          <Grid item>
                            <b>Delays:</b> {row.delays}{" "}
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item>
                            <b>Start Time:</b> {row.starttime}
                          </Grid>
                          <Grid item>
                            <b>End Time:</b> {row.endtime}
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item>
                            <b>Risk:</b> {row.risk}
                          </Grid>
                          <Grid item>
                            <b>Type:</b> {row.jobtype}
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item>
                            <b>Project:</b> {row.projname}
                          </Grid>
                          <Grid item>
                            <b>Customer:</b> {row.custname}
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item>
                            <b>Team:</b> {row.teamname}
                          </Grid>
                          <Grid item>
                            <b>Registration:</b> {row.registration}
                          </Grid>
                          <Tooltip title="Update Job"><UpdateIcon color="primary" onClick={() => { handleLink(row) }} /></Tooltip>
                          <Tooltip title="Add Notes"><AddBoxIcon color="primary" onClick={() => { handleNoteOpen(row) }} /></Tooltip>
                        </Grid>
                        {/* <Grid container spacing={2}>
                          <Grid item>
                            <Link href={row.jobpath}>
                            <b>Upload Docs</b>  
                            </Link>
                            <input id="pic" type="file" >
                            </input>
                            
                            <Button id="uploadBtn" >
                              Upload File
                            </Button>
                            <Button
                              fullWidth
                              onClick={upload}
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                handleLink(row);
                              }}
                            >
                              Document
                            </Button>
                          </Grid>
                        </Grid> */}
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion>

          {/* <Accordion expanded={true}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={useStyles.heading} variant="h5">
                Tasks
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.root}>
                <Grid container spacing={1}>
                  {tasks.map((row) => (
                    <Grid item xs={12} sm={12} md={6} lg={4}>
                      <Paper className={classes.paper}>
                        <h4> {row.taskname}</h4>
                        <Grid container spacing={2}>
                          <Grid item>
                            <b>Job:</b> {row.jobname}{" "}
                          </Grid>
                          <Grid item>
                            <b>Job Type:</b> {row.tasktype}{" "}
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid item>
                            <b>Start Time:</b> {row.starttime}
                          </Grid>
                          <Grid item>
                            <b>End Time:</b> {row.endtime}
                          </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                          <Grid item>
                            <b>Status:</b> {row.status}
                          </Grid>
                          <Grid item>
                          <Link href={row.taskpath}>
                          <b>Upload Docs</b> 
                            </Link>
                            {/* <Button
                              fullWidth
                              color="primary"
                              variant="contained"
                              onClick={() => {
                                handleLink(row);
                              }}
                            >
                              Document
                            </Button> */}
          {/* </Grid>
                        </Grid>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </div>
            </AccordionDetails>
          </Accordion> */}
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={snack}
            autoHideDuration={6000}
            onClose={() => {
              jobAdd("Snack", false);
            }}
            message="Job has been added"
            action={<React.Fragment></React.Fragment>}
          />

          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={snackNegative}
            onClose={() => {
              jobAdd("SnackNegative", false);
            }}
            autoHideDuration={6000}
            message="The Job could not be added"
            action={<React.Fragment></React.Fragment>}
          />
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={SnackNote}
            onClose={() => {
              jobAdd("SnackNote", false);
            }}
            autoHideDuration={6000}
            message="Notes Added"
            action={<React.Fragment></React.Fragment>}
          />
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            open={SnackNegativeNote}
            onClose={() => {
              jobAdd("SnackNegativeNote", false);
            }}
            autoHideDuration={6000}
            message="Notes Not Added"
            action={<React.Fragment></React.Fragment>}
          />

          <Dialog name="DialogOpen" open={dialogOpenjm} onClose={HandleClose}>
            <DialogTitle>Job</DialogTitle>
            <DialogContent>
              <Grid container spacing={2}>


                <Grid item xs={6}>
                  <TextField
                    name="JobName"
                    onChange={changeValueJob}
                    className={classBtn.root}
                    error={errjobName}
                    label="Job Name"
                    variant="standard"
                    color="primary"
                    placeholder="Job Name"
                    required
                    helperText={errjobName ? "Please enter Job Name " : ""}
                    value={jobName}
                    disabled={isEd}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="StartTime"
                    type="date"
                    onChange={changeValueJob}
                    value={startTime}
                    fullWidth
                    error={errstartTime}
                    inputProps={{ min: minDate }}
                    label="Start Time"
                    variant="standard"
                    color="primary"
                    required
                    placeholder="Start Time"
                    helperText={errstartTime ? "Please select Start Date " : ""}
                    disabled={isEd}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    name="EndTime"
                    type="date"
                    onChange={changeValueJob}
                    value={endTime}
                    fullWidth
                    inputProps={{ min: minDate }}
                    error={errendTime}
                    label="End Time"
                    variant="standard"
                    color="primary"
                    placeholder="End Time"
                    helperText={errendTime ? "Please select End Date " : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="Weather"
                    onChange={changeValueJob}
                    error={errweather}
                    label="Weather"
                    variant="standard"
                    color="primary"
                    placeholder="Weather"
                    helperText={errweather ? "Please enter Weather " : ""}
                    value={weather}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="Delays"
                    onChange={changeValueJob}
                    error={errdelays}
                    className={classBtn.root}
                    label="Delays"
                    variant="standard"
                    color="primary"
                    placeholder="Delays"
                    helperText={errdelays ? "Please enter Delays " : ""}
                    value={delays}
                  />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    name="Risk"
                    onChange={changeValueJob}
                    error={errrisk}
                    className={classBtn.root}
                    label="Risks"
                    variant="standard"
                    color="primary"
                    placeholder="Risks"
                    helperText={errrisk ? "Please enter Risks " : ""}
                    value={risk}
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel error={errVehicle} required>
                      Vehicles
                    </InputLabel>

                    <JobFleet cList={fleet} onCh={changeValueJob} />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel error={errJobType}>Job Type</InputLabel>

                    <JobType cList={jobtype} onCh={changeValueJob} />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>

                  <InputLabel error={errTeamName}>Team Name</InputLabel>

                  <JobTeam disabled={isEd} cList={teams} onCh={changeValueJob} />


                  <Button
                    disabled={isEd}
                    onClick={handleMobileTeam}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ textTransform: "none" }}
                    className="mt-8"
                  >
                    Add new Team{" "}
                  </Button>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel error={errProject}>Project</InputLabel>

                    <JobProj cList={proj} onCh={changeValueJob} />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel error={errCustomer}>Client</InputLabel>

                    <JobCust cList={cust} onCh={changeValueJob} />
                  </FormControl>
                  <Button
                    disabled={isEd}
                    onClick={handleCust}
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ textTransform: "none" }}
                    className="mt-2"
                  >
                    Add new Client{" "}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <Button color="primary" onClick={HandleClose}>
                Cancel
              </Button>

              <Button
                onClick={submitValue}
                color="primary"
                variant="contained"
                style={{ textTransform: "none" }}
              >
                Save
              </Button>
            </DialogActions>
          </Dialog>



          <Dialog name="DialogCust" open={dialogCust} onClose={handleCustClose}>
            <DialogTitle>Add Customer</DialogTitle>
            <DialogContent>
              <DialogContentText>Customer</DialogContentText>

              <TextField
                name="CustomerName"
                onChange={changeValueCust}
                className={classBtn.root}
                label="Enter new Customer"
                variant="standard"
                color="primary"
                placeholder="Enter new Customer "
              />
            </DialogContent>

            <DialogActions>
              <Button color="primary" onClick={handleCustClose}>
                Cancel
              </Button>

              <Button color="primary" onClick={HandleCustAdd}>
                Finalise
              </Button>
            </DialogActions>
          </Dialog>



          <Dialog open={DialogNote} onClose={handleNoteClose} fullWidth xl>

            <DialogContent>


              <Paper style={{ margin: 10, padding: 5 }}>


                <Grid>
                  <TextField
                    name="note"
                    onChange={changeValueNote}
                    label="Notes"
                    variant="standard"
                    color="primary"
                    placeholder="Notes"
                    error={errnotes}
                    helperText={errnotes ? "please enter a value " : ""}
                  >
                    Notes
                  </TextField>
                </Grid>
                <Grid>
                  <Button
                    onClick={async () => { submitValues() }}
                    fullWidth
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "none" }}
                  // error={eReasonST}
                  // helperText={eReasonST ? "please enter a value " : ""}
                  >
                    Submit
                  </Button>
                </Grid>

              </Paper>

            </DialogContent>
            <DialogActions>
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={handleNoteClose}
              >
                Close</Button>
            </DialogActions>



          </Dialog>

        </Grid>
      </Grid>
      <TaskViewDialog />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  proj: state.projRed.Project,
  job: state.jobRed.job,
  jobproj: state.jobRed.jobproj,
  task: state.taskRed.task,
  dialogOpen: state.jobRed.dialogOpen,
  isEd: state.jobRed.isEd,
  jobId: state.jobRed.JobID,
  jobName: state.jobRed.JobName,
  startTime: state.jobRed.StartTime,
  endTime: state.jobRed.EndTime,
  vehicle: state.jobRed.Vehicle,
  delays: state.jobRed.Delays,
  risk: state.jobRed.Risk,
  weather: state.jobRed.Weather,
  projId: state.jobRed.ProjectID,
  custId: state.jobRed.CustomerID,
  jobtypeId: state.jobRed.JobTypeID,
  teamassignedId: state.jobRed.TeamAssignedID,
  errjobName: state.jobRed.errJobName,
  errstartTime: state.jobRed.errStartTime,
  errendTime: state.jobRed.errEndTime,
  errvehicle: state.jobRed.errVehicle,
  errdelays: state.jobRed.errDelays,
  errrisk: state.jobRed.errRisk,
  errweather: state.jobRed.errWeather,
  cust: state.jobRed.cust,
  jobtype: state.jobRed.jobType,
  teamassigned: state.jobRed.team,
  custName: state.jobRed.CustomerName,
  dialogCust: state.jobRed.DialogCust,
  snack: state.jobRed.Snack,
  snackNegative: state.jobRed.SnackNegative,
  fleet: state.jobRed.fleet,
  teamMem: state.teamMemRed.TeamMem,
  dialogMobileTeam: state.jobRed.DialogMobileTeam,
  addTeamAssigned: state.jobRed.addTeamAssigned,
  teamName: state.jobRed.TeamName,
  teams: state.jobRed.teams,
  errJobType: state.jobRed.errJobType,
  errTeamName: state.jobRed.errTeamName,
  errProject: state.jobRed.errProject,
  errCustomer: state.jobRed.errCustomer,
  errVehicle: state.jobRed.errVehicle,
  users: state.jobRed.users,
  userId: state.jobRed.UserID,
  errUserid: state.jobRed.errUserID,
  docpath: state.jobRed.docpath,
  jobpath: state.jobRed.jobpath,
  taskOpen: state.taskRed.taskOpen,
  tasks: state.jobRed.tasks,
  DisplayJobs: state.jobRed.DisplayJobs,
  DisplayTasks: state.taskRed.DisplayTasks,
  selectedShown: state.jobRed.selectedShown,
  dialogOpenjm: state.jobRed.dialogOpenjm,
  jobuser: state.jobRed.jobuser,
  jobdis: state.taskRed.jobdis,
  notes: state.jobRed.notes,
  note: state.jobRed.note,
  DialogNote: state.jobRed.DialogNote,
  SnackNote: state.jobRed.SnackNote,
  SnackNegativeNote: state.jobRed.SnackNegativeNote,
  errnotes: state.jobRed.errnotes,
});

export default connect(mapStateToProps, {
  fetchTaskSpec,
  jobAdd,
  custAdd,
  fetchCustomer,
  fetchJob,
  fetchJobType,
  fetchProject,
  fetchTeamAssigned,
  fetchReg,
  fetchTeam,
  teamNameAdd,
  fetchJobAll,
  fetchUsers,
  fetchSelectedJOb,
  fetchProj,
  fetchTask,
  taskAdd,
  fetchJobSP,
})(SiteSurveyDisp);
