import {ADDTASK, FETCHTASK, FETCHJOBTASK, FETCHJOBSTATUS, FETCHTASKTRACKER} from '../Actions/types';
import {API_ADDR} from '../DatabaseStrings/dbStrings';
import auth from '../Authentication/auth'

export const taskAdd = (objName, value) => dispatch => {
    dispatch({
        type: ADDTASK,
        payload: value,
        objName: objName
    });
}

export const fetchTask = () => async (dispatch) =>{
    
    fetch(`${API_ADDR}/api/Task`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHTASK , 
            payload: data
        })).catch(err => console.error(err))
}

export const fetchJob = () => async (dispatch) =>{
    
    fetch(`${API_ADDR}/api/JobAll`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHJOBTASK , 
            payload: data
        })).catch(err => console.error(err))
}

export const fetchStatus = () => async (dispatch) =>{
    
    fetch(`${API_ADDR}/api/JobStatus`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHJOBSTATUS , 
            payload: data
        })).catch(err => console.error(err))
}

export const fetchTaskTracker = (taskid) => async (dispatch) =>{
    
    fetch(`${API_ADDR}/api/TaskTracker?Taskid=${taskid}`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHTASKTRACKER , 
            payload: data
        })).catch(err => console.error(err))
}