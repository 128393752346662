import React, { useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  InputLabel,
  Snackbar,
  TextField,
  Typography,
  Paper,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  serviceAdd,
  fetchServices,
  fetchServiceTypes,
  fetchCFM,
} from "../../Actions/ServiceAction";
import ServiceDisplay from "./ServiceDisplay";
import ServiceTypeDialog from "./ServiceTypeDialog";
import CFMDrop from "./CompanyFleetModelDD";
import ServiceTypeDrop from "./ServiceTypeDD";
import { fetchHelper } from '../../RequetsHandler/FetchHandler';

const Services = ({
  details,
  dueDates,
  fetchCFM,
  fetchServices,
  fetchServiceTypes,
  vserviceTyepId,
  vcfmId,
  vCfmArr,
  vServiceTypesArr,
  vServiceArr,
  serviceAdd,
  eDate,
  eDetails,
  snack,
  snacknegative,
  sid,
  isEd
}) => {


  const cval = (e) => {
    serviceAdd(e.target.name, e.target.value);
    if ((e.target.value === "") | (e.target.value === 0)) {
      serviceAdd(`err${e.target.name}`, true);
    } else {
      serviceAdd(`err${e.target.name}`, false);
    }
  };

  function updateServiceSuccess() {
    fetchServices();
    serviceAdd("snack", true);
    serviceAdd("isEditing", false);
    serviceAdd("serviceID", 0);
    serviceAdd("details", "");
    serviceAdd("dueDate", "");
    serviceAdd("serviceTypeId", 0);
    serviceAdd("comppanyFleetModelId", 0);
  }

  function updateServiceFailure() {
    serviceAdd("snacknegative", true);
  }


  function ServicesSuccess() {
    fetchServices();
    serviceAdd("snack", true);
    serviceAdd("isEditing", false);
    serviceAdd("details", "");
    serviceAdd("dueDate", "");
    serviceAdd("serviceTypeId", 0);
    serviceAdd("comppanyFleetModelId", 0);
  }

  function ServicesFailure() {
    serviceAdd("snacknegative", true);
  }



  const updateService = async (objUpdate) => {

    fetchHelper.updateData('api/services', objUpdate, updateServiceSuccess, updateServiceFailure);

  };

  const addService = async (objAdd) => {
    fetchHelper.postData('api/services', objAdd, ServicesSuccess, ServicesFailure);
  };



  const serviceAdder = () => {
    if (
      (details !== "") &
      (dueDates !== "") &
      (vserviceTyepId !== 0) &
      (vcfmId !== 0)
    ) {
      //console.log(isEd)
      if (isEd) {
        if ((details.trim() === "") | (!(/^[0-9a-z A-Z]*$/.test(details)))) {
          serviceAdd("errdetails", true);
          serviceAdd("snacknegative", true);
          return;
        }
        if (dueDates.trim() === "") {
          serviceAdd("errdueDate", true);
          serviceAdd("snacknegative", true);
          return;
        }
        if (vserviceTyepId === 0) {
          serviceAdd("errserviceTypeId", true);
          serviceAdd("snacknegative", true);
          return;
        }
        if (vcfmId === 0) {
          serviceAdd("errcomppanyFleetModelId", true);
          serviceAdd("snacknegative", true);
          return;
        }
        var objUpdate = {
          serviceID: sid,
          details: details,
          dueDate: new Date(dueDates).toISOString(),
          servicetypeID: vserviceTyepId,
          companyFleetModelID: vcfmId,
        };
        console.log('we on the patch commented');

        updateService(objUpdate);
        serviceAdd("isEditing", true);
      } else {


        var objAdd = {
          serviceID: 0,
          details: details,
          dueDate: dueDates,
          serviceTypeID: vserviceTyepId,
          companyFleetModelID: vcfmId,
        };

        addService(objAdd);

      }
    }
    else {
      if ((details.trim() === "") | (!(/^[0-9a-z A-Z]*$/.test(details)))) {
        serviceAdd("errdetails", true);
        serviceAdd("snacknegative", true);
        return;
      }
      if (dueDates.trim() === "") {
        serviceAdd("errdueDate", true);
        serviceAdd("snacknegative", true);
        return;
      }
      if (vserviceTyepId === 0) {
        serviceAdd("errserviceTypeId", true);
        serviceAdd("snacknegative", true);
        return;
      }
      if (vcfmId === 0) {
        serviceAdd("errcomppanyFleetModelId", true);
        serviceAdd("snacknegative", true);
        return;
      }

      serviceAdd("snacknegative", true);
    }
  };



  const handleServiceOpen = () => {
    serviceAdd("vServiceOpen", true);
  };

  useEffect(() => {

    fetchServices();
    fetchCFM();
    fetchServiceTypes();
    // console.log(vServiceTypesArr);
    // console.log(vServiceArr);
    // console.log(vCfmArr);
    return () => { };
  }, [fetchServices,fetchCFM,fetchServiceTypes]);



  const minDate = new Date().toISOString().split("T")[0];

  return (
    <Container maxWidth="xl">
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => {
          serviceAdd("snack", false);
        }}
        message="Service has been added"
        action={<React.Fragment></React.Fragment>}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snacknegative}
        onClose={() => {
          serviceAdd("snacknegative", false);
        }}
        autoHideDuration={6000}
        message="The Service could not be added"
        action={<React.Fragment></React.Fragment>}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} style={{ padding: 8 }}>
          <Typography variant="h3">Add Service</Typography>
        </Grid>

        <Grid item xs={9}>
          <Paper>
            <ServiceDisplay></ServiceDisplay>
          </Paper>
        </Grid>

        <Grid item xs={3}>
          <Paper>
            <Grid Container>
              <Grid item xs={12} style={{ padding: 8 }}>
                <TextField
                  error={eDetails}
                  helperText={eDetails ? "This field is required " : ""}
                  value={details}
                  name="details"
                  label="Details"
                  variant="standard"
                  fullWidth={true}
                  placeholder="Details"
                  onChange={cval}
                />
              </Grid>

              <Grid item xs={12} style={{ padding: 8 }}>
                <InputLabel>Due Date</InputLabel>
                <TextField
                  error={eDate}
                  value={dueDates}
                  helperText={eDate ? "This field is required " : ""}
                  type="date"
                  inputProps={{ min: minDate }}
                  name="dueDate"
                  variant="standard"
                  fullWidth={true}
                  onChange={cval}
                />
              </Grid>

              <Grid item xs={12} style={{ padding: 8 }}>
                <InputLabel style={{ marginLeft: 10 }} fullWidth={true}>
                  Company Fleet Model
                  </InputLabel>
                <CFMDrop arr={vCfmArr} onCh={cval}></CFMDrop>
              </Grid>

              <Grid item xs={12} style={{ padding: 8 }}>
                
                  <InputLabel style={{ marginLeft: 10 }} fullWidth={true}>
                    Service Type
                  </InputLabel>
                  <ServiceTypeDrop
                    arr={vServiceTypesArr}
                    onCh={cval}
                  ></ServiceTypeDrop>
                
              </Grid>

              <Grid item xs={12} style={{ padding: 8 }}>
                <Button
                  onClick={handleServiceOpen}
                  color="primary"
                  variant="standard"
                  fullWidth={true}
                >
                  Add Service Type
                </Button>
              </Grid>

              <Grid item xs={12} style={{ padding: 8 }}>
                <Button
                  color="primary"
                  variant="contained"
                  fullWidth={true}
                  onClick={serviceAdder}
                >
                  Add Service
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <ServiceTypeDialog />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  eDate: state.service.errdueDate,
  eDetails: state.service.errdetails,

  details: state.service.details,
  dueDates: state.service.dueDate,
  vserviceTyepId: state.service.serviceTypeId,
  vcfmId: state.service.comppanyFleetModelId,
  vCfmArr: state.service.vCFM,
  vServiceTypesArr: state.service.vServiceType,
  vServiceArr: state.service.vServiceDetails,
  snack: state.service.snack,
  snacknegative: state.service.snacknegative,
  sid: state.service.serviceID,
  isEd: state.service.isEditing,
});

export default connect(mapStateToProps, {
  serviceAdd,
  fetchCFM,
  fetchServiceTypes,
  fetchServices,
})(Services);
