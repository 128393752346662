import {createStore , applyMiddleware} from 'redux';
import thunk   from 'redux-thunk';
import rootReducer from '../reducers';

const intitialState ={} ;
const middleWare  = [thunk] ;

const store = createStore(rootReducer ,  intitialState , applyMiddleware(...middleWare))

export default store ; 
