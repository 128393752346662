import React, { useEffect } from "react";
import {
  Container,
  Grid,
  Button
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { DataGrid } from "@material-ui/data-grid";
import { connect } from "react-redux";
import { fetchDrivers } from "../../Actions/DriverDetailsAction";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Driver from "../../Drivers/Driver";
import { entryValue } from "../../Actions/DriverAction";

const Drivers = ({
  drive,
  fetchDrivers,
  entryValue,
}) => {

  const rows = drive.map((row) => {
    
    return {
     
      id: row.driverID,
      name: row.name,
      surname: row.surname,
      idNumber: row.idNumber,  
      phoneNumber: row.phoneNumber,
      address: row.address,
      licenseExpiry: row.licenseExpiry,
      pdpExpiry: row.pdpExpiry,
      dgdpExpiry: row.dgdpExpiry,
      edit: row,
      
    };
  });
  const columns = [
    { field: "driverID", hide: true },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "surname",
      headerName: "Surname",
      width: 200,
    },
    {
      field: "idNumber",
      headerName: "Id",
      width: 200,
      hide: true,
    },
    {
      field: "address",
      headerName: "Address",
      width: 200,
      hide: true,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      width: 200,
    },
    {
      field: "licenseExpiry",
      headerName: "License Expiry",
      width: 200,
      type: "date",
    },
    {
      field: "pdpExpiry",
      headerName: "PDP Expiry",
      width: 200,
      type: "date",
    },
    {
      field: "dgdpExpiry",
      headerName: "DGDP Expiry",
      width: 200,
      type: "date",
    },

    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <strong>
            <Button
              onClick={() => {
                editField(params.value);
              }}
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
            >
              Edit
            </Button>
          </strong>
        );
      },
    },
  ];

  useEffect(() => {
    fetchDrivers();
    return () => {};
  }, [fetchDrivers]);

  //to update
  const editField = (row) => {
    entryValue("DriverID", row.id);
    entryValue("Name", row.name);
    entryValue("Surname", row.surname);
    entryValue("IdNo", row.idNumber);
    entryValue("PdpExp",new Date(row.pdpExpiry).toISOString().substr(0, new Date(row.pdpExpiry).toISOString().indexOf("T")));
    entryValue("Address", row.address);
    entryValue("PhNumber", row.phoneNumber);
    entryValue("LicExp",new Date(row.licenseExpiry).toISOString().substr(0, new Date(row.licenseExpiry).toISOString().indexOf("T")));
    entryValue("DgdpExp",new Date(row.dgdpExpiry).toISOString().substr(0, new Date(row.dgdpExpiry).toISOString().indexOf("T")));
    entryValue("isEditing", true);
  };

  


  return (
    <Container maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">
            {" "}
            <Box fontWeight="fontWeightBold">Driver Records </Box>
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <div style={{ display: "flex", height: "100%" }}>
            <Paper elevation={5} />
            <div style={{ flexGrow: 1, height: "80vh" }}>
              <DataGrid rows={rows} columns={columns} />
            </div>
            <Paper />
          </div>
        </Grid>

        <Grid item xs={3}>
          <Paper style={{ padding: 10 }}>
            <Driver></Driver>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  drive: state.driverDetails.driverDetails,
  dName: state.driverDetails.Name,
  dSurname: state.driverDetails.Surname,
  dIdNo: state.driverDetails.IdNo,
  dPdpExp: state.driverDetails.PdpExp,
  dAddress: state.driverDetails.Address,
  dPhNumber: state.driverDetails.PhNumber,
  dLicExp: state.driverDetails.LicExp,
  dDgdpExp: state.driverDetails.DgdpExp,
});

export default connect(mapStateToProps, { fetchDrivers, entryValue })(Drivers);
