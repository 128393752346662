import { LOGIN_USER, LOGIN_USER_FAILED, SHOW_LOADER, HIDE_LOADER} from "./types";
import auth from "../Authentication/auth";

export const loginvalue = (objName, value) => (dispatch) => {
  dispatch({
    type: LOGIN_USER,
    payload: value,
    objName: objName,
  });
};

export const failLogin = (objName, value) => (dispatch) => {
  console.log(objName, value);
  dispatch({
    type: LOGIN_USER_FAILED,
    payload: value,
    objName: objName,
  });
};

export const showLoader = () => dispatch => {
  dispatch({
    type: SHOW_LOADER
  })
}

export const hideLoader = () => dispatch => {
  dispatch({
    type: HIDE_LOADER
  })
}

export const fetchLogin = (aUser) => (dispatch) => {
  fetch("https://160.119.252.81:443/api/Login/authenticate", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(aUser),
  })
    .then((res) => res.json())
    .then((data) => {
      var value = data;

      dispatch({
        type: LOGIN_USER,
        payload: value,
      });

      auth.authenticated = true;
    });
};
