import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { fetchVehicleType, vehicelEdit, fecthMake } from '../../Actions/vehicleAction';
import {fetchHelper} from '../../RequetsHandler/FetchHandler';

const VehicleMakesDialog = ({
    vmName,
    vOpen,
    vehicelEdit,
    fetchVehicleType,
    fecthMake

}) => {

    const HandleRoleClose = () => {

        vehicelEdit("vMakeOpen", false);

    }
    
function VehicleMakesSuccess(){
    fetchVehicleType();
    fecthMake();
    HandleRoleClose();
}
function VehicleMakesFailure(){

}


    const handleMakeAdd = () => {


        if (vmName.trim() !== "") {




            //var myHeaders = new Headers();

           // myHeaders.append("Content-Type", "application/json");

            var raw = { "makeID": 0, "makeName": vmName };

            fetchHelper.postData("api/make", raw , VehicleMakesSuccess , VehicleMakesFailure)


            // var requestOptions = {
            //     method: 'POST',
            //     mode: 'cors',
            //     credentials: 'include',
            //     referrerPolicy: 'strict-origin-when-cross-origin',
            //     headers: myHeaders,
            //     body: raw,
            //     redirect: 'follow'
            // };

            // fetch(`${API_ADDR}/api/Make`, requestOptions)
            //     .then(response => response.text())
            //     .then(result => {


            //         fetchVehicleType();
            //         fecthMake();
            //         HandleRoleClose();


            //     })
            //     .catch(error => console.log('error', error));

        } else {




        }




    }


    const changeHandler = (e) => {
        vehicelEdit(e.target.name, e.target.value)
    }



    return (
        <Dialog name="DialogRole" open={vOpen} onClose={HandleRoleClose} fullWidth={true} maxWidth="sm">
            <DialogTitle >Add Vehicle Make</DialogTitle>
            <DialogContent>

                <DialogContentText>
                    Enter vehicle make
            </DialogContentText>

                <TextField onChange={changeHandler} required={true} placeholder="Vehicle Make" label="Vehicle Make" fullWidth name="vMakeName" />


            </DialogContent>


            <DialogActions>
                <Button color="primary" onClick={HandleRoleClose}>
                    Cancel
            </Button>

                <Button color="primary" onClick={handleMakeAdd}>
                    Finalise
            </Button>
            </DialogActions>
        </Dialog>
    )
}
const mapStateToProps = state => ({

    vOpen: state.Vehicle.vMakeOpen,
    vmName: state.Vehicle.vMakeName,


})


export default connect(mapStateToProps, { vehicelEdit, fetchVehicleType, fecthMake })(VehicleMakesDialog)
