import React, { useEffect } from 'react';
import { Button, Container,  Grid, InputLabel, makeStyles, Snackbar, TextField, Typography, Paper, TableCell } from '@material-ui/core';
import { connect } from 'react-redux';
import { vehicelDetailsAdd, fetchDriver, fetchCompanyFleet, fetchModel, fecthVehicleGroup, fetchVehicleDetails } from '../../Actions/VehicleDetailsAction';
import VDCompFleet from './VDCompanyFleet';
import VDDrive from './VDDriver';
import VDMod from './VDModel';
import VDVehGroup from './VDVehicleGroup';
import VDVehGroupDialog from './VDVehicleGroupDialog';
import VehicleDisp from '../VehicleDetails/VehicleDetailsDisplay';
import { fetchHelper } from '../../RequetsHandler/FetchHandler';

const VehicleDetails = ({
    mileage,
    registration,
    vin,
    engineNo,
    gvm,
    fetchDriver,
    fetchModel,
    fecthVehicleGroup,
    fetchCompanyFleet,
    vmodelId,
    vcompanyFleetId,
    vdriverId,
    vvehicleGroupId,
    vDriverArr,
    vVehicleGroupArr,
    vModelArr,
    vCompanyFleetArr,
    vehicelDetailsAdd,
    fetchVehicleDetails,
    eMileage,
    eReg,
    eVin,
    eEngineNo,
    eGvm,
    isEd,
    cfmID,
    snackNegative,
    snack

}) => {



    function updateFleetModelSuccess() {
        fetchVehicleDetails();
        vehicelDetailsAdd("Snack", true)
        vehicelDetailsAdd("mileage", "")
        vehicelDetailsAdd("registration", "")
        vehicelDetailsAdd("vin", "")
        vehicelDetailsAdd("engineNo", "")
        vehicelDetailsAdd("gvm", "")
        vehicelDetailsAdd("companyFleetId", 0)
        vehicelDetailsAdd("modelId", 0)
        vehicelDetailsAdd("driverId", 0)
        vehicelDetailsAdd("vehicleGroupId", 0)
    }

    function updateFleetModelFailure() {
        vehicelDetailsAdd("SnackNegative", true)
    }



    const editVehicleDetails = async (objUpdate) => {
        fetchHelper.updateData('api/companyFleetmodel', objUpdate, updateFleetModelSuccess, updateFleetModelFailure);
    };

    function FleetModelSuccess() {
        fetchVehicleDetails();
        vehicelDetailsAdd("Snack", true)
        vehicelDetailsAdd("isEditing", false);
        vehicelDetailsAdd("mileage", "")
        vehicelDetailsAdd("registration", "")
        vehicelDetailsAdd("vin", "")
        vehicelDetailsAdd("engineNo", "")
        vehicelDetailsAdd("gvm", "")
        vehicelDetailsAdd("companyFleetId", 0)
        vehicelDetailsAdd("modelId", 0)
        vehicelDetailsAdd("driverId", 0)
        vehicelDetailsAdd("vehicleGroupId", 0)
    }
    function FleetModelFailure() {
        vehicelDetailsAdd("SnackNegative", true)
    }

    const addVehicleDetails = async (objAdd) => {

        fetchHelper.postData('api/companyFleetModel', objAdd, FleetModelSuccess, FleetModelFailure);

    };




    const fleetModelAdd = () => {


        if (registration !== '' &
         vin !== '' & 
         engineNo !== '' &
          gvm !== '' &
           mileage !== '' & 
           vmodelId !== 0 &
           vcompanyFleetId !== 0 & 
           vdriverId !== 0 & 
           vvehicleGroupId !== 0) 
           {


            if (isEd) {


                //console.log("CFMID" , cfmID)
                var objUpdate = {
                    companyFleetModelID: cfmID,
                    mileage: parseInt(mileage, 10),
                    registration: registration,
                    vin: vin,
                    engineNumber: engineNo,
                    gvm: gvm,
                    modelID: vmodelId,
                    companyFleetID: vcompanyFleetId,
                    driverID: vdriverId,
                    vehicleGroupID: vvehicleGroupId
                }


                editVehicleDetails(objUpdate)
                    .then(() => {
                        vehicelDetailsAdd("snack", true);
                    })
                    .catch(() => {
                        vehicelDetailsAdd("snackNegative", true);
                    });
                //vehicelDetailsAdd("isEditing", false);
            } else {


                var objAdd = {
                    companyFleetModelID: cfmID,
                    mileage: parseInt(mileage, 10),
                    registration: registration,
                    vin: vin,
                    engineNumber: engineNo,
                    gvm: gvm,
                    modelID: vmodelId,
                    companyFleetID: vcompanyFleetId,
                    driverID: vdriverId,
                    vehicleGroupID: vvehicleGroupId
                };
                //console.log(obj);

                //fetchHelper.postData('api/CompanyFleetModel', obj , FleetModelSuccess , FleetModelFailure);
                addVehicleDetails(objAdd)
                    .then(() => {
                        vehicelDetailsAdd("snack", true);
                    })
                    .catch(() => {
                        vehicelDetailsAdd("snackNegative", true);
                    });
            }

        } else {
            if (registration.trim() === "" | (!(/^[0-9a-z A-Z]*$/.test(registration)))) {
                vehicelDetailsAdd("errregistration", true)
                vehicelDetailsAdd("SnackNegative", true)
                return;
            }
            if (vin.trim() === "" | (!(/^[0-9a-z A-Z]*$/.test(vin)))) {
                vehicelDetailsAdd("errvin", true)
                vehicelDetailsAdd("SnackNegative", true)
                return;
            }
            if (engineNo.trim() === "" | (!(/^[0-9a-z A-Z]*$/.test(engineNo)))) {
                vehicelDetailsAdd("errengineNo", true)
                vehicelDetailsAdd("SnackNegative", true)
                return;
            }
            if (gvm.trim() === "") {
                vehicelDetailsAdd("errgvm", true)
                vehicelDetailsAdd("SnackNegative", true)
                return;
            }
            if (mileage.trim() === "") {
                vehicelDetailsAdd("errmileage", true)
                vehicelDetailsAdd("SnackNegative", true)
                return;
            }
            if (vmodelId === 0) {
                vehicelDetailsAdd("errmodelId", true)
                vehicelDetailsAdd("SnackNegative", true)
                return;
            }
            if (vcompanyFleetId === 0) {
                vehicelDetailsAdd("errcompanyFleetId", true)
                vehicelDetailsAdd("SnackNegative", true)
                return;
            }
            if (vdriverId === 0) {
                vehicelDetailsAdd("errdriverId", true)
                vehicelDetailsAdd("SnackNegative", true)
                return;
            }
            if (vvehicleGroupId === 0) {
                vehicelDetailsAdd("errvehicleGroupId", true)
                vehicelDetailsAdd("SnackNegative", true)
                return;
            }
        }

    }

    const boxStyle = makeStyles(() => ({
        root: {
            height: "57px",
        }
    }))

    const handleVehicleGroupOpen = () => {
        vehicelDetailsAdd("vVehicleGroupOpen", true);
    }


    useEffect(() => {
        fecthVehicleGroup();
        fetchCompanyFleet();
        fetchDriver();
        fetchModel();
        // console.log(vVehicleGroupArr);
        // console.log(vCompanyFleetArr);
        // console.log(vDriverArr);
        // console.log(vModelArr);
        return () => {
        }
    }, [fecthVehicleGroup, fetchCompanyFleet, fetchDriver, fetchModel])

    const cval = (e) => {
        vehicelDetailsAdd(e.target.name, e.target.value)
        if (e.target.value === "" | e.target.value === 0) {
            vehicelDetailsAdd(`err${e.target.name}`, true);
        } else {
            vehicelDetailsAdd(`err${e.target.name}`, false);
        }
    }

    const classBox = boxStyle();

    return (

        <Container maxWidth="xl">

            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => { vehicelDetailsAdd("Snack", false) }}
                message="Vehicle has been added / updated"
                action={
                    <React.Fragment>

                    </React.Fragment>
                } />

            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={snackNegative}
                onClose={() => { vehicelDetailsAdd("SnackNegative", false) }}
                autoHideDuration={6000}
                message="The Vehicle could not be added / Updated"
                action={
                    <React.Fragment>
                    </React.Fragment>
                } />


            <Grid container spacing={2} >

                <Grid item xs={12}>
                    <Typography variant="h3">
                        Vehicle Details
                        </Typography>

                </Grid>

                <Grid item xs={9}>
                    <Paper>

                        <VehicleDisp></VehicleDisp>

                    </Paper>
                </Grid>



                <Grid item xs={3}  >

                    <Grid Container >



                        <Grid item xs={12} style={{ padding: 8 }}>
                            
                                <InputLabel style={{ marginLeft: 10 }} fullWidth={true}>Model</InputLabel>

                                <VDMod onCH={cval} arr={vModelArr} ></VDMod>
                           
                        </Grid>

                        <Grid item xs={12} style={{ padding: 8 }}>
                            
                                <InputLabel style={{ marginLeft: 10 }} fullWidth={true}>Driver</InputLabel>
                                <VDDrive onCH={cval} arr={vDriverArr}></VDDrive>
                            
                        </Grid>

                        <Grid item xs={12} style={{ padding: 8 }}>
                            
                                <InputLabel style={{ marginLeft: 10 }} fullWidth={true}>CompanyFleet</InputLabel>
                                <VDCompFleet onCH={cval} arr={vCompanyFleetArr}></VDCompFleet>
                            
                        </Grid>

                        <Grid item xs={12} style={{ padding: 8 }}>
                            <TextField
                                required={true}
                                helperText={eMileage ? "* Please fill in the filed" : ""}
                                error={eMileage}
                                type="Number"
                                name="mileage"
                                label="Mileage"
                                variant="standard"
                                value={mileage}
                                fullWidth={true}
                                placeholder="Mileage"
                                onChange={cval} />
                        </Grid>

                        <Grid item xs={12} style={{ padding: 8 }}>
                            <TextField
                                helperText={eReg ? "* Please fill in the filed" : ""}
                                error={eReg}
                                disabled={isEd}
                                value={registration}
                                name="registration"
                                label="Registration"
                                variant="standard"
                                fullWidth={true}
                                placeholder="Registration"
                                onChange={cval} />
                        </Grid>

                        <Grid item xs={12} style={{ padding: 8 }}>
                            <TextField
                                helperText={eVin ? "* Please fill in the filed" : ""}
                                error={eVin}
                                value={vin}
                                disabled={isEd}
                                name="vin"
                                className={classBox.root}
                                label="Vin No"
                                variant="standard"
                                fullWidth={true}
                                placeholder="Vin No"
                                onChange={cval} />
                        </Grid>

                        <Grid item xs={12} style={{ padding: 8 }}>
                            <TextField
                                helperText={eEngineNo ? "* Please fill in the filed" : ""}
                                error={eEngineNo}
                                value={engineNo}
                                disabled={isEd}
                                name="engineNo"
                                label="Colour"
                                variant="standard"
                                fullWidth={true}
                                placeholder="Colour"
                                onChange={cval} />
                        </Grid>

                        <Grid item xs={12} style={{ padding: 8 }}>
                            <TextField

                                error={eGvm}
                                disabled={isEd}
                                helperText={eGvm ? "* Please fill in the filed" : ""}
                                type="Number"
                                name="gvm"
                                value={gvm}
                                label="Year"
                                variant="standard"
                                fullWidth={true}
                                placeholder="Year"
                                onChange={cval} />
                        </Grid>

                        <Grid item xs={12} style={{ padding: 8 }}>
                            
                                <InputLabel style={{ marginLeft: 10 }} fullWidth={true}>Vehicle Group</InputLabel>
                                <TableCell item xs={12}>
                                    <VDVehGroup onCH={cval} arr={vVehicleGroupArr}></VDVehGroup>
                                </TableCell>
                            
                        </Grid>

                        <Grid item xs={12} style={{ padding: 8 }}>
                            <Button onClick={handleVehicleGroupOpen} color="primary" variant="standard" fullWidth={true} className={classBox.root}>Add Vehicle Group</Button>
                        </Grid>



                        <Grid item xs={12} style={{ padding: 8 }}>
                            <Button color="primary" variant="contained" fullWidth={true} onClick={fleetModelAdd} className={classBox.root}>Add Vehicle</Button>
                        </Grid>

                    </Grid>

                </Grid>

            </Grid>

            <VDVehGroupDialog />

        </Container>
    )
}

const mapStateToProps = state => ({
    mileage: state.vehicleDetail.mileage,
    registration: state.vehicleDetail.registration,
    vin: state.vehicleDetail.vin,
    engineNo: state.vehicleDetail.engineNo,
    gvm: state.vehicleDetail.gvm,
    vmodelId: state.vehicleDetail.modelId,
    vcompanyFleetId: state.vehicleDetail.companyFleetId,
    vdriverId: state.vehicleDetail.driverId,
    vvehicleGroupId: state.vehicleDetail.vehicleGroupId,
    vDriverArr: state.vehicleDetail.vDriver,
    vVehicleGroupArr: state.vehicleDetail.vVehicleGroup,
    vModelArr: state.vehicleDetail.vModel,
    vCompanyFleetArr: state.vehicleDetail.vCompanyFleet,
    isEd: state.vehicleDetail.isEditing,
    cfmID: state.vehicleDetail.companyFleetModelID,

    snack: state.vehicleDetail.Snack,
    snackNegative: state.vehicleDetail.SnackNegative,


    eMileage: state.vehicleDetail.errmileage,
    eReg: state.vehicleDetail.errregistration,
    eVin: state.vehicleDetail.errvin,
    eEngineNo: state.vehicleDetail.errengineNo,
    eGvm: state.vehicleDetail.errgvm,
    eVGID: state.vehicleDetail.errvehicleGroupId,
    eModelID: state.vehicleDetail.errmodelId,
    eCFID: state.vehicleDetail.errcompanyFleetId,
    eDriverID: state.vehicleDetail.errdriverId

})

export default connect(mapStateToProps, { vehicelDetailsAdd, fetchCompanyFleet, fetchDriver, fetchModel, fecthVehicleGroup, fetchVehicleDetails })(VehicleDetails)
