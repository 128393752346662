import React from 'react'
import { Switch,Route, useRouteMatch } from 'react-router-dom'
import SignUpPage from '../SignUpPage/SignUpPage'
import Drivers from '../Pages/Drivers/Drivers'
import Services from '../Pages/Services/Services'
import VehicleDetails from '../Pages/VehicleDetails/VehicleDetails'
//import Menu from '../Pages/Menu/Menu'
//import Item from '../Pages/Item/Item'
//import Company from '../Pages/Company/Company'; 
//import Function from '../Pages/Function/Function';
import FleetDetails from '../Pages/FleetDetails/FleetDetails'
import vdets from '../Pages/AddVehicle/VehicleDetails'
import dashDisp from '../Dashboard/DashboardDisplay'
//import site from '../Pages/Site/Site'
//import project from '../Pages/Project/Project'
import TeamMember from '../Pages/TeamAssigned/TeamMember'
//import Job from '../Pages/Job/Job'
//import Task from '../Pages/Task/Task'
import SiteSurv from '../Pages/SiteSurvey/SiteSurvey'
import { Container } from '@material-ui/core'
//import Create from '../DesignChange/Create/MakeWork'
import View from '../DesignChange/SiteMan'
//import ProjMan from '../DesignChange/ProjectManagement'
import downloads from '../Dashboard/Downloads'
import ViewManagement from '../ViewManagement/ViewSiteManagement'
import TeamAssigned from '../Pages/TeamAssigned/TeamMemberAssigned'
import ProjectReport from '../Report/ProjectReport'
import TaskReport from '../Report/JobReport'
import DefaultList from '../DesignChange/NsbManagement'

const RouteLayout = () => {
    let {path}  = useRouteMatch();
    return (
                   
        <Container>
            
            <Switch>
                <Route path={`${path}/Users`} component={SignUpPage}/>
                <Route exact path={`/Dashboard`} component={dashDisp}/>
                <Route path={`${path}/Drivers`} component={Drivers} />
                <Route path={`${path}/FleetDetails`} component={FleetDetails}/>
                <Route path={`${path}/Downloads`} component={downloads}/>
                {/* <Route path={`${path}/CompanyFleetDetails`} component={CompanyFleetDetails} />  */}
                {/* <Route path={`${path}/Site`} component={site}/>
                <Route path={`${path}/Create`} component={Create}/>
                <Route path={`${path}/View`} component={View}/>
                <Route path={`${path}/Project`} component={project} /> */}
                <Route path={`${path}/ProjectManagement`} component={View} />
                {/* <Route path={`${path}/Job`} component={Job} />*/}
                <Route path={`${path}/SiteSurvey`} component={SiteSurv} /> 
                <Route path={`${path}/Team`} component={TeamMember} />
                {/* <Route path={`${path}/Task`} component={Task} /> */}
                <Route path={`${path}/AddVehicle`} component={VehicleDetails} />
                <Route path={`${path}/Services`} component={Services} />
                <Route path={`${path}/AddModel`} component={vdets} />
                <Route path={`${path}/ViewManagement`} component={ViewManagement} />
                <Route path={`${path}/AssignedTeam`} component={TeamAssigned}/>
                <Route path={`${path}/ProjectReport`} component={ProjectReport}/>
                <Route path={`${path}/TaskReport`} component={TaskReport}/>
                <Route path={`${path}/DefaultListManagement`} component={DefaultList}/>
                {/* <Route path={`${path}/Menu`} component={Menu} />
                <Route path={`${path}/Item`} component={Item} /> */}
                {/* <Route path={`${path}/Company`} component={Company} /> */}
                {/* <Route path={`${path}/Driver`} component={Driver} />             */}
                {/* <Route path={`${path}/Function`} component={Function} />   */}
                {/* <Route path={`${path}/:comapanyID`} component={FleetDetails} /> */}
                
                        
            </Switch>
        </Container>
    )
}

export default RouteLayout
