import { MenuItem, Select, Container } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import {entryValue} from '../Actions/CompanyFleetDetailsAction'


const fleetcompanySelect = ({usrComp, cList, entryValue ,eCompanyID, onCh , edi}) => {

    return (
        <Container>
        <Select
        disabled={edi}
            name =  "usrCompanyID"
            error = {eCompanyID}
            onChange = {onCh}
            fullWidth
            placeholder = "Hello World"
            value = {usrComp}
        >
            <MenuItem value={0}> <em>None</em></MenuItem>
                {cList.map((item) => (

                <MenuItem value={item.companyId}>{item.companyName}</MenuItem>

                ))}

        </Select>



        </Container>
    )
}
const mapStateToProps = state =>({
    edi  :state.companyFleetDetail.isEditing , 
    usrComp : state.companyFleetDetail.usrCompanyID , 
    eCompanyID  :state.companyFleetDetail.errusrCompanyID

})

export default connect(mapStateToProps , {entryValue})(fleetcompanySelect)
