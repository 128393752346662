import {ADDPROJ, FETCHSITES, FETCHSTATUS, FETCHPROJ, FETCHPROJSITE} from '../Actions/types';

const initialState = {
    ProjID:0,
    ProjName:"",
    ProjDet:"",
    SiteID:0,
    StatusID:0,
    Project:[],
    Status:[],
    Site:[],
    errProjName: false,
    errProjDet: false,
    errSiteID: false,
    errStatusID: false,
    isEd: false,
    Snack: false,
    SnackNegative: false,
    projpath: '',
    dialogOpen: false,
    projsite: [],
    DisplayProj: false
}

export default function red(state = initialState, action){
    switch(action.type)
    {
        case ADDPROJ:
            return{
            ...state,
            [action.objName] : action.payload
            }
        case FETCHPROJ :
            return {
            ...state ,
            Project : action.payload
            }
        case FETCHSITES :
            return {
            ...state ,
            Site : action.payload
            }
        case FETCHSTATUS :
            return {
            ...state ,
            Status : action.payload
            }
            case FETCHPROJSITE :
                return {
                ...state ,
                projsite : action.payload
                }
        default:
            return state;
    }
}