import {
  Button,
  Paper
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { serviceAdd } from "../../Actions/ServiceAction";
import {} from "../../Actions/vehicleAction";
import { DataGrid } from "@material-ui/data-grid";

const ServiceTable = ({ models, serviceAdd }) => {

  //console.log("models",models);

  const rows = models.map((row) => {

    return {
      id: row.serviceID,
      description: row.description,
      details: row.details,
      dueDate: row.dueDate,
      registration: row.registration,
      serviceID: row.serviceID,
      servicetypeid: row.servicetypeid,
      edit: row,
    };
  });

  const columns = [
    { field: "serviceID", hide: true },
    {
      field: "registration",
      headerName: "Registration",
      width: 250,
    },
    {
      field: "description",
      headerName: "Service Type",
      width: 250,
    },
    {
      field: "details",
      headerName: "Details",
      width: 250,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      width: 250,
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <strong>
            <Button
              onClick={() => {
                console.log(params.value)
                handleUpdate(params.value);
              }}
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
            >
              Edit
            </Button>
          </strong>
        );
      },
    },
  ];
  //const tblHeader = ["Vehicle Reg", "Service Type", "Details", "Due Date"];

  const handleUpdate = async (row) => {
    serviceAdd("details", row.details);
    serviceAdd("dueDate",new Date(row.dueDate).toISOString().substr(0, new Date(row.dueDate).toISOString().indexOf("T")));
    serviceAdd("serviceID", row.serviceID);
    serviceAdd("comppanyFleetModelId", row.companyfleetmodelid);
    serviceAdd("serviceTypeId", row.servicetypeid);
    serviceAdd("isEditing", true);
  };

  // const josnValueHeader = ["registration", "description", "details", "dueDate"];
  
  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Paper elevation={5} />
      <div style={{ flexGrow: 1, height: "80vh" }}>
        <DataGrid rows={rows} columns={columns} />
      </div>
      <Paper />
    </div>
  );
};

const mapStateToProps = (state) => ({
  models: state.service.vServiceDetails,
});

export default connect(mapStateToProps, { serviceAdd })(ServiceTable);
