import { LOCALSTORE } from '../Actions/types'

const initialState = {
    disabled: false,
    disabledST: false
}

export default function red(state = initialState, action) {
    switch (action.type) {

        case LOCALSTORE:
            return {
                ...state,
                [action.objName]: action.payload
            }
     
        default:
            return state;
    }

}