import {FETCH_VEHICLE_MAKE , FETCH_VEHICLE_TYPE , EDIT_VALUE_V, FETCH_MODELS} from './types';
import {API_ADDR} from '../DatabaseStrings/dbStrings';
import auth from '../Authentication/auth'

export const vehicelEdit = (name , value) => dispatch => {
        console.log({name , value})    
    
    dispatch({
            type :EDIT_VALUE_V ,
            payload : value ,
            objname : name,
        })
}


export const fecthMake = () => dispatch => {
    fetch(`${API_ADDR}/api/Make`
    , {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
        'Accept':'application/json',
        
    }}).then(res => res.json())
.then(data => dispatch(
    {
        type : FETCH_VEHICLE_MAKE,
        payload : data
    })).catch(err => console.log(err))

}

export const fetchModels = () => dispatch => {
    //console.log("token model",auth.getToken())
    fetch(`${API_ADDR}/api/Models`,
    {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
            'Accept' : 'application/json',
           
        }
    }).then(res => res.json())
    .then(data => dispatch({
        type:FETCH_MODELS ,
        payload :data 
    })).catch(err => console.log(err))

}


export const fetchVehicleType = () => dispatch => {
        fetch(`${API_ADDR}/api/VehicleType`,
        {
            mode:'cors',
            credentials:'include',
            referrerPolicy:'strict-origin-when-cross-origin',
            headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept' : 'application/json',
               
            }
        }).then(res => res.json())
        .then(data => dispatch({
            type:FETCH_VEHICLE_TYPE ,
            payload :data 
        })).catch(err => console.log(err))

}