import { MenuItem, Select, Container } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { projAdd, fetchSites } from '../../Actions/ProjectAction';

const siteSelect = ({
    SiteID,
    cList,
    errSiteID,
    onCh,
    isEd

}) => {
    
        return (
        <Container>
        <Select
        //disabled={edi}
            name =  "SiteID"
            error = {errSiteID}
            onChange = {onCh}
            fullWidth
            placeholder = "Hello World"
            value = {SiteID}
            disabled={isEd}
        >
            <MenuItem value={0}> <em>None</em></MenuItem>
                {cList.map((item) => (

                <MenuItem value={item.siteid}>{item.sitename}</MenuItem>
                
                ))}

        </Select>



        </Container>
    )
}

const mapStateToProps = state =>({
     
    SiteID : state.projRed.SiteID , 
    errSiteID  :state.projRed.errSiteID,
    isEd: state.projRed.isEd

})

export default connect(mapStateToProps,{projAdd, fetchSites})(siteSelect)