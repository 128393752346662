import { Container, MenuItem, Select } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { jobAdd, fetchReg, custAdd } from '../../Actions/JobAction'


const JobTeamSelect = ({usrVeh, cList,eVehID, onCh, isEd}) => {

    return (
        <Container>
        <Select
        //disabled={edi}
            name =  "Vehicle"
            error = {eVehID}
            onChange = {onCh}
            fullWidth
            placeholder = "Hello World"
            value = {usrVeh}
            disabled={isEd}
        >
            <MenuItem value={0}> <em>None</em></MenuItem>
                {cList.map((item) => (

                <MenuItem value={item.companyFleetModelID}>{item.registration} {item.vehicletype}</MenuItem>
                
                ))}

        </Select>



        </Container>
    )
}
const mapStateToProps = state =>({
    usrVeh : state.jobRed.Vehicle , 
    eVehID :state.jobRed.errVehicle,
    isEd: state.jobRed.isEd

})

export default connect(mapStateToProps , {custAdd , fetchReg, jobAdd})(JobTeamSelect)
