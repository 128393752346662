import {ADDSERVICE, FETCHSERVICEDETAILS, FETCHCFM, FETCHSERVICETYPE} from '../Actions/types';

const initialState={
    details : '',
    dueDate : '',
    comppanyFleetModelId : 0,
    serviceTypeId : 0,
    vCFM : [],
    vServiceType: [],
    vServiceOpen : false,
    vServiceTypeName: '',
    vServiceDetails: [],
    errdetails : false , 
    errdueDate : false ,
    errcomppanyFleetModelId : false ,
    errserviceTypeId  :false,
    snack : false ,
    snacknegative : false,
    serviceID : 0 ,
    isEditing :false 
    
}

export default function red(state = initialState, action){
    switch(action.type){
        case ADDSERVICE :
            //console.log(action.payload);
            return{
                ...state ,
                [action.objname] : action.payload
            }
            case FETCHSERVICEDETAILS :
            //console.log("This is the service getter" , action.payload);
            return{
                ...state ,
                vServiceDetails : action.payload
            } 
            case FETCHCFM :
            //console.log(action.payload);
            return{
                ...state ,
                vCFM : action.payload
            } 
            case FETCHSERVICETYPE :
            //console.log(action.payload);
            return{
                ...state ,
                vServiceType : action.payload
            } 
            default:
                return state ;   
        }
    }
