import { MenuItem, Select, Container } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import {vehicelDetailsAdd} from '../../Actions/VehicleDetailsAction'

const companyFleet = ({arr , companyFleetChoice , vehicelDetailsAdd ,onCH, eCompanyFleet}) => {
  
    // const c = (e) => {

    //     console.log(e.target.value)
    //     console.log(e.target.name);
    //     console.log(arr);
    //     vehicelDetailsAdd(e.target.name , e.target.value)
    // }  
  
    return (
        <Container>

        <Select 
       
        error={eCompanyFleet}
        name="companyFleetId" value={companyFleetChoice} variant="standard" onChange={onCH} fullWidth={true} placeholder="Company Fleet" >
                <MenuItem value={0}><em>None</em></MenuItem>
            {arr.map(x => (

                <MenuItem 
                key={x.companyFleetID} 
                value={x.companyFleetID}>

                     {x.fleetNumber} : {x.description}
                     </MenuItem>

            ))}
        </Select>
        </Container>
    )
}

const mapStateToProps = state => ({

    companyFleetChoice : state.vehicleDetail.companyFleetId , 
    arr : state.vehicleDetail.vCompanyFleet ,
    eCompanyFleet :  state.vehicleDetail.errcompanyFleetId


})


export default connect(mapStateToProps , {vehicelDetailsAdd})(companyFleet)
