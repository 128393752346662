import { MenuItem, Select, Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { projAdd, fetchStatus } from '../../Actions/ProjectAction';

const StatusSelect = ({
    StatusID,
    cList,
    projAdd,
    errStatusID,
    onCh

}) => {

    useEffect(() => {

      
        return () => {

        }
    }, [cList])


    return (
        <Container>
            <Select
                //disabled={edi}
                name="StatusID"
                error={errStatusID}
                onChange={onCh}
                fullWidth
                placeholder="Hello World"
                value={StatusID}
            >
                <MenuItem value={0}> <em>None</em></MenuItem>
                {cList.map((item) => (


                    <MenuItem value={item.projectstatusid}>{item.status}</MenuItem>
                )
                )}

            </Select>



        </Container>
    )
}

const mapStateToProps = state => ({

    StatusID: state.projRed.StatusID,
    errStatusID: state.projRed.errStatusID

})

export default connect(mapStateToProps, { projAdd, fetchStatus })(StatusSelect)