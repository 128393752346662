import { Link } from 'react-router-dom';
import { CardActions, Paper, Card, Grid, Typography } from '@material-ui/core'
import React from 'react'

const DownLoads = () => {
    return (
        <Paper>
            <Card>
                <CardActions>
                    <Grid container item xs={12} spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="h4">
                                Downloads
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h6">
                                Job Cards
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to="/Newbatteryjobcard.xlsx" style={{ color: "blue", textDecorationLine:"underline" }} target="_blank" download >- New Battery Job Card</Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to="/NewCabinetReticulationjobcard.xlsx" style={{ color: "blue", textDecorationLine:"underline" }} target="_blank" download >- New Cabinet Reticulation Job Card</Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to="/NewCOCProjectjobcard.xlsx" style={{ color: "blue", textDecorationLine:"underline"  }} target="_blank" download >- New COC Project Job Card</Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to="/NewDailySiteDiaryjobcard.xlsx" style={{ color: "blue", textDecorationLine:"underline" }} target="_blank" download >- New Daily Site Diary Job Card</Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to="/NewTangostockjobcard.xlsx" style={{ color: "blue", textDecorationLine:"underline" }} target="_blank" download >- New Tango Job Card</Link>
                        </Grid>
                    </Grid>
                </CardActions>
            </Card>
        </Paper>
    )
}

export default DownLoads
