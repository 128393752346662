//ACTION TYPES FOR THE MENU POPULATING 
export const FETCH_MENU = 'FETCH_MENU' ;
export const NEW_MENU = 'NEW_MENU';
export const FETCH_ITEMS = 'FETCH_ITMES';
export const FETCH_MENU_ITEMS = 'FETCH_MENU_ITEMS'; 
export const FECTH_SIDEBAR = 'FETCH_SIDEBAR' ; 

//actions for signup and login
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const SIGNUP_USER = 'SIGNUP_USER' ;
export const FETCH_USERS = 'FETCH_USERS';
export const SHOW_LOADER = 'SHOW_LOADER';
export const HIDE_LOADER = 'HIDE_LOADER';


//ACTION TYPES FOR THE SIGNUP SCREEN 
export const EDIT_VALUE = 'EDIT_VALUE' ;
export const DIALOG_DISPLAY = 'DIALOG_DISPLAY' ; 
export const ERROR_DISPLAY = 'ERROR_DISPLAY' ; 
export const ITEM_DISPLAY = 'ITEM_DISPLAY' ;
export const COMPANY_DISPLAY = 'COMPANY_DISPLAY' ; 
export const FETCH_ROLES = 'FETCH_ROLES'; 


//RoleDialog Screen
export const ADDITEMTOROLE = 'ADDITEMTOROLE' ;
export const ROLENAMECHANGE = 'ROLENAMECHNAGE' ; 

//Company Dialog screen 
export const NEWCOMPANYNAME = 'NEWCOMPANYNAME';

//ACTION TYPES FOR LOGIN SCREEN 
export const EDITLOGIN = 'EDITLOGIN';


//ACTION FOR ADDING A VECHICLE 
export const EDIT_VALUE_V = 'EDITVALUEV';
export const FETCH_VEHICLE_TYPE = 'FETCH_VEHICLE_TYPE';
export const FETCH_VEHICLE_MAKE = 'FETCH_VEHICLE_MAKE';
export const FETCH_MODELS = 'FETCH_MODELS';

//Driver screen 
export const ADDDRIVER = 'ADDDRIVER';

//Company FLeet Details Screen
export const ADDCOMPANYFLEETDETAILS = 'ADDCOMPANYFLEETDETAILS';
export const FETCHDRIVERS = 'FETCHDRIVERS'


//Company Fleet Model screen
export const ADDCOMPANYFLEETMODEL = 'ADDCOMPANYFLEETMODEL';
export const FETCHMODEL = 'FETCHMODEL';
export const FETCHDRIVER = 'FETCHDRIVER';
export const FETCHCOMPANYFLEET = 'FETCHCOMPANYFLEET';
export const FETCHVEHICLEGROUP = 'FETCHVEHICLEGROUP';
export const FETCHVEHICLEDETAILS = 'FETCHVEHICLEDETAILS';

//Service Screen
export const ADDSERVICE = 'ADDSERVICE';
export const FETCHSERVICEDETAILS = 'FETCHSERVICEDETAILS';
export const FETCHSERVICETYPE = 'FETCHSERVICETYPE';
export const FETCHCFM = 'FETCHCFM';  

//Site Screen
export const ADDSITE = 'ADDSITE';
export const FETCHSCOMP = 'FETCHSCOMP';
export const FETCHSITE = 'FETCHSITE';
export const FETCHSITESPEC = 'FETCHSITESPEC';


//Proj Screen
export const ADDPROJ = 'ADDPROJ';
export const FETCHSITES = 'FETCHSITES';
export const FETCHSTATUS = 'FETCHSTATUS';
export const FETCHPROJ = 'FETCHPROJ'; 
export const FETCHPROJSITE = 'FETCHPROJSITE'

//Team Member Screen
export const ADDTEAMMEM = 'ADDTEAMMEM';
export const FETCHTEAMMEM = 'FETCHTEAMMEM';
export const FETCHTEAMMEMBERASSIGNED = 'FETCHTEAMMEMBERASSIGNED';


//Job Screen
export const ADDJOB = 'ADDJOB';
export const FETCHJOB = 'FETCHJOB';
export const FETCHPROJECT = 'FETCHPROJECT';
export const FETCHCUSTOMER = 'FETCHCUSTOMER';
export const ADDCUSTOMER = 'ADDCUSTOMER';
export const FETCHJOBTYPE = 'FETCHJOBTYPE';
export const FETCHTEAMASSIGNED = 'FETCHTEAMASSIGNED';
export const FETCHREG = 'FETCHREG';
export const ADDTEAM = 'ADDTEAM';
export const ADDTEAMASSIGNED = 'ADDTEAMASSIGNED';
export const FETCHTEAM = 'FETCHTEAM';
export const FETCHUSERS = 'FETCHUSERS';
export const FETCHTASKSPEC = 'FETCHTASKSPEC';
export const FETCHSELECTEDJOB = 'FETCHSELECTEDJOB'; 
export const FETCHJOBSP = 'FETCHJOBSP'; 
export const FETCHJOBUSER = 'FETCHJOBUSER';
export const FETCHNOTES = 'FETCHNOTES';
export const FETCHJT = 'FETCHJT';

// Task Screen
export const ADDTASK = 'ADDTASK';
export const FETCHTASK = 'FETCHTASK';
export const FETCHJOBTASK = 'FETCHJOBTASK';
export const FETCHJOBSTATUS = 'FETCHJOBSTATUS';
export const FETCHTASKTRACKER = 'FETCHTASKTRACKER';

//Sub Task Screen
export const ADDSUBTASK = 'ADDSUBTASK';
export const FETCHSUBTASKSPEC = 'FETCHSUBTASKSPEC';
export const FETCHSUBTASKTRACKER = 'FETCHSUBTASKTRACKER'
export const LOCALSTORE = 'LOCALSTORE';

//NSB Screen
export const ADDNSB = 'ADDNSB';
export const FETCHNSB = 'FETCHNSB';

//FOLDER Screen
export const ADDFOLDER = 'ADDFOLDER';
export const FETCHFOLDER = 'FETCHFOLDER';