import { MenuItem, Select, Container } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import {serviceAdd} from '../../Actions/ServiceAction'

const serviceTypeDrop = ({arr , ServiceTypeChoice , serviceAdd ,onCh, eServiceType}) => {
  
    // const c = (e) => {

    //     console.log(e.target.value)
    //     console.log(e.target.name);
    //     console.log(arr);
    //     console.log("fdwsf");
    //     serviceAdd(e.target.name , e.target.value)
    // }  
  
    return (
        <Container>

        <Select 
        error={eServiceType}
        name="serviceTypeId" value={ServiceTypeChoice} variant="standard" onChange={onCh} fullWidth={true} placeholder="Service Type" >
                <MenuItem value={0}><em>None</em></MenuItem>
            {arr.map(x => (

                <MenuItem 
                key={x.serviceTypeID} 
                value={x.serviceTypeID}>

                     {x.description}
                     
                     </MenuItem>

            ))}
        </Select>
        </Container>
    )
}

const mapStateToProps = state => ({

    ServiceTypeChoice : state.service.serviceTypeId , 
    arr : state.service.vServiceType ,
    eServiceType : state.service.errserviceTypeId


})


export default connect(mapStateToProps , {serviceAdd})(serviceTypeDrop)
