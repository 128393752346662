import {
  Button,
  Grid,
  Snackbar,
  TextField,
  Container
} from "@material-ui/core";
import React, {  } from "react";
import { connect } from "react-redux";
import { entryValue } from "../Actions/DriverAction";
//import { fetchDrivers } from "../Actions/DriverDetailsAction";
import { fetchDriver } from "../Actions/VehicleDetailsAction";
import {fetchHelper} from "../RequetsHandler/FetchHandler";

const Driver = ({
  entryValue,
  dName,
  dSurname,
  dIdNo,
  dPdpExp,
  dAddress,
  dPhNumber,
  dLicExp,
  dDgdpExp,
  eName,
  eSurname,
  eIdNo,
  ePdpExp,
  eAddress,
  ePhNumber,
  eLicExp,
  eDgdpExp,
  snack,
  snackNegative,
  isEd,
  diID,
  fetchDriver,
  SnackDriverID,
}) => {


  const changeValue = (e) => {
    entryValue(e.target.name, e.target.value);

    if ((e.target.value === "") | (e.target.value === 0)) {
      entryValue(`err${e.target.name}`, true);
    } else {
      entryValue(`err${e.target.name}`, false);
    }
  };
 

function updateDriverSuccess(){
  fetchDriver() ;
  entryValue("Snack", true);
  entryValue("isEditing", false);
  entryValue("DriverID", 0);
  entryValue("Name", "");
  entryValue("Surname", "");
  entryValue("IdNo", "");
  entryValue("PdpExp", "");
  entryValue("Address", "");
  entryValue("PhNumber", "");
  entryValue("LicExp", "");
  entryValue("DgdpExp", "");
}

function updateDriveFailure(){
  entryValue("SnackNegative", true)
}


const updateDriver = async (objUpdate) => {

  fetchHelper.updateData('api/driver' , objUpdate, updateDriverSuccess, updateDriveFailure)

};
  

const addDriverDetails = async (objAdd) =>{

  fetchHelper.postData('api/driver', objAdd , (res) =>
  {
    console.log(res.status)
    if(res.status === 200)
    {
      fetchDriver();
      entryValue("Snack", true);
      entryValue("isEditing", false);
      entryValue("DriverID", 0);
      entryValue("Name", "");
      entryValue("Surname", "");
      entryValue("IdNo", "");
      entryValue("PdpExp", "");
      entryValue("Address", "");
      entryValue("PhNumber", "");
      entryValue("LicExp", "");
      entryValue("DgdpExp", "");
    }
    else
    {
      if(res.status === 400)
      {
        entryValue("SnackDriverID", true)
      }
    }
  }, () => { entryValue("SnackNegative", true)}
  )
}


  const submitVals = () => {

    if (isEd) {

      if (dPdpExp.trim() === "" ) {
        entryValue("ePdpExp", true);
        entryValue("SnackNegative", true);
        return;
      }

      if (dAddress.trim() === "" | !(/^[0-9 a-z A-Z]*$/.test(dAddress))) {
        entryValue("eAddress", true);
        entryValue("SnackNegative", true);
        return;
      }

      if ((dPhNumber.length !== 10) | (dPhNumber.trim() === "")) {
        entryValue("ePhNumber", true);
        entryValue("SnackNegative", true);
        return;
      }

      if (dLicExp.trim() === "") {
        entryValue("eLicExp", true);
        entryValue("SnackNegative", true);
        return;
      }

      if (dDgdpExp.trim() === "") {
        entryValue("eDgdpExp", true);
        entryValue("SnackNegative", true);
        return;
      }

      var objUpdate = {
        driverID: diID,
        name: dName,
        surname: dSurname,
        idNumber: dIdNo,
        pdpExpiry: new Date(dPdpExp).toISOString(),
        address: dAddress,
        phoneNumber: dPhNumber,
        licenseExpiry: new Date(dLicExp).toISOString(),
        dgdpExpiry: new Date(dDgdpExp).toISOString(),
      };

      updateDriver(objUpdate)
      console.log(objUpdate)
    } 
    else
    {
      var valid = true;

        if (dName.trim() === "" | !(/^[a-z A-Z]*$/.test(dName))) {
          valid = false;
          entryValue("errName", true);
          entryValue("SnackNegative", true);
          return;
        }

        if (dSurname.trim() === "" | !(/^[a-z A-Z]*$/.test(dSurname))) {
          valid = false;
          entryValue("errSurname", true);
          entryValue("SnackNegative", true);
          return;
        }

        if (dIdNo.length !== 13 | (dIdNo.trim() === ""))  {
          valid = false;
          entryValue("errIdNo", true);
          entryValue("SnackNegative", true);
          return;
        }

        if (dPdpExp.trim() === "") {
          valid = false;
          entryValue("errPdpExp", true);
          entryValue("SnackNegative", true);
          return;
        }

        if (dAddress.trim() === ""| !(/^[0-9 a-z A-Z]*$/.test(dAddress))) {
          valid = false;
          entryValue("errAddress", true);
          entryValue("SnackNegative", true);
          return;
        }

        if ((dPhNumber.trim() === "") | (dPhNumber.length !== 10) ) {
          valid = false;
          entryValue("errPhNumber", true);
          entryValue("SnackNegative", true);
          return;
        }

        if (dLicExp.trim() === "") {
          valid = false;
          entryValue("errLicExp", true);
          entryValue("SnackNegative", true);
          return;
        }

        if (dDgdpExp.trim() === "") {
          valid = false;
          entryValue("errDgdpExp", true);
          entryValue("SnackNegative", true);
          return;
        }

      if (valid) {
        var objAdd = {
          driverID: 0,
          name: dName,
          surname: dSurname,
          idNumber: dIdNo,
          pdpExpiry: dPdpExp,
          address: dAddress,
          phoneNumber: dPhNumber,
          licenseExpiry: dLicExp,
          dgdpExpiry: dDgdpExp,
        };


      addDriverDetails(objAdd)  
      } 
      
    }
  };

  const minDate = new Date().toISOString().split("T")[0];
  var d = new Date();
  var year = d.getFullYear();
  var month = d.getMonth();
  var day = d.getDate();
  var maxDate = new Date(year + 5, month, day).toISOString().split("T")[0];


  return (
    <Container>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => {
          entryValue("Snack", false);
        }}
        message="Driver has been added"
        action={<React.Fragment></React.Fragment>}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackNegative}
        onClose={() => {
          entryValue("SnackNegative", false);
        }}
        autoHideDuration={6000}
        message="The Driver could not be added"
        action={<React.Fragment></React.Fragment>}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={SnackDriverID}
        onClose={() => {
          entryValue("SnackDriverID", false);
        }}
        autoHideDuration={6000}
        message="THE ID IS LINKED TO A DRIVER"
        action={<React.Fragment></React.Fragment>}
      />
      <Grid container direction="column" spacing={2}>
        <Grid item xs>
          <TextField
            name="Name"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            onChange={changeValue}
            error={eName}
            value={dName}
            disabled={isEd}
            label="Name"
            variant="standard"
            color="primary"
            placeholder="Name"
            helperText={eName ? "please enter a value " : ""}
          />
        </Grid>

        <Grid item xs>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            name="Surname"
            disabled={isEd}
            onChange={changeValue}
            value={dSurname}
            fullWidth
            error={eSurname}
            label="Surname"
            variant="standard"
            color="primary"
            placeholder="Surname"
            helperText={eSurname ? "please enter a value " : ""}
          />
        </Grid>
        <Grid item xs>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isEd}
            name="IdNo"
            type="Number"
            onChange={changeValue}
            value={dIdNo}
            fullWidth
            error={eIdNo}
            label="Id Number"
            variant="standard"
            color="primary"
            placeholder="IdNo"
            helperText={eIdNo ? "please enter a value " : ""}
          />
        </Grid>

        <Grid item xs>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            name="Address"
            onChange={changeValue}
            value={dAddress}
            fullWidth
            error={eAddress}
            label="Address"
            variant="standard"
            color="primary"
            placeholder="Address"
            helperText={eAddress ? "please enter a value " : ""}
          />
        </Grid>
        <Grid item xs>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            name="PhNumber"
            type="Number"
            value={dPhNumber}
            onChange={changeValue}
            fullWidth
            error={ePhNumber}
            label="Phone Number"
            variant="standard"
            color="primary"
            placeholder="PhNumber"
            helperText={ePhNumber ? "please enter a value " : ""}
          />
        </Grid>
        <Grid item xs>
          <TextField
            InputLabelProps={{ shrink: true }}
            name="LicExp"
            type="date"
            inputProps={{ min: minDate, max: maxDate}}
            onChange={changeValue}
            value={dLicExp}
            fullWidth
            error={eLicExp}
            label="License Expiry"
            variant="standard"
            color="primary"
            placeholder="LicExp"
            helperText={eLicExp ? "please enter a value " : ""}
          />
        </Grid>
        <Grid item xs>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            name="PdpExp"
            id="date"
            type="date"
            value={dPdpExp}
            onChange={changeValue}
            fullWidth
            inputProps={{ min: minDate }}
            error={ePdpExp}
            label="PDP Expiry"
            variant="standard"
            color="primary"
            placeholder="PdpExp"
            helperText={ePdpExp ? "please enter a value " : ""}
          />
        </Grid>
        <Grid item xs>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            name="DgdpExp"
            id="date"
            type="date"
            onChange={changeValue}
            value={dDgdpExp}
            fullWidth
            error={eDgdpExp}
            inputProps={{ min: minDate }}
            variant="standard"
            label="DGDP Expiry"
            color="primary"
            placeholder="DgdpExp"
            helperText={eDgdpExp ? "please enter a value " : ""}
          />
        </Grid>

        <Grid item xl>
          <Button
            onClick={submitVals}
            fullWidth
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  dDriver: state.driver.Driver,
  dName: state.driver.Name,
  dSurname: state.driver.Surname,
  dIdNo: state.driver.IdNo,
  dPdpExp: state.driver.PdpExp,
  dAddress: state.driver.Address,
  dPhNumber: state.driver.PhNumber,

  isEd: state.driver.isEditing,
  diID: state.driver.DriverID,

  dLicExp: state.driver.LicExp,
  dDgdpExp: state.driver.DgdpExp,
  eName: state.driver.errName,
  eSurname: state.driver.errSurname,
  eIdNo: state.driver.errIdNo,
  ePdpExp: state.driver.errPdpExp,
  eAddress: state.driver.errAddress,
  ePhNumber: state.driver.errPhNumber,
  eLicExp: state.driver.errLicExp,
  eDgdpExp: state.driver.errDgdpExp,
  snack: state.driver.Snack,
  snackNegative: state.driver.SnackNegative,
  SnackDriverID: state.driver.SnackDriverID,
});

export default connect(mapStateToProps, { entryValue, fetchDriver})(Driver);
