import { LOGIN_USER,LOGIN_USER_FAILED, SHOW_LOADER, HIDE_LOADER } from "../Actions/types";

const intitialState = {
  credentials: {},
  UsrName: "",
  Password: "",
  UsrNameErr: false,
  PassErr: false,
  LoginFailed: false,
  loading: false,
  load: false,
  success: false,
  disable: false
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = intitialState, action) {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        [action.objName]: action.payload,
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        [action.objName]: action.payload,
      };

      case SHOW_LOADER:
        return { ...state, loading: true };

      case HIDE_LOADER:
        return { ...state, loading: false };

    default:
      return state;
  }
}
