import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  Paper,
  Typography,
  Grid,
  Snackbar,
  TextField,
  Stack
} from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from '@material-ui/core/styles';
import { jobAdd } from "../../Actions/JobAction";
import { editSubTask, fetchSubTaskSpec } from '../../Actions/SubTaskAction'
import { fetchHelper } from "../../RequetsHandler/FetchHandler";
import SubTaskType from './SubTaskViewDialog';
import { taskAdd } from '../../Actions/TaskAction';
import { localAdd } from '../../Actions/localstore';
import Loader from "../../Login/Loader";
import { showLoader, hideLoader } from "../../Actions/LoginAction";
import Compress from "react-image-file-resizer";


const TaskViewDialog = ({ selectedTask, jobAdd, allValues, snack, snackNegative, editSubTask, fetchSubTaskSpec, startdis, closedis, taskAdd, jobdis, jobuser, reasondialog, reason, disabled, localAdd, eReasonST, snackST, snackNegativeST, showLoader, hideLoader, taskid }) => {
  // const [taskId, setTaskId] = useState("");

  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedExcel, setSelectedExcel] = useState([]);

  const useStyles = makeStyles((theme) => ({
    table: {
      align: "center",
      border: "solid",

    },
    tableCell: {
      border: "1px solid black",
      borderStyle: "paper",
      width: 250,
      fontSize: '8pt',
    },
    tableCellB: {
      background: "#007bff",
      border: "1px solid black",
      borderStyle: "paper",
      width: 250,
      fontSize: '8pt',
    },
    tableHead: {
      fontsize: '8pt',
      paddingLeft: 1,
      borderStyle: "paper",

    },
    heading: {
      fontSize: theme.typography.pxToRem(8),
      fontWeight: theme.typography.fontWeightRegular,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.primary,
    },
    label: {
      fontsize: '15pt',
      color: 'black',
      fontWeight: 'Bold'
    },
    button: {
      alignItems: "right",
      padding: 5,
      outline: "none",
      borderradius: "10px",
      border: "none",
      margin: "1em",
    },
    root: {

      padding: 5,
      alignItems: "right",
      color: "primary",
      variant: "outlined",
      size: "small",
      span: 3,
      offset: 3
    },
  }));


  const classes = useStyles();
  const handleClose = () => {
    jobAdd("snack", false)
    jobAdd("snacknegative", false)
    jobAdd("selectedShown", false);
    taskAdd("errReasonST", false)
    taskAdd("SnackNegativeTimer", false)
  };

  const success = () => {
    jobAdd("snack", true)
    hideLoader()
    handleClose()
  }

  const changeValue = (e) => {
    taskAdd(e.target.name, e.target.value);
  }

  const failure = () => {
    jobAdd("snacknegative", true)
  }

  const ShowDialogSubTask = (a) => {
    jobAdd("snack", false)
    jobAdd("snacknegative", false)
    editSubTask("subtaskOpen", true);
    fetchSubTaskSpec(a)
  }


  function jobSuccess() {
    //console.log("Time Added", cdates)
    localAdd("disabled", true)
    taskAdd("SnackTimer", true)

  }

  function jobFailure() {
    //console.log("Time Not Added")
    taskAdd("SnackNegativeTimer", true)
  }
  const addingTime = async (ab) => {

    fetchHelper.postData("api/TaskTracker", ab, jobSuccess, jobFailure);

  };


  const dts = null;
  const [cdates, setDates] = useState(dts);

  const handlestart = async (a) => {
    let dts = new Date();
    setDates(dts);

    var obj = {
      durationid: 0,
      taskid: a.taskid,
      starts: dts
    }


    addingTime(obj);
  }

  function jobSuccessu() {
    //console.log("Time Updated", cdatec)
    taskAdd("reason", "")
    taskAdd("SnackTimer", true)
  }

  function jobFailureu() {
    //console.log("Time Not Updated")
    taskAdd("SnackNegativeTimer", true)
  }
  const updatingTime = async (ab) => {

    fetchHelper.updateData("api/TaskTracker", ab, jobSuccessu, jobFailureu);

  };

  const dtc = null;
  const [cdatec, setDatec] = useState(dtc);
  const handleclose = (TaskId) => {
    let dtc = new Date();
    setDatec(dtc);

    if (reason.trim() === "") {
      taskAdd("errReasonST", true)
      taskAdd("SnackNegativeTimer", true)
      return;
    }

    var obj = {
      taskid: TaskId,
      stop: dtc,
      reason: reason
    }
    updatingTime(obj)
  }





  const setSelectedPicture = (event) => {

    const picarr = event.target.files

    for (let i = 0; i < picarr.length; i++) {
      const element = picarr[i];


      setSelectedFile([...selectedFile, element])
    }
    
  }

  console.log(selectedFile)
  return (
    <Grid container spacing={2}>
      <Grid item xl={12} sm={12} md={12}>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snack}
          autoHideDuration={6000}
          onClose={() => {
            jobAdd("Snack", false);
          }}
          message="Successful Upload"
          action={<React.Fragment></React.Fragment>}
        />

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snackNegative}
          onClose={() => {
            jobAdd("SnackNegative", false);
          }}
          autoHideDuration={6000}
          message="Failed to Upload"
          action={<React.Fragment></React.Fragment>}
        />

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snackST}
          onClose={() => {
            taskAdd("SnackTimer", false);
          }}
          autoHideDuration={6000}
          message="Task Timer Started/Updated"
          action={<React.Fragment></React.Fragment>}
        />

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          open={snackNegativeST}
          onClose={() => {
            taskAdd("SnackNegativeTimer", false);
          }}
          autoHideDuration={6000}
          message="Failed to Stop Task Timer"
          action={<React.Fragment></React.Fragment>}
        />

        <Dialog open={selectedTask} onClose={handleClose} fullWidth xl>
          <DialogTitle>
            {allValues.length > 0 ? allValues[0].jobname : ""}
          </DialogTitle>
          <DialogContent>
            <b> {allValues.length > 0 ? "" : "No tasks sceduled"}</b>

            {allValues.map((a) => (
              <Paper style={{ margin: 10, padding: 5 }}>
                <Button color="primary" variant="outlined" size="small" onClick={() => { ShowDialogSubTask(a.taskid) }}> {a.taskname}</Button>
                {/* <Typography>
                <b>Task Name: </b> {a.taskname}
              </Typography> */}
                <Typography>
                  <b>Task Status: </b>
                  {a.status}
                </Typography>
                <Typography>
                  <b>Task Type: </b>
                  {a.tasktype}
                </Typography>
                <Typography>
                  <b>Start Time: </b>
                  {a.starttime}
                </Typography>
                <Typography>
                  <b>End Time: </b>
                  {a.endtime}
                </Typography>
                <Typography>
                <h5>Photo's</h5>
                  <input
                    type="file"
                    id="fileUpload"
                    color="primary"
                    classes={classes.root}
                    onChange={async (event) => {
                      hideLoader()
                      taskAdd("TaskID", a.taskid)
                      jobAdd("snack", false)
                      jobAdd("snacknegative", false)
                      setSelectedPicture(event)
                    }}
                    multiple
                  />
                </Typography>


                <Typography>
                  {taskid === a.taskid &&
                    <b>Pictures Taken: {selectedFile.length}</b>
                  }

                </Typography>

                <Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={async (event) => {
                      for (let i = 0; i < selectedFile.length; i++) {
                        Compress.imageFileResizer(

                          selectedFile[i], // the file from input
                          1920, // width
                          1080, // height
                          "JPEG", // compress format WEBP, JPEG, PNG
                          70, // quality
                          0, // rotation
                          (uri) => {
                            //console.log(uri);
                            // You upload logic goes here
                            const formData = new FormData();
                            formData.append("files", uri);
                            formData.append("taskid", a.taskid);
                            fetchHelper.post("api/fileUpload", formData, (res) => {
                              if (res.status === 200) {
                                //showLoader();
                                jobAdd("fileUpload", '')
                                jobAdd("snack", true)
                              
                              }
                              else {
                                if (res.status === 400) {
                                  hideLoader()
                                  jobAdd("snacknegative", true)
                                }
                              }
                            });
                          },
                          "file" // blob or base64 default base64
                        );
                      };
                      // const formData = new FormData();
                      // for (let i = 0; i < selectedFile.length; i++) {
                      //   formData.append("files", selectedFile[i]);
                      //   formData.append("taskid", a.taskid);
                      //   fetchHelper.post("api/fileUpload", formData, success, failure);
                      //   //console.log("formdata", formData);

                      // }
                      setSelectedFile([]);

                      //console.log("formdata", selectedFile);

                    }}>
                    Submit Photo
                  </Button>
                </Typography>



                
                <Typography>
                <h5>Excel</h5>
                  <input
                    type="file"
                    id="fileUpload"
                    color="primary"
                    classes={classes.root}
                    onChange={async (event) => {
                      hideLoader()
                      taskAdd("TaskID", a.taskid)
                      jobAdd("snack", false)
                      jobAdd("snacknegative", false)
                      setSelectedExcel(event.target.files)
                    }}
                    multiple
                  />
                </Typography>


                <Typography>
                  {taskid === a.taskid &&
                    <b>Excel Selected: {selectedExcel.length}</b>
                  }

                </Typography>

                <Typography>
                  <Button
                    color="primary"
                    variant="contained"
                    size="small"
                    onClick={async (event) => {
                      
                      const formData = new FormData();
                      for (let i = 0; i < selectedExcel.length; i++) {
                        formData.append("files", selectedExcel[i]);
                        formData.append("taskid", a.taskid);
                        fetchHelper.post("api/fileUpload", formData, success, failure);
                        //console.log("formdata", formData);

                      }
                      setSelectedExcel([]);
                    }}>
                    Submit Excel
                  </Button>
                </Typography>

                <b><Loader></Loader></b>


                <Grid>
                  <TextField
                    name="reason"
                    onChange={changeValue}
                    title="Timer"
                    label="Reason For Close"
                    variant="standard"
                    color="primary"
                    placeholder="Reason For Close"
                    error={eReasonST}
                    helperText={eReasonST ? "please enter a value " : ""}
                  >
                  </TextField>
                </Grid>
                <Grid>
                  <Button
                    onClick={async () => { handlestart(a) }}
                    disabled={startdis}
                    color="primary"
                    variant="outlined"
                    size="small">
                    Start Task
                  </Button>
                  <Button
                    onClick={async () => { handleclose(a.taskid) }}
                    disabled={closedis}
                    color="primary"
                    variant="outlined"
                    size="small"
                    error={eReasonST}
                    helperText={eReasonST ? "please enter a value " : ""}
                  >
                    Close Task
                  </Button>
                </Grid>

              </Paper>
            ))}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="outlined"
              size="small"
              onClick={handleClose}
            >
              Close</Button>
          </DialogActions>



        </Dialog>


      </Grid>

      <SubTaskType />
    </Grid>


  );
};

const mapStateToProps = (state) => ({
  allValues: state.jobRed.selectedJob,
  selectedTask: state.jobRed.selectedShown,
  task: state.jobRed.tasks,
  snack: state.jobRed.snack,
  Snackbar: state.jobRed.Snackbar,
  subtask: state.subred.subtask,
  subtaskOpen: state.subred.subtaskOpen,
  startdis: state.taskRed.startdis,
  closedis: state.taskRed.closedis,
  jobuser: state.jobRed.jobuser,
  reason: state.taskRed.reason,
  reasondialog: state.taskRed.reasondialog,
  disabled: state.local.disabled,

  snackST: state.taskRed.SnackTimer,
  snackNegativeST: state.taskRed.SnackNegativeTimer,
  snackTimerUpdate: state.taskRed.SnackTimerUpdate,
  eReasonST: state.taskRed.errReasonST,

  taskid: state.taskRed.TaskID
});

export default connect(mapStateToProps, { jobAdd, editSubTask, fetchSubTaskSpec, taskAdd, localAdd, showLoader, hideLoader, })(TaskViewDialog);
