import React from 'react'
import { Grid, Button, Dialog, DialogContent, DialogTitle, DialogActions, TextField, InputLabel, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux'
import { fetchJobSP, fetchTaskSpec } from "../Actions/JobAction";
import { fetchTask, taskAdd } from '../Actions/TaskAction';
import { editSubTask } from '../Actions/SubTaskAction';
import { fetchHelper } from '../RequetsHandler/FetchHandler';
import TaskStatus from '../Pages/Task/TaskStatus';

const SubTaskList = ({

    SubTaskID,
    SubTaskName,
    Description,
    TaskID,
    StatusID,
    errSubTaskName,
    errTaskID,
    errDescription,
    errStatusID,
    Snacks,
    SnackNegatives,
    isEd,
    subtaskpath,
    subtaskOpen,
    DisplaySubTasks,
    subtask,
    status,
    taskAdd,
    subTaskType,
    editSubTask


}) => {

    const btnStyle = makeStyles((theme) => ({
        root: {
            width: "99%",
            margin: 2,
        },
    }));
    const classBtn = btnStyle();


    const changeValue = (e) => {

        editSubTask(e.target.name, e.target.value);
        console.log(e.target.name)
        console.log(e.target.value)
        // if (e.target.value === "" | e.target.value === 0) {
        //     editSubTask(`err${e.target.name}`, true)
        // } else {
        //     editSubTask(`err${e.target.name}`, false)
        // }

    }

    const changeValuestatus = (e) => {
        taskAdd(e.target.name, e.target.value);
        console.log(e.target.name)
        console.log(e.target.value)
    }
    function subTaskSuccess() {
        fetchTask();
        editSubTask("Snacks", true);
        editSubTask("subtaskOpen", false);
        editSubTask("SubTaskID", 0)
        editSubTask("SubTaskName", "")
        editSubTask("Description", "")
        editSubTask("TaskID", 0)
        editSubTask("StatusID", 0)
    }

    function subTaskFailure() {
        editSubTask("SnackNegatives", true);
    }

    const HandleClose = () => {
        
        editSubTask("subtaskOpen", false);
        console.log("dialog close",subtaskOpen)
    }

    const addsubtype = async (ab) => {
        console.log(ab)
        fetchHelper.postData('api/TaskType', ab, subTaskSuccess, subTaskFailure);
    }

    const submitVals = () => {
        console.log("button pressed")

        var valid = true;

        if ((SubTaskName.trim() === "")) {
            valid = false;
            editSubTask("errSubTaskName", true)
            editSubTask("SnackNegatives", true)
            return
        }

        if (Description.trim() === "" ) {
            valid = false;
            editSubTask("errDescription", true)
            editSubTask("SnackNegatives", true)
            return
        }

        if (StatusID === 0) {
            valid = false;
            taskAdd("errStatusID", true)
            editSubTask("SnackNegatives", true)
            return
        }


        if (valid) {
            var obj = {
                tasktypeid : 0,
                tasktypename: SubTaskName,
                description: Description,
                jobstatusid: StatusID,
                taskid: TaskID
            }

            addsubtype(obj);

        }
        else {

            if ((SubTaskName.trim() === "") ) {

                editSubTask("errSubTaskName", true)
                editSubTask("SnackNegatives", true)
                return;
            }

            if (Description.trim() === "" ) {

                editSubTask("errDescription", true)
                editSubTask("SnackNegatives", true)
                return;
            }

            if (StatusID === 0) {

                taskAdd("errStatusID", true)
                editSubTask("SnackNegatives", true)
                return;
            }



        }


    }

    return (
        <Grid container spacing={2} >
            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={Snacks}
                autoHideDuration={6000}
                onClose={() => { editSubTask("Snacks", false) }}
                message="Sub-Task has been added"
                action={
                    <React.Fragment>

                    </React.Fragment>
                } />

            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={SnackNegatives}

                onClose={() => { editSubTask("SnackNegatives", false) }}
                autoHideDuration={6000}
                message="The Sub-Task could not be added"
                action={
                    <React.Fragment>
                    </React.Fragment>
                } />

            <Grid item xs={12}>


                <Dialog name="DialogOpen" open={subtaskOpen} onClose={HandleClose}>
                    <DialogTitle>Sub-Task</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>


                            <Grid item xs={6}>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="SubTaskName"
                                    className={classBtn.root}
                                    onChange={changeValue}
                                    error={errSubTaskName}
                                    label="Sub-Task Name"
                                    variant="standard"
                                    color="primary"
                                    placeholder="Sub-Task Name"
                                    required
                                    helperText={errSubTaskName ? "Please enter Sub-Type Name " : ""}
                                    value={SubTaskName}
                                    disabled={isEd}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="Description"
                                    onChange={changeValue}
                                    value={Description}
                                    fullWidth
                                    error={errDescription}
                                    label="Description"
                                    variant="standard"
                                    color="primary"
                                    required
                                    placeholder="Description"
                                    helperText={errDescription ? "Please Enter Description " : ""}
                                    disabled={isEd}
                                />
                            </Grid>

                            <Grid item xs>


                                
                                    <InputLabel error={errStatusID} >Status</InputLabel>

                                    <TaskStatus cList={status} onCh={changeValuestatus} />

                                

                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={HandleClose}>
                            Cancel
                        </Button>

                        <Button
                            onClick={submitVals}
                            color="primary"
                            variant="contained"
                            style={{ textTransform: "none" }}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = state => ({
    SubTaskName: state.subred.SubTaskName,
    Description: state.subred.Description,
    TaskID: state.subred.TaskID,
    errSubTaskName: state.subred.errSubTaskName,
    errTaskID: state.subred.errTaskID,
    errDescription: state.subred.errDescription,
    errStatusID: state.taskRed.errStatusID,
    Snacks: state.subred.Snacks,
    SnackNegatives: state.subred.SnackNegatives,
    isEd: state.subred.isEd,
    subtaskOpen: state.subred.subtaskOpen,
    DisplaySubTasks: state.subred.DisplaySubTasks,
    subtask: state.subred.subtask,
    status: state.subred.status,
    task: state.subred.task,
    SubTaskType: state.subred.SubTaskType,
    StatusID: state.taskRed.StatusID
})

export default connect(mapStateToProps, { fetchTaskSpec, fetchJobSP, fetchTask, taskAdd, editSubTask })(SubTaskList)