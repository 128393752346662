import { Container, Grid, Paper, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import AddVehicle from './AddVehicle'
import VehicleTable from './VehicleTable'
import {fetchModels} from '../../Actions/vehicleAction'
import { connect } from 'react-redux'


const VehicleDetails = ({
fetchModels


}) => {
useEffect(() => {
        
    fetchModels();

    return () => {
    
    }
}, [fetchModels])



    return (
        <Container maxWidth="xl"  >
                <Grid container  justify="center" spacing={2}  >

                <Grid item xs ={12}>
                <Typography variant="h4">
                        Models
                    </Typography>
                </Grid>

                <Grid item xs={9}>
                    <Paper>
                    <VehicleTable></VehicleTable>
                    </Paper>
                </Grid>

                <Grid item xs={3} >
                    
                    <Paper>
                    <AddVehicle></AddVehicle>
                    </Paper>
                </Grid>
               



                </Grid>
        </Container>
    )
}

const MapStatetoProps = state => ({



})


export default connect (MapStatetoProps , {fetchModels})(VehicleDetails)
