import {FETCHSUBTASKSPEC, ADDSUBTASK, FETCHSUBTASKTRACKER} from '../Actions/types';
import {API_ADDR} from '../DatabaseStrings/dbStrings';
import auth from '../Authentication/auth';

export const editSubTask = (objName , value) => dispatch =>{
    dispatch({
        type:ADDSUBTASK ,
        payload: value ,
        objName: objName

    }) ; 
}

export const fetchSubTaskSpec = (taskid) =>  async (dispatch) => {
    //console.log("inside fetch request for subtask. Task-ID",taskid)
    fetch(`${API_ADDR}/api/TaskType?TaskID=${taskid}`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHSUBTASKSPEC , 
            payload: data
        })).catch(err => console.error(err))
}

export const fetchSubTaskTracker = (SubTaskID) => async (dispatch) =>{
    //console.log("inside fetch request for subtask Timer",SubTaskID)
    fetch(`${API_ADDR}/api/SubTaskTracker?SubTaskid=${SubTaskID}`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHSUBTASKTRACKER , 
            payload: data
        })).catch(err => console.error(err))
}