import {FETCHCOMPANYFLEET} from '../Actions/types'

const initialState = {

    companyfleetdetails:[]
}

export default function red(state = initialState, action)
{
    switch(action.type)
    {
        case FETCHCOMPANYFLEET:
            return{
                  ...state,
                  companyfleetdetails: action.payload
                }
                default:
                    return state;
            
    }
}