import React from 'react'
import { MenuItem, Select, Container } from '@material-ui/core'
import { connect } from 'react-redux'
import {vehicelDetailsAdd} from '../../Actions/VehicleDetailsAction'

const Model = ({arr , modelChoice , vehicelDetailsAdd , chang,onCH ,ed, eModelID}) => {
  
    // const c = (e) => {
        
    //     console.log(e.target.value)
    //     console.log(e.target.name);
    //     console.log(arr);
    //     vehicelDetailsAdd(e.target.name , e.target.value)
    // }  
  


    return (
        <Container>

        <Select 
        disabled = {ed}
        error={eModelID}
        name="modelId" value={modelChoice}  onChange={onCH} variant="standard" fullWidth={true} placeholder="Model" >
                <MenuItem value={0}><em>None</em></MenuItem>
            {arr.map(x => (
                        
                <MenuItem 
                key={x.modelID} 
                value={x.modelID}>

                     {x.modelName}  {x.vehicletypename}
                     
                     </MenuItem>

            ))}
        </Select>
        </Container>
    )
}

const mapStateToProps = state => ({

    modelChoice : state.vehicleDetail.modelId , 
    arr : state.vehicleDetail.vModel ,
    eModelID : state.vehicleDetail.errmodelId,
    ed  : state.vehicleDetail.isEditing

})


export default connect(mapStateToProps , {vehicelDetailsAdd})(Model)
