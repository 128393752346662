import { FETCH_MENU, FETCH_ITEMS, FETCH_MENU_ITEMS, FECTH_SIDEBAR } from './types';
import {API_ADDR} from '../DatabaseStrings/dbStrings'
import auth from '../Authentication/auth';

export const fetchMenu = () => dispatch => {
        
         fetch(`${API_ADDR}/api/Menu`,
         
         {
             method:'GET',
             mode:'cors',
             credentials:'include',
             referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCH_MENU , 
            payload: data
        })).catch(err => console.error(err))

}

export const fecthItems = () => dispatch => {

        fetch(`${API_ADDR}/api/Item`,
         
        {
            method:'GET',
            mode:'cors',
            credentials:'include',
            referrerPolicy:'strict-origin-when-cross-origin',
            headers:{
                Authorization: `Bearer ${auth.getToken()}`,
               'Accept': 'application/json',
               
            }
        })
        .then(res => res.json())
        .then(data => dispatch({
            type: FETCH_ITEMS,
            payload: data


        })).catch(err => console.error(err))

}

export const fecthMI = () =>dispatch => {
    
    fetch(`${API_ADDR}/api/MenuItem`,    
    {
        method:'GET',
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
           'Accept': 'application/json',
           
        }
    })
    .then(res => res.json())
    .then(data => dispatch({
        type:FETCH_MENU_ITEMS , 
        payload:data,
        someValue:'Hello World'

        })).catch(err => console.error(err))


}

export const fetchSidebar = (id) => dispatch => {
    
    fetch(`${API_ADDR}/api/MenuItem/${auth.getRoleID()}`,
         
    {
        method:"GET" ,
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
           'Accept': 'application/json',
           
        }
    })
    .then(res => res.json())
    .then(data => dispatch({
        type: FECTH_SIDEBAR,
        payload: data

        })).catch(err => console.error(err));

}