import { ADDTASK, FETCHTASK, FETCHJOBTASK, FETCHJOBSTATUS, FETCHTASKTRACKER } from '../Actions/types';

const initialState = {
    TaskID: 0,
    TaskName: '',
    TaskType: '',
    StartTime: '',
    EndTime: '',
    StatusID: 0,
    JobID: 0,
    status: [],
    job: [],
    task: [],
    errTaskName: false,
    errTaskType: false,
    errStartTime: false,
    errEndTime: false,
    errStatusID: false,
    errJobID: false,
    Snack: false,
    SnackNegative: false,
    SnackTimer: false,
    SnackNegativeTimer: false,
    isEd: false,
    taskpath: '',
    taskOpen: false,
    DisplayTasks: false,
    subTaskType: [],
    startdis: false,
    closedis: false,
    jobdis: false,
    reason: '',
    reasondialog: true,
    taskTracker: [],
    trackerdialog: false,
    errReasonST: false,
}

export default function red(state = initialState, action) {
    switch (action.type) {

        case ADDTASK:

            return {
                ...state,
                [action.objName]: action.payload

            }

        case FETCHTASK:
            return {
                ...state,
                task: action.payload
            }

        case FETCHJOBTASK:
            return {
                ...state,
                job: action.payload
            }



        case FETCHJOBSTATUS:
            return {

                ...state,
                status: action.payload

            }

        case FETCHTASKTRACKER:
            return {

                ...state,
                taskTracker: action.payload

            }

        default:
            return state;

    }

}