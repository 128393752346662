import {combineReducers}from 'redux' ; 
import postReducer from './postReducer';
import loginReducer from './LoginReducer' ; 
import sIgnUpReducer from './SIgnUpReducer';
import driverReducer from './DriverReducer';
import driverDetailsRed from './DriverDetailsReducer';
import vehicleDetailsReducer from './VehicleDetailsReducer'
import companyfleetdetailsReducer from './CompanyFleetDetailsReducer';
import VehicleReducer from './VehicleReducer';
import FleetDetailsReducer from './FleetDetailsReducer';
import ServiceReducer from './ServiceReducer';
import SiteReducer from './SiteReducer';
import ProjRed from './ProjectReducer';
import TeamMemRed from './TeamMemberRed';
import JobRed from './JobReducer';
import TaskRed from './TaskReducer';
import Subred from './SubTaskReducer';
import TeamAssignedRed from './TeamAssignedReducer';
import locals from './localStoreReducer';
import NSB from './NsbReducer';
import FOLDER from './FolderReducer';

export default combineReducers({

menu : postReducer ,
loginCred : loginReducer,
signUpPage : sIgnUpReducer,
driver: driverReducer,
Vehicle : VehicleReducer ,
companyFleetDetail: companyfleetdetailsReducer,
driverDetails: driverDetailsRed,
vehicleDetail: vehicleDetailsReducer,
companyfleetdetails: FleetDetailsReducer,
service: ServiceReducer,
sitecom: SiteReducer,
projRed: ProjRed,
teamMemRed: TeamMemRed,
jobRed: JobRed,
taskRed: TaskRed,
subred: Subred,
teamAssignRed: TeamAssignedRed,
nsb: NSB,
local: locals,
folder: FOLDER,


})