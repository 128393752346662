import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Paper,
    Typography,
    Grid,
    Snackbar,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { editSubTask } from '../Actions/SubTaskAction';
//import { fetchHelper } from "../RequetsHandler/FetchHandler";

const SubTask = ({ subtask, DisplaySubTasks, editSubTask, snack, snackNegative }) => {

    //const [selectedFile, setSelectedFile] = useState(null);

    const handleClose = () => {
        editSubTask("DisplaySubTasks", false);
    };

    // const success = () => {
    //     editSubTask("Snack", true)
    //     handleClose()
    // }

    // const failure = () => {
    //     editSubTask("SnackNegative", true)
    // }

    return (
        <Grid>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => {
                    editSubTask("Snack", false);
                }}
                message="Successful Upload"
                action={<React.Fragment></React.Fragment>}
            />

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snackNegative}
                onClose={() => {
                    editSubTask("SnackNegative", false);
                }}
                autoHideDuration={6000}
                message="Failed to Upload"
                action={<React.Fragment></React.Fragment>}
            />

            <Dialog open={DisplaySubTasks} onClose={handleClose} fullWidth xl>
                <DialogTitle>
                    {subtask.length > 0 ? subtask[0].taskname : ""}
                </DialogTitle>
                <DialogContent>
                    <b> {subtask.length > 0 ? "" : "No tasks sceduled"}</b>

                    {subtask.map((a) => (
                        <Paper style={{ margin: 10, padding: 5 }}>
                            <Typography>
                                <b>Sub-Task Name: </b>
                                {a.tasktypename}
                            </Typography>
                            <Typography>
                                <b>Description: </b>
                                {a.description}
                            </Typography>
                            <Typography>
                                <b>Task Status: </b>
                                {a.status}
                            </Typography>
                           

                            {/* <Input
                                type="file"
                                id="fileUpload"
                                color="primary"
                                onChange={(event) => setSelectedFile(event.target.files[0])}
                            /> */}
                            {/* <Button onClick={async (event) => {
                                //console.log(a.taskid, selectedFile)
                                const formData = new FormData();
                                formData.append("files", selectedFile);
                                formData.append("taskid", a.tasktypeid);
                                //console.log("formdata", formData)
                                fetchHelper.post("api/FileSubTask", formData, success, failure);

                            }}>
                                Submit File
                            </Button> */}
                        </Paper>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    subtask: state.subred.subtask,
    DisplaySubTasks: state.subred.DisplaySubTasks,
    snack: state.subred.Snack,
    snackNegative: state.subred.SnackNegative
});


export default connect(mapStateToProps, { editSubTask })(SubTask)
