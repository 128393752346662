import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import {vehicelDetailsAdd, fetchCompanyFleet, fetchDriver, fetchModel, fecthVehicleGroup} from '../../Actions/VehicleDetailsAction';
import {fetchHelper} from '../../RequetsHandler/FetchHandler';
const vehicleGroupDialog = ({

    vehicelDetailsAdd , 
    vOpen,
    vVehGroup,
    fetchDriver,
    fetchModel,
    fecthVehicleGroup,
    fetchCompanyFleet


}) =>{

    const changeHandler = (e) =>{
        vehicelDetailsAdd(e.target.name , e.target.value)
    }

    const HandleClose = () => {

        vehicelDetailsAdd("vVehicleGroupOpen" , false)
        
        } 

    function vehicleGroupAddSuccess(){
        fetchCompanyFleet() ;
        fetchDriver();
        fetchModel();
        fecthVehicleGroup();
        HandleClose();
    }

    function vehiclegroupAddFailure(){

    }


        const handleVehicleGroupAdd = () => {


            if(vVehGroup.trim() !== ""){
            
                
               
                var raw = {"vehicleGroupID":0,"description":vVehGroup};

                fetchHelper.postData('api/vehicleGroup', raw , vehicleGroupAddSuccess , vehiclegroupAddFailure);

                
            
            }else{
            
           
            
            }
            
            }

            return (
                <Dialog name= "DialogRole" open={vOpen} onClose={HandleClose} fullWidth={true} maxWidth="sm" >
                <DialogTitle >Add Vehicle Group</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Enter vehicle group
                </DialogContentText>
        
        
                        <TextField placeholder="Vehicle Group" label="Vehicle Group" fullWidth name="vVehicleGroupName" onChange={changeHandler}/>
        
                </DialogContent>
        
                <DialogActions>
                    <Button color="primary" onClick={HandleClose}>
                        Cancel
                    </Button>
        
                    <Button color="primary" onClick={handleVehicleGroupAdd}>
                        Finalise
                    </Button>
                </DialogActions>
            </Dialog>
            )
}

const mapStateToProps = state => ({

    vOpen : state.vehicleDetail.vVehicleGroupOpen , 
    vVehGroup :state.vehicleDetail.vVehicleGroupName
    
    })
    
    
    export default connect(mapStateToProps , {vehicelDetailsAdd , fetchCompanyFleet , fetchDriver, fetchModel, fecthVehicleGroup})(vehicleGroupDialog)
     