import { FETCHDRIVERS} from '../Actions/types'

const intitialState = { 
    driverDetails: []
    // Driver: '',
    // Name: '',
    // Surname: '',
    // IdNo: '',
    // PdpExp: '',
    // Address: '',
    // PhNumber: '',
    // LicExp: '',
    // DgdpExp: ''
}

export default function red(state = intitialState, action){
    switch(action.type){
        case FETCHDRIVERS:
            return{
                ...state,
                driverDetails: action.payload
            }
        default:
            return state ;
    }
}