import React, { useEffect } from "react";
import { Box, Grid, Typography, Container, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { DataGrid, GridToolbarContainer,GridToolbarExport, } from "@material-ui/data-grid";
import { fetchJT } from "../Actions/JobAction";

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDataGrid-row.Mui-even:not(:hover)': {
      backgroundColor: theme.palette.type === 'light' ? '#007bff' : 'rgba(255, 255, 255, 0.04)',
    },
  },
  menuTypography: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


const Report = ({

  JobTime,
  fetchJT


}) => {

  const classes = useStyles();
  const rows = JobTime.map((row) => {
    return {
      id: row.durationid,
     jobname: row.jobname,
     starts: row.starts,
     stop: row.stop,
     duration: row.duration,
     taskname: row.taskname,
     projectname: row.projectname,
    };
  });
  const columns = [
    { field: "id", hide: true },
    {
        field: "projectname",
        headerName: "Project",
        width: 125,
      },
    {
      field: "jobname",
      headerName: "Job",
      width: 125,
    },
    {
        field: "taskname",
        headerName: "Task",
        width: 125,
      },
    {
      field: "starts",
      headerName: "Task Start Time",
      width: 200,
    },
    {
      field: "stop",
      headerName: "Task Stop Time",
      width: 200,
    },
    {
        field: "duration",
        headerName: "Duration of Task",
        width: 200,
      },
      

  ];

  useEffect(() => {
    fetchJT()
    return () => {


    }
  }, [fetchJT])

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport/>
      </GridToolbarContainer>
    );
  }
  
  return (
    <Container>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          <Typography variant="h4">
            <Box fontWeight="fontWeightBold"> Task Report</Box>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div style={{ display: "flex", height: "100%" }}>
            <Paper elevation={5} />
            <div style={{ flexGrow: 1, height: "80vh" }}>
              <DataGrid 
              className={classes.root}
              data={JobTime}
              rows={rows} 
              columns={columns} 
              components={{Toolbar: CustomToolbar,}}
              />
            </div>
            <Paper />
          </div>
        </Grid>
      </Grid>
    </Container>


  );
};



const mapStateToProps = (state) => ({

  JobTime: state.jobRed.JT
})

export default connect(mapStateToProps, { fetchJT })(Report);

