import React, { useEffect } from "react";
import { Box, Grid, Typography, Container, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchMenu, fetchSidebar } from "../Actions/MenuActions";
import { fetchSite } from '../Actions/SiteAction';
import { fetchProj } from "../Actions/ProjectAction";
import { fetchTask } from '../Actions/TaskAction';
import { DataGrid, GridToolbarContainer,GridToolbarExport, } from "@material-ui/data-grid";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDataGrid-row.Mui-even:not(:hover)': {
      backgroundColor: theme.palette.type === 'light' ? '#007bff' : 'rgba(255, 255, 255, 0.04)',
    },
  },
  menuTypography: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


const Report = ({

  proj,
  siteList,
  task,
  fetchMenu,
  fetchSidebar,
  fetchProj,
  fetchSite,
  fetchTask,



}) => {

  const classes = useStyles();
  const rows = proj.map((row) => {
    return {
      id: row.projectid,
      projectname: row.projectname,
      projectdetails: row.projectdetails,
      status: row.status,
    };
  });
  const columns = [
    { field: "id", hide: true },
    {
      field: "projectname",
      headerName: "Project Name",
      width: 300,
    },
    {
      field: "projectdetails",
      headerName: "Project Details",
      width: 300,
    },
    {
      field: "status",
      headerName: "Status",
      width: 300,
    },

  ];

  useEffect(() => {
    fetchMenu();
    fetchSidebar(1);
    fetchProj();
    fetchSite();
    fetchTask();
    return () => {


    }
  }, [fetchMenu, fetchSidebar, fetchProj, fetchSite, fetchTask])

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarExport/>
      </GridToolbarContainer>
    );
  }
  
  return (
    <Container>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          <Typography variant="h4">
            <Box fontWeight="fontWeightBold"> Project Report</Box>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div style={{ display: "flex", height: "100%" }}>
            <Paper elevation={5} />
            <div style={{ flexGrow: 1, height: "80vh" }}>
              <DataGrid 
              className={classes.root}
              data={proj}
              rows={rows} 
              columns={columns} 
              components={{Toolbar: CustomToolbar,}}
              />
            </div>
            <Paper />
          </div>
        </Grid>
      </Grid>
    </Container>


  );
};



const mapStateToProps = (state) => ({


  menu: state.menu.menuItems,
  sb: state.menu.sidebar,
  proj: state.projRed.Project,
  siteList: state.sitecom.SiteList,
  task: state.taskRed.task,

})

export default connect(mapStateToProps, { fetchMenu, fetchSidebar, fetchProj, fetchSite, fetchTask })(Report);

