import { Container, MenuItem, Select } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { taskAdd, fetchJob } from '../../Actions/TaskAction'


const TaskJobSelect = ({ usrJob, cList, eJobID, onCh, isEd }) => {

    return (
        <Container>
            <Select
                disabled={isEd}
                name="JobID"
                error={eJobID}
                onChange={onCh}
                fullWidth
                placeholder="Hello World"
                value={usrJob}
            >
                <MenuItem value={0}> <em>None</em></MenuItem>
                {cList.map((item) => (

                    <MenuItem value={item.jobid}>{item.jobname}</MenuItem>

                ))}

            </Select>



        </Container>
    )
}
const mapStateToProps = state => ({
    usrJob: state.taskRed.JobID,
    eJobID: state.taskRed.errJobID,
    isEd: state.taskRed.isEd

})

export default connect(mapStateToProps, { taskAdd, fetchJob })(TaskJobSelect)
