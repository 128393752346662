import './App.css';
import SignUpPage from './SignUpPage/SignUpPage';
import {BrowserRouter as Router ,  Route, Switch} from 'react-router-dom';
import Login from './Login/Login';
import Dashboard from './Dashboard/Dashboard';
import { ProtectedRoute } from './ProtectedRoute/ProtectedRoute';
import Driver from './Drivers/Driver'
import {Provider} from 'react-redux';
import store from "./Store/store" ; 
import CompanyFleetDetails from './CompanyFleetDetails/CompanyFleetDetails';
import React from 'react';

function App() {


  return (

    <Provider store={store}>
    <div className="App">
      
      <Router>
        <Switch>
        <Route exact path="/" component={Login}/>
        <Route path="/signUp" component={SignUpPage}/>
        <ProtectedRoute path="/Dashboard" component={Dashboard}/>
        
        <Route path="/CompanyFleetDetails" component={CompanyFleetDetails}/>
        <Route path="/Driver" component={Driver}/>

        </Switch>
      </Router>
      
    </div>
    </Provider>
  );
}

export default App;
