import {ADDNSB, FETCHNSB} from '../Actions/types';
import {API_ADDR} from '../DatabaseStrings/dbStrings';
import auth from '../Authentication/auth'

export const AddNsb = (objName, value) => dispatch => {
    dispatch({
        type: ADDNSB,
        payload: value,
        objName: objName
    });
}

export const fetchNsb = () => async (dispatch) =>{
    fetch(`${API_ADDR}/api/Nsb`,
         {  
            mode:'cors',
            credentials:'include',
            referrerPolicy:'strict-origin-when-cross-origin',
            headers:{
            Authorization: `Bearer ${auth.getToken()}`,
            'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHNSB, 
            payload: data
            
        })).catch(err => console.error(err))
}

