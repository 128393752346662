import React, { useEffect } from 'react'
import { fetchTeamMemberAssigned, teamAdd } from '../../Actions/TeamMemberAction'
import { connect } from 'react-redux'
import { Container, Grid, Paper, Button, TextField, makeStyles, Snackbar } from '@material-ui/core';
import { DataGrid } from "@material-ui/data-grid";
import { fetchHelper } from '../../RequetsHandler/FetchHandler';

const TeamMembers = ({

    teamAdd,
    fetchTeamMemberAssigned,
    TeamMemberAssigned,
    teamassignedid,
    teamid,
    teammemberid,
    teamname,
    teammembername,
    snack,
    snackNegative

}) => {


    const rows = TeamMemberAssigned.map((row) => {
        return {
            id: row.teamassignedid,
            teamname: row.teamname,
            teamid: row.teamid,
            teammembername: row.teammembername,
            teamleader: row.teamleader,
            Delete: row,


        };
    });
console.log(TeamMemberAssigned)
    const columns = [
        {
            field: "id",
            headerName: "TMA",
            width: 150,
        },
        {
            field: "teamname",
            headerName: "Team",
            width: 200,
        },
        {
            field: "teamid",
            headerName: "Team ID",
            width: 150,
        },
        {
            field: "teammembername",
            headerName: "Team Member",
            width: 200,
        },
        {
            field: "teamleader",
            headerName: "Team Leader",
            width: 200,
        },
        {
            field: "Delete",
            headerName: "Delete",
            sortable: false,
            filterable: false,
            width: 100,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <strong>
                        <Button
                            onClick={() => {
                                handleLink(params.value);
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 16 }}
                        >
                            Delete
                        </Button>
                    </strong>
                );
            },
        },

    ];
    const btnStyle = makeStyles((theme) => ({

        root: {
            width: '99%',
            margin: 2

        },
        paperHead: {
            margin: '5px',
            marginBottom: '15px',
            fontWeight: "bold"
        },
        table:
        {

            align: "center"
        },

        tableCell: {
            width: 250,
            borderStyle: "none",

        },
        tableHead: {
            paddingLeft: 10,
            borderStyle: 'none'

        },
        paper: {

            margin: '5px'
        }

    }))



    //console.log(TeamMemberAssigned);

    useEffect(() => {


        fetchTeamMemberAssigned();
        return () => {

        }
    }, [fetchTeamMemberAssigned])

    const changeValue = (e) => {
        teamAdd(e.target.name, e.target.value);

        if ((e.target.value === "") | (e.target.value === 0)) {
            teamAdd(`err${e.target.name}`, true);
        } else {
            teamAdd(`err${e.target.name}`, false);
        }
    }

    // function DeleteSuccess() {
    //     fetchTeamMemberAssigned();
    //     teamAdd("snack", true);
    //     teamAdd("teamassignedid", 0);
    //     teamAdd("teamid", 0)
    // }

    // function DeleteFailure() {
    //     teamAdd("snackNegative", true);
    // }

    const DeleteTMA = async (ab) => {
        //console.log(ab)

        fetchHelper.deleteData('api/TeamAssigned', ab, (res) => {
            //console.log(res.status)
            if (res.status === 200) {
                fetchTeamMemberAssigned();
                teamAdd("snack", true);
                teamAdd("teamassignedid", 0);
                teamAdd("teamid", 0)
            }
            else
                {
                    if (res.status === 400) 
                    {
                    teamAdd("snackNegative", true)
                    }
                }
        }, 
        //() => { teamAdd("SnackNegative", true) }

            //DeleteSuccess , DeleteFailure
        );

    }




    const submitValue = () => {



        // var valid = true;

        // if (teamassignedid.trim() === "")  {
        //     valid = false;
        //     teamAdd("SnackNegative", true)
        //     console.log("check tma id")
        //     return;

        // }

        // if (teamname.trim() === "" ) {
        //     valid = false;
        //     teamAdd("SnackNegative", true)
        //     console.log("check team id")
        //     return;

        // }

        // if (valid) {
        var TMA = {
            "teamassignedid": teamassignedid,
            "teamid": teamid,
        }



        DeleteTMA(TMA)



    }

    const classes = btnStyle();


    const handleLink = (row) => {
        //console.log("row",row);
        teamAdd("teamassignedid", row.teamassignedid);
        teamAdd("teamid", row.teamid);
    };
    return (
        <Container>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => {
                    teamAdd("snack", false);
                }}
                message="Team has been Removed"
                action={<React.Fragment></React.Fragment>}
            />

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snackNegative}
                onClose={() => {
                    teamAdd("snackNegative", false);
                }}
                autoHideDuration={6000}
                message="Team is linked to a Job"
                action={<React.Fragment></React.Fragment>}
            />


            <Container maxWidth="lg">
                <Grid container direction="column" spacing={2}>
                    <Grid container spacing={3}>


                        <Grid item xs={12} sm={6}>
                            <h2>Team Member Assigned</h2>
                            <div style={{ display: "flex", height: "100%" }}>
                                <Paper elevation={5} />
                                <div style={{ flexGrow: 1, height: "80vh" }}>
                                    <DataGrid rows={rows} columns={columns} />
                                </div>
                                <Paper />
                            </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <h2>Remove Assigned Team</h2>
                            <Paper>
                                <Grid item xs>

                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="TeamID"
                                        onChange={changeValue}
                                        value={teamid}
                                        fullWidth
                                        //inputProps={{ min: minDate }}
                                        //error={errstartTime}
                                        label="Team ID"
                                        variant="standard"
                                        color="primary"
                                        placeholder="Team ID"
                                    //helperText={errstartTime ? "please enter a value " : ''}
                                    //disabled={isEd}
                                    />
                                </Grid>


                                <Grid item xs>

                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="TeamAssignedID"
                                        onChange={changeValue}
                                        value={teamassignedid}
                                        fullWidth
                                        //inputProps={{ min: minDate }}
                                        //error={errendTime}
                                        label="Team Assigned ID"
                                        variant="standard"
                                        color="primary"
                                        placeholder="Team Assigned ID"
                                    //helperText={errendTime ? "please enter a value " : ''} 
                                    />


                                    <Grid className={classes.root} item xs={12}  >
                                        <Button onClick={submitValue} className={classes.root} variant="contained" color="primary" style={{ textTransform: 'none' }}>Delete</Button>
                                    </Grid>
                                </Grid>
                            </Paper>

                        </Grid>

                    </Grid>
                </Grid>


            </Container>
        </Container>
    )
}

const mapStateToProps = state => ({

    TeamMemberAssigned: state.teamAssignRed.TeamMemberAssigned,
    teamassignedid: state.teamAssignRed.teamassignedid,
    teamid: state.teamAssignRed.teamid,
    teammemberid: state.teamAssignRed.teammemberid,
    teamname: state.teamAssignRed.teamname,
    teammembername: state.teamAssignRed.teammembername,
    snack: state.teamAssignRed.snack,
    snackNegative: state.teamAssignRed.snackNegative
})

export default connect(mapStateToProps, { fetchTeamMemberAssigned, teamAdd })(TeamMembers)