import {ADDCOMPANYFLEETDETAILS, COMPANY_DISPLAY } from '../Actions/types';
import {API_ADDR} from '../DatabaseStrings/dbStrings';
import auth from '../Authentication/auth'


export const entryValue = (objName, value) => dispatch => {
    
    dispatch({
        type: ADDCOMPANYFLEETDETAILS,
        payload: value,
        objName: objName
    });
}

export const fetchCompany = () => async(dispatch) => {

    await fetch(`${API_ADDR}/api/Company`,
    {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
           'Accept': 'application/json',
          
        }
    }).then(res => res.json())
     .then(data => dispatch({
 
         type:COMPANY_DISPLAY ,
         payload:data 
        
 
     })).catch(err => console.log(err))
 
 }