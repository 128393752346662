import React, { useEffect } from "react";
import {
  Paper,
  Grid
} from "@material-ui/core";
import { connect } from "react-redux";
import {} from "../../Actions/vehicleAction";
import { DataGrid } from "@material-ui/data-grid";

const VehicleTable = ({ models }) => {

  const rows = models.map((row) => {
    //console.log(row)
    return {
      
      id: row.modelID,
      modelName: row.modelName,
      vehicletypename: row.vehicletypename,
      makename: row.makename,
      edit: row,
    };
  });

  const columns = [
    { field: "id", hide: true },
    {
      field: "modelName",
      headerName: "Model",
      width: 150,
    },
    {
      field: "vehicletypename",
      headerName: "Vehicle Type",
      width: 150,
    },
    {
      field: "makename",
      headerName: "Make",
      width: 150,
    },
  ];
  useEffect(() => {
    return () => {};
  }, []);


  return (
    <Grid item xs={12}>
    <div style={{ display: "flex", height: "100%" }}>
      <Paper elevation={5} />
      <div style={{ flexGrow: 1, height: "80vh" }}>
        <DataGrid rows={rows} columns={columns} />
      </div>
      <Paper />
    </div>
  </Grid>
  );
};

const mapStateToProps = (state) => ({
  models: state.Vehicle.vModel,
});

export default connect(mapStateToProps, {})(VehicleTable);
