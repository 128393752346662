import { MenuItem, Select, Container } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import {vehicelDetailsAdd} from '../../Actions/VehicleDetailsAction'

const driver = ({arr ,eDriver , driverChoice ,onCH, vehicelDetailsAdd}) => {
  
    // const c = (e) => {

    //     console.log(e.target.value)
    //     console.log(e.target.name);
    //     console.log(arr);
    //     console.log("fdwsf");
    //     vehicelDetailsAdd(e.target.name , e.target.value)
    // }  
  
    return (
        <Container>

        <Select
        error={eDriver}
        name="driverId" value={driverChoice} variant="standard" onChange={onCH} fullWidth={true} placeholder="Driver" >
                <MenuItem value={0}><em>None</em></MenuItem>
            {arr.map(x => (

                <MenuItem 
                key={x.driverID} 
                value={x.driverID}>

                     {x.name}
                     
                     </MenuItem>

            ))}
        </Select>
        </Container>
    )
}

const mapStateToProps = state => ({

    driverChoice : state.vehicleDetail.driverId , 
    arr : state.vehicleDetail.vDriver ,
    eDriver : state.vehicleDetail.errdriverId


})


export default connect(mapStateToProps , {vehicelDetailsAdd})(driver)
