import {  MenuItem, Select, Container } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import {vehicelEdit} from "../../Actions/vehicleAction"

const VehicleTypes = ({arr , vehicleChoice ,evehicleType, vehicelEdit  , onCh}) => {
  
    // const c = (e) => {

    //     console.log(e.target.value)
    //     console.log(e.target.name);
    //     console.log(arr);
    //     vehicelEdit(e.target.name , e.target.value)
    // }  
  
    return (
        <Container>

        <Select
        error={evehicleType}
        name="vehicleType" value={vehicleChoice} variant="standard" onChange={onCh} fullWidth={true} placeholder="Vehicle Type" >
                <MenuItem value={0}><em>None</em></MenuItem>
            {arr.map(x => (

                <MenuItem 
                key={x.vehicleTypeID} 
                value={x.vehicleTypeID}>

                     {x.vehicleTypeName}
                     
                     </MenuItem>

            ))}
        </Select>
        </Container>
    )
}
const mapStateToProps = state => ({

    vehicleChoice : state.Vehicle.vehicleType , 
    arr : state.Vehicle.vType ,
    evehicleType : state.Vehicle.errvehicleType


})


export default connect(mapStateToProps , {vehicelEdit})(VehicleTypes)
