import React, { useEffect } from 'react'
import { Grid, Button, Paper, Dialog, DialogContent, DialogTitle, DialogActions, TextField, InputLabel, Snackbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { fetchProj, projAdd, fetchSites, fetchStatus, fetchProjSite } from "../Actions/ProjectAction";
import { fetchJobAll, jobAdd, fetchJobSP, fetchProject } from "../Actions/JobAction";
import { fetchTask } from '../Actions/TaskAction';
import { fetchSite } from '../Actions/SiteAction'
import JobManagement from './ViewJobManagement';
import ProjSite from '../Pages/Project/ProjectSite'
import ProjStatus from '../Pages/Project/ProjectStatus'
import { fetchHelper } from "../RequetsHandler/FetchHandler"
import MakeJob from '../DesignChange/Create/MakeJob'
//import AddBoxIcon from '@material-ui/icons/AddBox';
//import UpdateIcon from '@material-ui/icons/Update';
import PageviewIcon from '@material-ui/icons/Pageview';
import Tooltip from '@material-ui/core/Tooltip';


const ProjectList = ({
    projId,
    proj,
    job,
    fetchProj,
    fetchJobAll,
    fetchTask,
    projName,
    projDet,
    siteId,
    statusId,
    stat,
    site,
    errProjName,
    errProjDet,
    errStatusId,
    errSiteId,
    isEd,
    snack,
    snackNeg,
    projAdd,
    fetchSites,
    fetchStatus,
    projpath,
    dialogOpen,
    jobAdd,
    fetchJobSP,
    fetchProject,
    fetchSite,
    projsite,
    fetchProjSite

}) => {

    useEffect(() => {

        jobAdd("DisplayJobs", false)
        fetchProj();
        fetchTask();
        fetchSites();
        fetchStatus();
        fetchSite();
        return () => {

        }
    }, [fetchProj, fetchTask, fetchSites, fetchStatus, jobAdd, fetchSite])

    const useStyles = makeStyles((theme) => ({
        table: {
            align: "center",
        },
        tableCell: {
            border: "1px solid black",
            borderStyle: "paper",
            width: 250,
            fontSize: '8pt',
        },
        tableCellB: {
            background:"#007bff",
            border: "1px solid black",
            borderStyle: "paper",
            width: 250,
            fontSize: '8pt',
        },
        tableHead: {
            fontSize: '8pt',
            paddingLeft: 10,
            borderStyle: "paper",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "left",
            color: theme.palette.text.primary,
        },
    }));


    const classes = useStyles();

    const HandleClose = () => {
        projAdd("dialogOpen", false);
    };

    function projectSuccess(ab) {
        projAdd("Snack", true);
        projAdd("dialogOpen", false)
    }
    function projectFailure() {
        projAdd("SnackNegative", true);
    }

    const addProj = async (ab) => {

        fetchHelper.postData('api/project', ab, projectSuccess, projectFailure);

    }

    const changeValue = (e) => {

        console.log(e.target.name)
        projAdd(e.target.name, e.target.value);

        if (e.target.value === "" | e.target.value === 0) {
            projAdd(`err${e.target.name}`, true)
        } else {
            projAdd(`err${e.target.name}`, false)
        }

    }

    function updateProjectSuccess() {
        projAdd("Snack", true);
        projAdd("isEd", false);
        projAdd("ProjID", 0);
        projAdd("ProjName", "");
        projAdd("ProjDet", "");
        projAdd("SiteID", 0);
        projAdd("StatusID", 0);
        projAdd("dialogOpen", false)
    }
    function updateProjectFailure() {
        projAdd("SnackNegative", true)
    }


    const editProject = async (ab) => {
        fetchHelper.updateData('api/project', ab, updateProjectSuccess, updateProjectFailure);
    }

    const submitVals = () => {
        console.log(isEd)

        if (isEd) {

            if (!statusId) {
                projAdd("errStatusID", true)
                projAdd("SnackNegative", true)
                return;
            }


            var obj = {
                "projectid": projId,
                "projectname": projName,
                "projectdetails": projDet,
                "siteid": siteId,
                "projectstatusid": statusId
            }

            editProject(obj);
        }
        else {

            var valid = true;

            if ((projName.trim() === "")) {
                valid = false;
                projAdd("errProjName", true)
            }

            if (projDet.trim() === "") {
                valid = false;
                projAdd("errProjDet", true)
            }

            if (statusId === 0) {
                valid = false;
                projAdd("errStatusID", true)
            }

            if (siteId === 0) {
                valid = false;
                projAdd("errSiteID", true)
            }

            if (valid) {
                var projects = {
                    projectid: 0,
                    projectname: projName,
                    projectdetails: projDet,
                    siteid: siteId,
                    projectstatusid: statusId,
                    projPath: projpath
                }

                addProj(projects);

            }
            else {

                if ((projName.trim() === "")) {

                    projAdd("errProjName", true)
                    projAdd("SnackNegative", true)
                    return;
                }

                if (projDet.trim() === "") {

                    projAdd("errProjDet", true)
                    projAdd("SnackNegative", true)
                    return;
                }

                if (statusId === 0) {

                    projAdd("errStatusID", true)
                    projAdd("SnackNegative", true)
                    return;
                }

                if (siteId === 0) {

                    projAdd("errSiteID", true)
                    projAdd("SnackNegative", true)
                    return;
                }


            }
        }


    }


    // const handleLink = (row) => {
    //     projAdd("dialogOpen", true);
    //     projAdd("ProjID", row.projectid);
    //     projAdd("ProjName", row.projectname);
    //     projAdd("ProjDet", row.projectdetails);
    //     projAdd("SiteID", row.siteid);
    //     projAdd("StatusID", row.projectstatusid);
    //     projAdd("isEd", true);
    //     fetchSites();
    //     fetchStatus();

    // }
    // const handleOpenJob = (projID) => {
    //     fetchProject();
    //     jobAdd("dialogOpen", true);
    //     jobAdd("JobID", 0);
    //     jobAdd("JobName", "");
    //     jobAdd("StartTime", "");
    //     jobAdd("EndTime", "");
    //     jobAdd("Vehicle", 0);
    //     jobAdd("Delays", "");
    //     jobAdd("Risk", "");
    //     jobAdd("Weather", "");
    //     jobAdd("ProjectID", projID);
    //     jobAdd("CustomerID", 0);
    //     jobAdd("JobTypeID", 0);
    //     jobAdd("TeamAssignedID", 0);
    // };


    // const handleOpen = () => {

    //     projAdd("dialogOpen", true);
    //     fetchJobAll();
    //     projAdd("ProjID", 0);
    //     projAdd("ProjName", "");
    //     projAdd("ProjDet", "");
    //     projAdd("SiteID", 0);
    //     projAdd("StatusID", 0);
    // };

    const handleViewJob = (a) => {
        jobAdd("tasks", [])
        jobAdd("DisplayJobs", true);
        fetchJobSP(a)

        //const arr = [projsite]


        projsite.forEach(function (item, i) {
            if (item.projectid === a) {
                projsite.splice(i, 1);
                projsite.unshift(item);
            }
        });
    }

    function Row(props) {
        const { row, dId } = props;
        const [open, setOpen] = React.useState(false);

        const handleClick = () => {
            setOpen(!open);
        };

        return (
            <React.Fragment>

                <TableRow >
                    <TableCell onClick={() => handleClick()} align="center" component="th" scope="row" className={open === false ? classes.tableCell : classes.tableCellB}>{row.projectname}
                        <IconButton aria-label="expand row" size="small" onClick={() => handleClick()}>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </TableCell>
                    {/* <TableCell >
                        <IconButton aria-label="expand row" size="small" onClick={() => handleClick()}>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </TableCell> */}
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} className={classes.tableCell}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div" align="center">
                                    Project Details
                                    {/* <Tooltip title="Update Project"><UpdateIcon color="primary" onClick={() => { handleLink(row) }} /></Tooltip>
                                    <Tooltip title="Add Job"><AddBoxIcon color="primary" onClick={() => { handleOpenJob(row.projectid) }} /></Tooltip> */}
                                    <Tooltip title="View Job"><PageviewIcon color="primary" onClick={() => { handleViewJob(row.projectid) }} /></Tooltip>
                                </Typography>

                                <Table size="small" aria-label="jobs">


                                    <TableBody>
                                        {projsite.filter(a => a.projectid === dId).map((licRow) => (
                                            <Paper className={classes.paper}>
                                                <TableRow key={licRow.projectid}>
                                                    <Grid item >
                                                        <b>Project:</b> {row.projectname}

                                                    </Grid>
                                                    <Grid item >
                                                        <b>Details:</b> {row.projectdetails}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Site:</b> {row.sitename}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Site Ref:</b> {row.siteref}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Status:</b> {row.status}
                                                    </Grid>

                                                </TableRow>
                                            </Paper>
                                        ))}
                                    </TableBody>

                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>



            </React.Fragment>
        );
    }

    return (
        <Grid container spacing={2} >
            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => { projAdd("Snack", false) }}
                message="Project has been Added / Updated"
                action={
                    <React.Fragment>

                    </React.Fragment>
                } />

            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={snackNeg}

                onClose={() => { projAdd("SnackNegative", false) }}
                autoHideDuration={6000}
                message="The Project could not be Added / Updated"
                action={
                    <React.Fragment>
                    </React.Fragment>
                } />
            <Grid item xs={4}>
                <TableContainer >
                    <Table >
                        <TableHead className={classes.tableHead} >
                            <TableRow>
                                <TableCell align="center" className={classes.tableCell}><Box fontSize={15} fontWeight="fontWeightBold">Project</Box></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {projsite.map((row) => (
                                <Row key={row.projectid} row={row} dId={row.projectid} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Grid item xs={8}>
                <JobManagement></JobManagement>
            </Grid>



            <Dialog name="DialogOpen" open={dialogOpen} onClose={HandleClose}>
                <DialogTitle>Project</DialogTitle>
                <DialogContent>
                    <Grid item xs={12} container direction="column" spacing={2}>

                        <Grid item>

                            <TextField name="ProjName"
                                label="Project Name"
                                variant="standard"
                                fullWidth={true}
                                placeholder="QN-PC-JobDesc"
                                onChange={changeValue}
                                value={projName}
                                error={errProjName}
                                helperText={errProjName ? "Please enter the Project Name " : ''}
                                disabled={isEd}
                            />

                        </Grid>

                        <Grid item>

                            <TextField
                                helperText={errProjDet ? "Please enter the Project Description " : ''}
                                name="ProjDet"
                                label="Project Details"
                                variant="standard"
                                fullWidth={true}
                                value={projDet}
                                placeholder="Project Details"
                                onChange={changeValue}
                                error={errProjDet}
                                disabled={isEd}
                            />

                        </Grid>

                        <Grid item >
                            <InputLabel error={errSiteId} >Select Site</InputLabel>

                            <ProjSite cList={site} onCh={changeValue} disabled={isEd}></ProjSite>
                        </Grid>

                        <Grid item >
                            <InputLabel error={errStatusId}>Select Status</InputLabel>

                            <ProjStatus cList={stat} onCh={changeValue}></ProjStatus>
                        </Grid>

                    </Grid>

                </DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={HandleClose}>
                        Cancel
                    </Button>

                    <Button
                        onClick={submitVals}
                        color="primary"
                        variant="contained"
                        style={{ textTransform: "none" }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <MakeJob />
        </Grid>






    )
}

const mapStateToProps = state => ({
    proj: state.projRed.Project,
    job: state.jobRed.job,
    task: state.taskRed.task,
    projId: state.projRed.ProjID,
    projName: state.projRed.ProjName,
    projDet: state.projRed.ProjDet,
    siteId: state.projRed.SiteID,
    statusId: state.projRed.StatusID,
    stat: state.projRed.Status,
    site: state.projRed.Site,
    errProjName: state.projRed.errProjName,
    errProjDet: state.projRed.errProjDet,
    errStatusId: state.projRed.errStatusID,
    errSiteId: state.projRed.errSiteID,
    isEd: state.projRed.isEd,
    snack: state.projRed.Snack,
    snackNeg: state.projRed.SnackNegative,
    projpath: state.projRed.projpath,
    dialogOpen: state.projRed.dialogOpen,
    jobproj: state.jobRed.jobproj,
    DisplayJobs: state.jobRed.DisplayJobs,
    DisplayTasks: state.taskRed.DisplayTasks,
    tasks: state.jobRed.tasks,
    sitelist: state.sitecom.SiteList,
    projsite: state.projRed.projsite
})

export default connect(mapStateToProps, { fetchProj, fetchJobAll, fetchTask, projAdd, fetchSites, fetchStatus, jobAdd, fetchJobSP, fetchSite, fetchProjSite, fetchProject })(ProjectList)



