import { Container, MenuItem, Select } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { jobAdd, fetchCustomer, custAdd } from '../../Actions/JobAction'


const JobTeamSelect = ({usrTeam, cList,eTeamID, onCh, isEd }) => {

    return (
        <Container>
        <Select
            disabled={isEd}
            name =  "TeamAssignedID"
            error = {eTeamID}
            onChange = {onCh}
            fullWidth
            placeholder = "Hello World"
            value = {usrTeam}
        >
            <MenuItem value={0}> <em>None</em></MenuItem>
                {cList.map((item) => (

                <MenuItem value={item.teamid}>{item.teamname}</MenuItem>
                
                ))}

        </Select>



        </Container>
    )
}
const mapStateToProps = state =>({
    usrTeam : state.jobRed.TeamAssignedID , 
    eTeamID  :state.jobRed.errTeamName,
    isEd: state.jobRed.isEd

})

export default connect(mapStateToProps , {custAdd , fetchCustomer, jobAdd})(JobTeamSelect)
