import React, { useEffect } from 'react'
import { Grid, Button, Paper, Dialog, DialogTitle, DialogContent, TextField, FormControl, InputLabel, Snackbar, DialogActions, DialogContentText, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { fetchProj } from "../Actions/ProjectAction";
import { fetchJobAll, jobAdd, fetchCustomer, fetchJobType, fetchProject, fetchReg, fetchTeam, fetchUsers, fetchJobSP, fetchTaskSpec, teamNameAdd, fetchNotes } from "../Actions/JobAction";
import { fetchTask, taskAdd, fetchJob } from '../Actions/TaskAction';
import { fetchHelper } from '../RequetsHandler/FetchHandler';
import JobCust from "../Pages/Job/JobCustomer";
import JobProj from "../Pages/Job/JobProject";
import JobTeam from "../Pages/Job/JobTeam";
import JobType from "../Pages/Job/JobJobType";
import JobFleet from "../Pages/Job/JobVehicle";
import TeamTrans from "../TransferList/TeamTransfer";
import MakeWork from './Create/MakeTask';
import AddBoxIcon from '@material-ui/icons/AddBox';
import UpdateIcon from '@material-ui/icons/Update';
import PageviewIcon from '@material-ui/icons/Pageview';
import Tooltip from '@material-ui/core/Tooltip';
import TaskManagement from './TaskManagement';




const JobLIst = ({
    job,
    proj,
    fetchProj,
    fetchJobAll,
    fetchTask,
    dialogOpen,
    isEd,
    jobAdd,
    jobId,
    jobName,
    startTime,
    endTime,
    vehicle,
    delays,
    risk,
    weather,
    projId,
    custId,
    jobtypeId,
    teamassignedId,
    errjobName,
    errstartTime,
    errendTime,
    errdelays,
    errrisk,
    errweather,
    cust,
    jobtype,
    fleet,
    custName,
    teamName,
    dialogCust,
    dialogTeam,
    custAdd,
    fetchCustomer,
    fetchJobType,
    fetchProject,
    addTeamAssigned,
    fetchTaskSpec,
    teamMem,
    fetchTeam,
    teams,
    teamNameAdd,
    fetchReg,
    errJobType,
    errTeamName,
    errProject,
    errCustomer,
    errVehicle,
    users,
    userId,
    errUserid,
    fetchUsers,
    jobpath,
    taskAdd,
    jobproj,
    dialogOpenjm,
    fetchJobSP,
    snack,
    snackNegative,
    fetchJob,
    fetchNotes,
    DialogNoteView,
    notes,



}) => {

    useEffect(() => {

        fetchProj();
        fetchJobAll();
        fetchTask();
        fetchCustomer();
        fetchJobType();
        fetchProject();
        fetchReg();
        fetchTeam();
        fetchUsers();
        return () => {

        }
    }, [fetchProj, fetchJobAll, fetchTask, fetchCustomer, fetchJobType, fetchProject, fetchReg, fetchTeam, fetchUsers])

    const useStyles = makeStyles((theme) => ({
        table: {
            align: "center",
        },
        tableCell: {
            border: "1px solid black",
            borderStyle: "paper",
            width: 250,
            fontSize: '8pt',
        },
        tableCellB: {
            background: "#007bff",
            border: "1px solid black",
            borderStyle: "paper",
            width: 250,
            fontSize: '8pt',
        },
        tableHead: {
            fontSize: '8pt',
            paddingLeft: 10,
            borderStyle: "paper",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "left",
            color: theme.palette.text.primary,
        },
    }));

    const classes = useStyles();

    const btnStyle = makeStyles((theme) => ({
        root: {
            width: "99%",
            margin: 2,
        },
    }));

    const classBtn = btnStyle();

    const minDate = new Date().toISOString().split("T")[0];

    const changeValueJob = (e) => {
        jobAdd(e.target.name, e.target.value);
    };
    const changeValueCust = (e) => {
        custAdd(e.target.name, e.target.value);
    };

    const changeValueTeam = (e) => {
        console.log(e.target.name)
        console.log(e.target.value)
        teamNameAdd(e.target.name, e.target.value);

    };

    function customerSuccess() {
        jobAdd("DialogCust", false);
        fetchCustomer();
    }
    function customerFailure(e) {
        console.log(e)
    }

    const finaliseCustomer = async (CustomerName) => {

        fetchHelper.postData('api/Customer', CustomerName, customerSuccess, customerFailure);

    };

    const handleCust = () => {
        jobAdd("DialogCust", true);
    };

    const handleCustClose = () => {
        jobAdd("DialogCust", false);
    };

    const HandleCustAdd = () => {
        var customer = {
            customerid: 0,
            CustomerName: custName,
        };
        finaliseCustomer(customer);
    };

    function teamFailure() {

    }

    function TeamAssignedSuccess() {
        fetchTeam();
        jobAdd("DialogTeam", false);
    }

    function TeamAssignedFailure() {

    }


    const finaliseTeam = async (team, teamArr) => {

        fetchHelper.postData('api/team', team, (res) => {

            res.json().then(data => fetchHelper.postData(`api/TeamAssigned?TeamID=${data}`, teamArr, TeamAssignedSuccess, TeamAssignedFailure))

        }, teamFailure)

    };

    const HandleTeamOkay = (e) => {
        var values = addTeamAssigned.map((item) => item.teammemberid);

        teamNameAdd(e.target.name, teamName);

        var teams = {
            teamid: 0,
            teamname: teamName,
            userid: userId,
        };

        finaliseTeam(teams, values);
    };

    const HandleTeamClose = () => {
        jobAdd("DialogTeam", false);
    };

    const handleTeam = () => {
        jobAdd("DialogTeam", true);
    };


    const HandleClose = () => {
        jobAdd("isEd", false);
        jobAdd("dialogOpen", false);
        jobAdd("dialogOpenjm", false);
        taskAdd("taskOpen", false);
    };

    const handleOpen = (jobId) => {
        taskAdd("taskOpen", true);
        fetchJob();
        taskAdd("TaskID", 0);
        taskAdd("TaskName", "");
        taskAdd("TaskType", "");
        taskAdd("EndTime", "");
        taskAdd("StartTime", "");
        taskAdd("StatusID", 0);
        taskAdd("JobID", jobId);
    };

    const addingJob = async (ab) => {

        fetchHelper.postData("api/job", ab, jobSuccess, jobFailure);

    };


    function jobSuccess() {
        fetchJobAll();
        jobAdd("Snack", true);
        HandleClose();
    }

    function jobFailure() {
        jobAdd("SnackNegative", true);
    }

    const submitValue = () => {

        if (isEd) {
            if (!endTime) {
                jobAdd("errEndTime", true);
                jobAdd("SnackNegative", true);
                return;
            }

            if (!weather) {
                jobAdd("errWeather", true);
                jobAdd("SnackNegative", true);
                return;
            }

            if (!delays) {
                jobAdd("errDelays", true);
                jobAdd("SnackNegative", true);
                return;
            }

            if (!risk) {
                jobAdd("errRisk", true);
                jobAdd("SnackNegative", true);
                return;
            }

            var obj = {
                jobid: jobId,
                starttime: startTime,
                endtime: endTime,
                delays: delays,
                risk: risk,
                weather: weather,
                vehicleid: vehicle,
                projectid: projId,
                customerid: custId,
                jobtypeid: jobtypeId,
                teamid: teamassignedId,
                jobname: jobName,
                jobpath: jobpath
            };

            editJob(obj);
        }
        else {
            var valid = true;

            if ((jobName.trim() === "") || (/[^A-Za-z0-9^<>{}\"|;:.,~!?@#$%^=&*_\\]+/.test(jobName))) {
                valid = false;
                jobAdd("errJobName", true);
                jobAdd("SnackNegative", true);
                return;
            }

            if (startTime.trim() === "") {
                valid = false;
                jobAdd("errStartTime", true);
                jobAdd("SnackNegative", true);
                return;
            }
            if (endTime.trim() === "") {
                valid = false;
                jobAdd("errEndTime", true);
                jobAdd("SnackNegative", true);
                return;
            }
            if (delays.trim() === "") {
                valid = false;

                jobAdd("errDelays", true);
                jobAdd("SnackNegative", true);
                return;
            }

            if (risk.trim() === "") {
                valid = false;

                jobAdd("errRisk", true);
                jobAdd("SnackNegative", true);
                return;
            }
            if (weather.trim() === "") {
                valid = false;

                jobAdd("errWeather", true);
                jobAdd("SnackNegative", true);
                return;
            }

            if (vehicle === 0) {
                valid = false;

                jobAdd("errVehicle", true);
                jobAdd("SnackNegative", true);
                return;
            }

            if (jobtypeId === 0) {
                valid = false;

                jobAdd("errJobType", true);
                jobAdd("SnackNegative", true);
                return;
            }

            if (custId === 0) {
                valid = false;

                jobAdd("errCustomer", true);
                jobAdd("SnackNegative", true);
                return;
            }

            if (teamassignedId === 0) {
                valid = false;

                jobAdd("errTeamName", true);
                jobAdd("SnackNegative", true);
                return;
            }

            if (projId === 0) {
                valid = false;

                jobAdd("errProject", true);
                jobAdd("SnackNegative", true);
                return;
            }

            if (valid) {
                var joba = {
                    jobid: 0,
                    starttime: startTime,
                    endtime: endTime,
                    delays: delays,
                    risk: risk,
                    weather: weather,
                    vehicleid: vehicle,
                    projectid: projId,
                    customerid: custId,
                    jobtypeid: jobtypeId,
                    teamid: teamassignedId,
                    jobname: jobName
                };

                addingJob(joba);
            } else {
                console.log("Problem");
            }
        }
    };

    function updateJobSuccess() {
        fetchJobAll();
        jobAdd("JobID", 0);
        jobAdd("JobName", "");
        jobAdd("StartTime", "");
        jobAdd("EndTime", "");
        jobAdd("Vehicle", 0);
        jobAdd("Delays", "");
        jobAdd("Risk", "");
        jobAdd("Weather", "");
        jobAdd("ProjectID", 0);
        jobAdd("CustomerID", 0);
        jobAdd("JobTypeID", 0);
        jobAdd("TeamAssignedID", 0);
        HandleClose();
        jobAdd("Snack", true);
    }
    function updateJobFailure() {
        jobAdd("SnackNegative", true);
    }


    const editJob = async (ab) => {

        fetchHelper.updateData('api/Job', ab, updateJobSuccess, updateJobFailure)

    };

    const handleLink = (row) => {
        jobAdd("JobID", row.jobid);
        jobAdd("JobName", row.jobname);
        jobAdd("StartTime", row.starttime);
        jobAdd("EndTime", row.endtime);
        jobAdd("Vehicle", row.vehicleid);
        jobAdd("Delays", row.delays);
        jobAdd("Risk", row.risk);
        jobAdd("Weather", row.weather);
        jobAdd("ProjectID", row.projectid);
        jobAdd("CustomerID", row.customerid);
        jobAdd("JobTypeID", row.jobtypeid);
        jobAdd("TeamAssignedID", row.teamid);
        jobAdd("isEd", true);
        jobAdd("dialogOpenjm", true);
        fetchCustomer();
        fetchJobType();
        fetchProject();
        fetchReg();
        fetchTeam();
    };


    const handleNoteClose = () => {
        jobAdd("DialogNoteView", false);
    };


    const handleNoteOpen = (a) => {
        console.log("notes array", notes)
        fetchNotes(a);
        jobAdd("DialogNoteView", true);

    };

    function Row(props) {
        const { row, dId } = props;
        const [open, setOpen] = React.useState(false);

        const handleClick = () => {
            setOpen(!open);
        };

        const ShowTask = (a) => {
            jobAdd("DisplayTasks", true);
            //console.log(notes)
            fetchTaskSpec(a)

            //const arr = [jobproj]


            jobproj.forEach(function (item, i) {
                if (item.jobid === a) {
                    jobproj.splice(i, 1);
                    jobproj.unshift(item);
                }
            });

            //console.log(arr)
        }

        return (
            <React.Fragment>

                <TableRow >
                    <TableCell onClick={() => handleClick()} align="center" component="th" scope="row" className={open === false ? classes.tableCell : classes.tableCellB}>{row.jobname}
                        <IconButton aria-label="expand row" size="small" onClick={() => handleClick()}>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </TableCell>
                    {/* <TableCell >
                        <IconButton aria-label="expand row" size="small" onClick={() => handleClick()}>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </TableCell> */}
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} className={classes.tableCell}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div" align="center">
                                    Job Details
                                    <Tooltip title="Update Job"><UpdateIcon color="primary" onClick={() => { handleLink(row) }} /></Tooltip>
                                    <Tooltip title="Add Task"><AddBoxIcon color="primary" onClick={() => { handleOpen(row.jobid) }} /></Tooltip>
                                    <Tooltip title="View Task"><PageviewIcon color="primary" onClick={() => { ShowTask(row.jobid) }} /></Tooltip>
                                </Typography>

                                <Table size="small" aria-label="jobs">


                                    <TableBody>
                                        {jobproj.filter(a => a.jobid === dId).map((licRow) => (
                                            <Paper className={classes.paper}>
                                                <TableRow key={licRow.jobid}>

                                                    <Grid item >
                                                        <b>Job Name:</b> {row.jobname}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Start Time:</b> {row.starttime}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>End Time:</b> {row.endtime}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Weather:</b> {row.weather}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Delays:</b> {row.delays}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Risks:</b> {row.risk}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Project:</b> {row.projname}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Customer:</b> {row.custname}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Team:</b> {row.teamname}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Registration:</b> {row.registration}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Notes:</b> <Tooltip title="View Notes"><PageviewIcon color="primary" onClick={() => { handleNoteOpen(row.jobid) }} /></Tooltip>
                                                    </Grid>

                                                </TableRow>
                                            </Paper>
                                        ))}
                                    </TableBody>

                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <Grid container spacing={2} >
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => {
                    jobAdd("Snack", false);
                }}
                message="Job has been added"
                action={<React.Fragment></React.Fragment>}
            />

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snackNegative}
                onClose={() => {
                    jobAdd("SnackNegative", false);
                }}
                autoHideDuration={6000}
                message="The Job could not be added"
                action={<React.Fragment></React.Fragment>}
            />
            <Grid item xs={6}>
                <TableContainer >
                    <Table >
                        <TableHead className={classes.tableHead} >
                            <TableRow>
                                <TableCell align="center" className={classes.tableCell}><Box fontSize={15} fontWeight="fontWeightBold">Job</Box></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {jobproj.map((row) => (
                                <Row key={row.jobid} row={row} dId={row.jobid} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Grid item xs={6}>
                    <MakeWork />
                </Grid>


                <Dialog name="DialogOpen" open={dialogOpenjm} onClose={HandleClose}>
                    <DialogTitle>Job</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>


                            <Grid item xs={6}>
                                <TextField
                                    name="JobName"
                                    onChange={changeValueJob}
                                    className={classBtn.root}
                                    error={errjobName}
                                    label="Job Name"
                                    variant="standard"
                                    color="primary"
                                    placeholder="Job Name"
                                    required
                                    helperText={errjobName ? "Please enter Job Name " : ""}
                                    value={jobName}
                                    disabled={isEd}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="StartTime"
                                    type="date"
                                    onChange={changeValueJob}
                                    value={startTime}
                                    fullWidth
                                    error={errstartTime}
                                    inputProps={{ min: minDate }}
                                    label="Start Time"
                                    variant="standard"
                                    color="primary"
                                    required
                                    placeholder="Start Time"
                                    helperText={errstartTime ? "Please select Start Date " : ""}
                                    disabled={isEd}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    name="EndTime"
                                    type="date"
                                    onChange={changeValueJob}
                                    value={endTime}
                                    fullWidth
                                    inputProps={{ min: minDate }}
                                    error={errendTime}
                                    label="End Time"
                                    variant="standard"
                                    color="primary"
                                    placeholder="End Time"
                                    helperText={errendTime ? "Please select End Date " : ""}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    name="Weather"
                                    onChange={changeValueJob}
                                    error={errweather}
                                    label="Weather"
                                    variant="standard"
                                    color="primary"
                                    placeholder="Weather"
                                    helperText={errweather ? "Please enter Weather " : ""}
                                    value={weather}
                                    fullWidth
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    name="Delays"
                                    onChange={changeValueJob}
                                    error={errdelays}
                                    className={classBtn.root}
                                    label="Delays"
                                    variant="standard"
                                    color="primary"
                                    placeholder="Delays"
                                    helperText={errdelays ? "Please enter Delays " : ""}
                                    value={delays}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <TextField
                                    name="Risk"
                                    onChange={changeValueJob}
                                    error={errrisk}
                                    className={classBtn.root}
                                    label="Risks"
                                    variant="standard"
                                    color="primary"
                                    placeholder="Risks"
                                    helperText={errrisk ? "Please enter Risks " : ""}
                                    value={risk}
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel error={errVehicle} required>
                                        Vehicles
                                    </InputLabel>

                                    <JobFleet cList={fleet} onCh={changeValueJob} />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel error={errJobType}>Job Type</InputLabel>

                                    <JobType cList={jobtype} onCh={changeValueJob} />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>

                                <InputLabel error={errTeamName}>Team Name</InputLabel>

                                <JobTeam cList={teams} onCh={changeValueJob} />


                                <Button
                                    onClick={handleTeam}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ textTransform: "none" }}
                                    className="mt-8"
                                >
                                    Add new Team{" "}
                                </Button>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel error={errProject}>Project</InputLabel>

                                    <JobProj cList={proj} onCh={changeValueJob} />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel error={errCustomer}>Client</InputLabel>

                                    <JobCust cList={cust} onCh={changeValueJob} />
                                </FormControl>
                                <Button
                                    onClick={handleCust}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ textTransform: "none" }}
                                    className="mt-2"
                                    disabled={isEd}
                                >
                                    Add new Client{" "}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={HandleClose}>
                            Cancel
                        </Button>

                        <Button
                            onClick={submitValue}
                            color="primary"
                            variant="contained"
                            style={{ textTransform: "none" }}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog name="DialogTeam" open={dialogTeam} onClose={HandleTeamClose}>
                    <DialogTitle>Add Teams</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Team</DialogContentText>

                        <TextField
                            name="TeamName"
                            onChange={changeValueTeam}
                            className={classBtn.root}
                            label="Enter new Team Name"
                            variant="standard"
                            color="primary"
                            placeholder="Enter new Team Name "

                        />

                        <Select

                            name="UserID"
                            error={errUserid}
                            onChange={changeValueJob}
                            fullWidth
                            placeholder="Hello World"
                            value={userId}
                        >
                            <MenuItem value={0}>
                                {" "}
                                <em>Team Leaders</em>
                            </MenuItem>
                            {users.map((item) => (
                                <MenuItem value={item.userId}>{item.username}</MenuItem>
                            ))}
                        </Select>

                        <TeamTrans iList={teamMem} />
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={HandleTeamClose}>
                            Cancel
                        </Button>

                        <Button color="primary" onClick={HandleTeamOkay}>
                            Finalise
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog name="DialogCust" open={dialogCust} onClose={handleCustClose}>
                    <DialogTitle>Add Customer</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Customer</DialogContentText>

                        <TextField
                            name="CustomerName"
                            onChange={changeValueCust}
                            className={classBtn.root}
                            label="Enter new Customer"
                            variant="standard"
                            color="primary"
                            placeholder="Enter new Customer "
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={handleCustClose}>
                            Cancel
                        </Button>

                        <Button color="primary" onClick={HandleCustAdd}>
                            Finalise
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={DialogNoteView} onClose={handleNoteClose} >
                    <DialogTitle>
                        {notes.length > 0 ? notes[0].duration : ""}
                    </DialogTitle>
                    <DialogContent>
                        <b> {notes.length > 0 ? "" : "No Notes Logged"}</b>

                        <Table>
                            <TableBody>
                                {notes.map((licRow) => (
                                    <Paper className={classes.paper}>
                                        <TableRow key={licRow.noteId}>

                                            <Grid item >
                                                <b>Notes:</b> {licRow.note}
                                            </Grid>

                                        </TableRow>
                                    </Paper>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary"
                            variant="outlined"
                            size="small"
                            onClick={handleNoteClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>

            </Grid>

            <Grid item xs={6}>
                <TaskManagement></TaskManagement>
            </Grid>

        </Grid>



    )
}

const mapStateToProps = state => ({
    proj: state.projRed.Project,
    job: state.jobRed.job,
    jobproj: state.jobRed.jobproj,
    task: state.taskRed.task,
    dialogOpen: state.jobRed.dialogOpen,
    isEd: state.jobRed.isEd,
    jobId: state.jobRed.JobID,
    jobName: state.jobRed.JobName,
    startTime: state.jobRed.StartTime,
    endTime: state.jobRed.EndTime,
    vehicle: state.jobRed.Vehicle,
    delays: state.jobRed.Delays,
    risk: state.jobRed.Risk,
    weather: state.jobRed.Weather,
    projId: state.jobRed.ProjectID,
    custId: state.jobRed.CustomerID,
    jobtypeId: state.jobRed.JobTypeID,
    teamassignedId: state.jobRed.TeamAssignedID,
    errjobName: state.jobRed.errJobName,
    errstartTime: state.jobRed.errStartTime,
    errendTime: state.jobRed.errEndTime,
    errvehicle: state.jobRed.errVehicle,
    errdelays: state.jobRed.errDelays,
    errrisk: state.jobRed.errRisk,
    errweather: state.jobRed.errWeather,
    cust: state.jobRed.cust,
    jobtype: state.jobRed.jobType,
    teamassigned: state.jobRed.team,
    custName: state.jobRed.CustomerName,
    dialogCust: state.jobRed.DialogCust,
    snack: state.jobRed.Snack,
    snackNegative: state.jobRed.SnackNegative,
    fleet: state.jobRed.fleet,
    teamMem: state.teamMemRed.TeamMem,
    dialogTeam: state.jobRed.DialogTeam,
    addTeamAssigned: state.jobRed.addTeamAssigned,
    teamName: state.jobRed.TeamName,
    teams: state.jobRed.teams,
    errJobType: state.jobRed.errJobType,
    errTeamName: state.jobRed.errTeamName,
    errProject: state.jobRed.errProject,
    errCustomer: state.jobRed.errCustomer,
    errVehicle: state.jobRed.errVehicle,
    users: state.jobRed.users,
    userId: state.jobRed.UserID,
    errUserid: state.jobRed.errUserID,
    docpath: state.jobRed.docpath,
    jobpath: state.jobRed.jobpath,
    taskOpen: state.taskRed.taskOpen,
    tasks: state.jobRed.tasks,
    DisplayJobs: state.jobRed.DisplayJobs,
    DisplayTasks: state.taskRed.DisplayTasks,
    selectedShown: state.jobRed.selectedShown,
    dialogOpenjm: state.jobRed.dialogOpenjm,
    DialogNoteView: state.jobRed.DialogNoteView,
    notes: state.jobRed.notes

})

export default connect(mapStateToProps, { fetchProj, fetchJobAll, fetchTask, jobAdd, fetchCustomer, fetchJobType, fetchProject, fetchReg, fetchTeam, fetchUsers, taskAdd, fetchJobSP, fetchJob, fetchTaskSpec, teamNameAdd, fetchNotes })(JobLIst)
