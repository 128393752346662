import { Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, Grid, InputLabel, makeStyles, Snackbar, TextField } from '@material-ui/core';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { editValue, fetchCompany, fetchRoles, changeRoleName, fetchUsers } from '../Actions/signUpAction';
import { fecthItems } from '../Actions/MenuActions';
import RoleSelect from '../SignUpSelects/roleSelect';
import CompanySelect from '../SignUpSelects/companySelect';
import TransferList from '../TransferList/TransferList';
import UserDisp from '../SignUpPage/SignUpDisp'
import { fetchHelper } from '../RequetsHandler/FetchHandler';

const SignUpPage = ({ editValue, 
    fetchCompany, 
    changeRoleName, 
    fetchRoles, 
    fecthItems,
    usrName,
    usrSurname,
    usrEmail,
    usrIDNo,
    usrUsername,
    usrPassword,
    usrRePass,
    companyList,
    itemList,
    roleList,
    usrRole,
    usrCompany,
    roleName,
    addedItems,
    companyName,
    eName, 
    eSurname, 
    eUsername, 
    ePassword, 
    eEmail, 
    eID, 
    eRPass, 
    dialogRole, 
    dialogCompany, 
    snack, 
    snackNegative, 
    fetchUsers, 
    SnackUsername,
    isEd,
    suID
}) => {

    useEffect(() => {



        fetchUsers();
        fecthItems();
        fetchCompany();
        fetchRoles();
        return () => {

        }


    }, [fetchCompany, fecthItems, fetchRoles, fetchUsers])

   
    //Handling Dialogs 

    const handleRole = () => {

        editValue("DialogRole", true);

    }

    function companySuccess() {
        editValue("DialogCompany", false);
        fetchCompany();
    }

    const finaliseCompany = async (CompanyName) => {

        fetchHelper.postData('api/Compnay', companyName, companySuccess, () => { })

    }


    const HandleCompanyAdd = () => {
        var comp = {
            companyId: 0,
            CompanyName: companyName

        }
        finaliseCompany(comp);
    }

    const finaliseRole = async (role, itemArr) => {

        fetchHelper.postData('api/Role', role, (res) => {

            res.json().then(a => fetchHelper.postData(`api/RoleItem?RoleId=${a}`, itemArr, () => {
                fetchRoles();
                editValue("DialogRole", false);
            }
                ,
                () => { }))


        }, () => { })

    }


    const HandleRoleOkay = () => {

        var values = addedItems.map((item) => (item.itemID));

        changeRoleName(roleName);

        var rName = {
            roleID: 0,
            roleName: roleName
        }

        finaliseRole(rName, values);
    }

    const HandleRoleClose = () => {

        editValue("DialogRole", false)

    }

    const handleComapny = () => {

        editValue("DialogCompany", true)

    }

    const handleCompanyClose = () => {

        editValue("DialogCompany", false);

    }

    const btnStyle = makeStyles((theme) => ({

        root: {
            width: '99%',
            margin: 2

        }

    }))

    // handling and updating the current state 

    const changeValue = (e) => {
        //console.log(e.target.value)
        //console.log(e.target.name)
        editValue(e.target.name, e.target.value);
    
        if ((e.target.value === "") | (e.target.value === 0)) {
            editValue(`err${e.target.name}`, true);
        } else {
            editValue(`err${e.target.name}`, false);
        }
      };

     
      //update user
      function updateUserSuccess(){
        fetchUsers();
        fetchCompany();
        fetchRoles();
        editValue("Snack", true);
        editValue("isEditing", false);
        editValue("UserId", 0)
        editValue("UsrUsername", "");
        editValue("UsrPassword", "");
        editValue("UsrRePass", "");
        editValue("UsrName", "");
        editValue("UsrSurname", "");
        editValue("UsrEmail", "");
        editValue("UsrIDNo", "");
        editValue("UsrCompany", 0);
        editValue("UsrRole", 0);
      }
      
      function updateUserFailure(){
        editValue("SnackNegative", true)
      }
      
      
      const updateUser = async (objUpdate) => {
      //console.log(objUpdate)
        fetchHelper.updateData('api/User', objUpdate, updateUserSuccess, updateUserFailure)
      
      };


    //userPosting
    const addIngUser = async (ab) => {

        fetchHelper.postData('api/User', ab, (res) => {

            console.log(res.status)
            if (res.status === 200) {
                fetchUsers();
                editValue("Snack", true);
            } else {
                if (res.status === 400) {
                     editValue("SnackUsername", true);
                }

            }

        }, () => { editValue("SnackNegative", true); })

    }

    // handling the user Clicks 

    const submitValue = () => {
if(isEd)
{
    // if (usrEmail.trim() === "" | !(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(usrEmail))) {
    //     editValue("eerEmail", true)
    // }
    if (usrPassword.trim() === "" | usrPassword.length < 4) {
        editValue("eerPassword", true)
        editValue("SnackNegative", true)
    }
    if (usrPassword.trim() !== usrRePass.trim()) {
        editValue("eerPassword", true)
        editValue("SnackNegative", true)
    }
    console.log("suID", suID)
    var objUpdate = {
        userId: suID,
        username: usrUsername,
        password: usrPassword,
        name: usrName,
        surname: usrSurname,
        email: usrEmail,
        idNo: usrIDNo,
        companyID: usrCompany,
        roleID: usrRole,
    }

    updateUser(objUpdate)
    console.log(objUpdate)
}
else{
    var valid = true;

    if (usrUsername.trim() === "" | (!(/^[0-9a-zA-Z]+$/.test(usrUsername))) | usrUsername.length < 4) {
        valid = false;
        editValue("eerUsername", true)
        editValue("SnackNegative", true)
    }

    if (usrName.trim() === "" | (!(/^[a-zA-Z]+$/.test(usrName)))) {
        valid = false;
        editValue("eerName", true)
        editValue("SnackNegative", true)
    }

    if (usrEmail.trim() === "" | !(/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(usrEmail))) {
        valid = false;
        editValue("eerEmail", true)
        editValue("SnackNegative", true)
    }

    if (usrPassword.trim() === "" | usrPassword.length < 4) {
        valid = false;
        editValue("eerPassword", true)
        editValue("SnackNegative", true)
    }

    if (usrIDNo.trim() === "") {
        valid = false;
        editValue("eerIDNumber", true)
        editValue("SnackNegative", true)
    }

    if (usrSurname.trim() === "" | (!(/^[a-zA-Z]+$/.test(usrSurname)))) {
        valid = false;
        editValue("eerSurname", true)
        editValue("SnackNegative", true)
    }

    if (usrPassword.trim() !== usrRePass.trim()) {
        valid = false;
        editValue("eerPassword", true)
        editValue("SnackNegative", true)
    }

    if ((usrIDNo.length !== 13) | (!(/^[0-9]+$/.test(usrIDNo)))) {
        valid = false;
        editValue("eerIDNumber", true)
        editValue("SnackNegative", true)
    }

    if (valid) {
        var person = {
            userId: 0,
            username: usrUsername,
            password: usrPassword,
            name: usrName,
            surname: usrSurname,
            email: usrEmail,
            idNo: usrIDNo,
            companyID: usrCompany,
            roleID: usrRole,
        }

        addIngUser(person).then(a => { console.log("Success") }).catch(err => { console.log(err) });

    } 
  }
}

    const classBtn = btnStyle();

    return (

        <Container maxWidth="xl">
            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => { editValue("Snack", false) }}
                message="User has details been Added/Updated"
                action={
                    <React.Fragment>

                    </React.Fragment>
                } />

            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={snackNegative}

                onClose={() => { editValue("SnackNegative", false) }}
                autoHideDuration={6000}
                message="The user sadly could not be Added/Updated"
                action={
                    <React.Fragment>
                    </React.Fragment>
                } />

            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={SnackUsername}

                onClose={() => { editValue("SnackUsername", false) }}
                autoHideDuration={6000}
                message="The Username is taken"
                action={
                    <React.Fragment>
                    </React.Fragment>
                } />

            <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                    <Typography variant="h4">
                        Users
                        </Typography>
                </Grid> */}

                <Grid item xs={9}>  
                        <UserDisp></UserDisp>
                </Grid>



                <Grid item xs={3} >
                    <Paper style={{ padding: 10 }}>
                        <Grid container

                            justify="center"
                            alignItems="center"
                            direction="row"
                            height="100%"
                            spacing={2}>


                            <Grid
                                className={classBtn.root} item xs={12}>
                                <TextField name="UsrName"
                                    value={usrName}
                                    type="text"
                                    disabled={isEd}
                                    pattern="[a-zA-Z]"
                                    onChange={changeValue}
                                    className={classBtn.root}
                                    error={eName}
                                    label="Name"
                                    variant="standard"
                                    color="primary"
                                    placeholder="Name"
                                    helperText={eName ? "please enter a value " : ''}
                                />
                            </Grid>
                            <Grid
                                className={classBtn.root} item xs={12}>
                                <TextField name="UsrSurname"
                                    disabled={isEd}
                                    value={usrSurname}
                                    onChange={changeValue}
                                    className={classBtn.root}
                                    error={eSurname}
                                    label="Surname"
                                    variant="standard"
                                    color="primary"
                                    placeholder="Surname"
                                    helperText={eSurname ? "please enter a value" : ''}
                                />

                            </Grid>


                            <Grid item xs={12} className={classBtn.root}>

                                <TextField name="UsrEmail"
                                 disabled={isEd}
                                    onChange={changeValue}
                                    value={usrEmail}
                                    error={eEmail}
                                    className={classBtn.root}
                                    label="Email"
                                    variant="standard"
                                    color="primary"
                                    placeholder="Email"
                                    helperText={eEmail ? "please enter a value " : ''}
                                />

                            </Grid>
                            <Grid item xs={12} className={classBtn.root}>

                                <TextField name="UsrIDNo"
                                    type="Number"
                                    value={usrIDNo}
                                    disabled={isEd}
                                    onChange={changeValue}
                                    error={eID}
                                    className={classBtn.root}
                                    label="ID Number"
                                    variant="standard"
                                    color="primary"
                                    placeholder="ID Number"
                                    helperText={eID ? "please enter a value " : ''}
                                />

                            </Grid>




                            <Grid item className={classBtn.root}>
                                <TextField name="UsrUsername"
                                    disabled={isEd}
                                    value={usrUsername}
                                    onChange={changeValue}
                                    error={eUsername}
                                    label="Username"
                                    className={classBtn.root}
                                    variant="standard"
                                    color="primary"
                                    placeholder="Username"
                                    helperText={eUsername ? "please enter a value " : ''} />
                            </Grid>

                            <Grid item className={classBtn.root}>

                                <TextField name="UsrPassword"
                                    onChange={changeValue}
                                    error={ePassword}
                                    //value={usrPassword}
                                    label="Password"
                                    className={classBtn.root}
                                    type="password"
                                    variant="standard"
                                    color="primary"
                                    placeholder="Password"
                                    helperText={ePassword ? "please enter a value " : ''}
                                />

                            </Grid>
                            <Grid item className={classBtn.root}>

                                <TextField name="UsrRePass"
                                    onChange={changeValue}
                                    //value={usrRePass}
                                    error={eRPass}
                                    className={classBtn.root}
                                    label="Re-Enter password"
                                    variant="standard"
                                    type="password"
                                    color="primary"
                                    placeholder="Re-enter password "
                                    helperText={eRPass ? "please enter a value " : ''}
                                />

                            </Grid>





                            <Grid item xs>
                                
                                    <InputLabel >Role</InputLabel>

                                    <RoleSelect rList={roleList} onCh={changeValue} />


                                
                                <Button
                                    onClick={handleRole}
                                    variant="contained" color="primary" size="small" style={{ textTransform: 'none' }}>Add new Role </Button>
                            </Grid>
                            <Grid item xs>
                                
                                    <InputLabel >Company</InputLabel>

                                    <CompanySelect cList={companyList} onCh={changeValue}></CompanySelect>

                                
                                <Button
                                    onClick={handleComapny}
                                    variant="contained" color="primary" size="small" style={{ textTransform: 'none' }}>Add new Company </Button>
                            </Grid>



                            <Grid className={classBtn.root} item xs={12}  >

                                <Button onClick={submitValue} 
                                className={classBtn.root} 
                                variant="contained" 
                                color="primary" 
                                style={{ textTransform: 'none' }}
                                >
                                    Signup
                                
                                </Button>


                            </Grid>

                            <Dialog
                                name="DialogRole" open={dialogRole} onClose={HandleRoleClose} >
                                <DialogTitle >Add New Role</DialogTitle>
                                <DialogContent>

                                    <TextField name="roleAdd"
                                        onChange={changeValue}

                                        className={classBtn.root}
                                        label="Enter New Role Name"
                                        variant="standard"

                                        color="primary"
                                        placeholder="Enter Role Name"

                                    />


                                    <TransferList iList={itemList} />




                                </DialogContent>


                                <DialogActions>
                                    <Button color="primary" onClick={HandleRoleClose}>
                                        Cancel
                                </Button>

                                    <Button color="primary" onClick={HandleRoleOkay}>
                                        Finalise
                                </Button>
                                </DialogActions>


                            </Dialog>


                            <Dialog
                                name="DialogCompany" open={dialogCompany} onClose={handleCompanyClose} >
                                <DialogTitle >Add Company</DialogTitle>
                                <DialogContent>

                                    <DialogContentText>
                                        Company
                                    </DialogContentText>

                                    <TextField name="companyAdd"
                                        onChange={changeValue}

                                        className={classBtn.root}
                                        label="Enter new Company"
                                        variant="standard"

                                        color="primary"
                                        placeholder="Enter new Company "

                                    />




                                </DialogContent>


                                <DialogActions>
                                    <Button color="primary" onClick={handleCompanyClose}>
                                        Cancel
                                </Button>

                                    <Button color="primary" onClick={HandleCompanyAdd}>
                                        Finalise
                                </Button>
                                </DialogActions>


                            </Dialog>

                        </Grid>

                    </Paper>
                </Grid>

            </Grid>

        </Container>

    )
}


const mapStateToProps = (state) => ({

    usrName: state.signUpPage.UsrName,
    usrSurname: state.signUpPage.UsrSurname,
    usrEmail: state.signUpPage.UsrEmail,
    usrIDNo: state.signUpPage.UsrIDNo,
    usrUsername: state.signUpPage.UsrUsername,
    usrPassword: state.signUpPage.UsrPassword,
    usrRePass: state.signUpPage.UsrRePass,
    companyList: state.signUpPage.companyList,
    itemList: state.menu.itemItems,
    usrRole: state.signUpPage.UsrRole,
    usrCompany: state.signUpPage.UsrCompany,
    roleList: state.signUpPage.RoleList,
    addedItems: state.signUpPage.addItemRoleList,
    roleName: state.signUpPage.roleAdd,
    companyName: state.signUpPage.companyAdd,

    eName: state.signUpPage.eerName,
    eSurname: state.signUpPage.eerSurname,
    eUsername: state.signUpPage.eerUsername,
    ePassword: state.signUpPage.eerPassword,
    eEmail: state.signUpPage.eerEmail,
    eID: state.signUpPage.eerIDNumber,
    snack: state.signUpPage.Snack,
    snackNegative: state.signUpPage.SnackNegative,
    dialogRole: state.signUpPage.DialogRole,
    dialogCompany: state.signUpPage.DialogCompany,
    SnackUsername: state.signUpPage.SnackUsername,
    isEd: state.signUpPage.isEditing,

    suID: state.signUpPage.UserId
   

})



export default connect(mapStateToProps, { editValue, changeRoleName, fetchCompany, fetchRoles, fecthItems, fetchUsers })(SignUpPage)
