import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import {vehicelEdit , fetchVehicleType , fecthMake} from  '../../Actions/vehicleAction';
import {fetchHelper} from '../../RequetsHandler/FetchHandler';
const VehicleTypesDialog = ({

    vehicelEdit , 
    vtnOpen,
    vmType,
    fetchVehicleType,
    fecthMake


}) => {
 

const changeHandler = (e) =>{
    vehicelEdit(e.target.name , e.target.value)
}


const HandleClose = () => {

vehicelEdit("vTypeOpen" , false)

} 
function vtSuccess(){
    fetchVehicleType() ;
    fecthMake();
    HandleClose();
}
function vtFailure(){

}

const handleTypeAdd = () => {


if(vmType.trim() !== ""){
 
    // var myHeaders = new Headers();
   
    // myHeaders.append("Content-Type", "application/json");
   
    var raw = {"vehicleTypeID":0,"vehicleTypeName":vmType};
    
    fetchHelper.postData('api/vehicletype' , raw , vtSuccess , vtFailure);
    // var requestOptions = {
    //   method: 'POST',
    //   mode:'cors',
    //   credentials:'include',
    //   referrerPolicy:'strict-origin-when-cross-origin',
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: 'follow'
    // };
    
    // fetch(`${API_ADDR}/api/VehicleType`, requestOptions)
    //   .then(response => response.text())
    //   .then(result => {

    //     fetchVehicleType() ;
    //     fecthMake();
    //     HandleClose();
        

    //   })
    //   .catch(error => console.log('error', error));

}else{



}

}


    return (
        <Dialog name= "DialogRole" open={vtnOpen} onClose={HandleClose} fullWidth={true} maxWidth="sm" >
        <DialogTitle >Add Vehicle type</DialogTitle>
        <DialogContent>
        <DialogContentText>
            Enter vehicle type
        </DialogContentText>


                <TextField placeholder="Vehicle Type" label="Vehicle Type" fullWidth name="vTypeName" onChange={changeHandler}/>

        </DialogContent>

        <DialogActions>
            <Button color="primary" onClick={HandleClose}>
                Cancel
            </Button>

            <Button color="primary" onClick={handleTypeAdd}>
                Finalise
            </Button>
        </DialogActions>
    </Dialog>
    )
}
const mapStateToProps = state => ({

vtnOpen : state.Vehicle.vTypeOpen , 
vmType :state.Vehicle.vTypeName

})


export default connect(mapStateToProps , {vehicelEdit , fetchVehicleType , fecthMake})(VehicleTypesDialog)
 