import {FETCHDRIVERS} from './types'
import {API_ADDR} from '../DatabaseStrings/dbStrings'
import auth from '../Authentication/auth'

export const fetchDrivers = () => async (dispatch) =>{
    
    fetch(`${API_ADDR}/api/Driver`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHDRIVERS , 
            payload: data
        })).catch(err => console.error(err))
}
