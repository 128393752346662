import { API_ADDR } from "../DatabaseStrings/dbStrings";
import base64 from 'react-native-base64'

class Auth {
  constructor() {
    this.authenticated = false;
    this.userInfo = {};
  }

  ValidateUser = async (aUser) => { };

  async login(username, password, cb) {
    var person = { username: username, password: password };

    fetch(`${API_ADDR}/api/Login/authenticate`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",

      body: JSON.stringify(person),
    })
      .then((a) => {
        if (a.status === 200) {
          this.authenticated = true;
          localStorage.setItem("auth", true);
        }
        return a.json();
      })
      .then((data) => {
        this.userInfo = data;

        localStorage.setItem("userInfo", JSON.stringify(data));
        cb();
      });
  }

  logout(cb) {
    this.authenticated = false;
    localStorage.setItem("auth", false);
    cb();
  }

  getUserId() {

    return JSON.parse(localStorage.getItem("userInfo")).userId;

  }
  getRoleID() {

    return JSON.parse(localStorage.getItem("userInfo")).roleID;

  }

  getCompID(){
    return JSON.parse(localStorage.getItem("userInfo")).companyID;
  }

  getToken(){
    return JSON.parse(localStorage.getItem("userInfo")).token;
  }

  isAuthenticated() {
    if (JSON.parse(localStorage.getItem("auth"))) {
      
      const JWToken = JSON.parse(localStorage.getItem("userInfo")).token;
      const utf8 = require('utf8');
    
      function getPayload(JWT){
        return (JWT.split(".")[1])
      }

      const payload = getPayload(JWToken);
      var bytes = base64.decode(payload);
      var text = utf8.decode(bytes);
      const expiration = text;
      const exparr = [expiration.split(":")[1].split(",")[0]];
      let intexparr = parseInt(exparr)
      const now = new Date().getTime()/1000;
      let inow = parseInt(now, 10);
      const fiveMinutes =  60 * 1;

      // console.log("token", intexparr);
      // console.log("now time", inow);
      // console.log("math",intexparr - inow)
      // console.log("5",fiveMinutes);
      if( (intexparr - inow) <= fiveMinutes)
      {
        //alert("JWT Token will expire soon. Logout and Sign in Again!")
        //console.log("JWT has expired or will expire soon");
        return false;
        
      }
      
      return JSON.parse(localStorage.getItem("auth"));
    }

    return false;
  }
}

export default new Auth();
