import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputLabel,
    makeStyles,
    Snackbar,
    TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
    jobAdd,
    custAdd,
    fetchCustomer,
    fetchJob,
    fetchJobType,
    fetchProject,
    fetchTeamAssigned,
    fetchReg,
    fetchTeam,
    teamNameAdd,
    fetchJobAll,
    fetchUsers,
} from "../../Actions/JobAction";
import JobCust from "../../Pages/Job/JobCustomer";
import JobProj from "../../Pages/Job/JobProject";
import JobTeam from "../../Pages/Job/JobTeam";
import JobType from "../../Pages/Job/JobJobType";
import JobFleet from "../../Pages/Job/JobVehicle";
import { fetchTeamMem } from "../../Actions/TeamMemberAction";
import auth from "../../Authentication/auth";
import { fetchHelper } from '../../RequetsHandler/FetchHandler';

const CreateJob = ({
    jobName,
    startTime,
    endTime,
    vehicle,
    delays,
    risk,
    weather,
    projId,
    custId,
    jobtypeId,
    teamassignedId,
    errjobName,
    errstartTime,
    errendTime,
    errdelays,
    errrisk,
    errweather,
    proj,
    cust,
    jobtype,
    fleet,
    custName,
    teamName,
    dialogCust,
    dialogTeam,
    snack,
    snackNegative,
    custAdd,
    jobAdd,
    fetchCustomer,
    fetchJob,
    fetchJobType,
    fetchProject,
    fetchTeamAssigned,
    addTeamAssigned,
    teamMem,
    fetchTeamMem,
    fetchTeam,
    teams,
    teamNameAdd,
    fetchReg,
    errJobType,
    errTeamName,
    errProject,
    errCustomer,
    errVehicle,
    isEd,
    fetchJobAll,
    users,
    userId,
    errUserid,
    fetchUsers,
    dialogOpen,
    projID
}) => {

    const btnStyle = makeStyles((theme) => ({
        root: {
            width: "99%",
            margin: 2,
        },
    }));

    useEffect(() => {
        if (auth.getRoleID() === 1) {
            fetchJobAll();
        } else {
            fetchJob();
        }
        fetchUsers();
        fetchCustomer();
        fetchJobType();
        fetchProject();
        fetchTeamAssigned();
        fetchTeamMem();
        fetchTeam();
        fetchReg();
        return () => { };
    }, [fetchCustomer,
        fetchJob,
        fetchJobType,
        fetchProject,
        fetchTeamAssigned,
        fetchReg,
        fetchTeam,
        fetchJobAll,
        fetchUsers, fetchTeamMem]);


    const HandleClose = () => {
        jobAdd("dialogOpen", false);
        jobAdd("isEd", false);
    };

    

    const changeValueCust = (e) => {
        custAdd(e.target.name, e.target.value);
    };

    // const changeValueTeam = (e) => {
    //     teamNameAdd(e.target.name, e.target.value);
    //     console.log(e.target.name)
    //     console.log(e.target.value)
    // };

    function customerSuccess() {
        jobAdd("DialogCust", false);
        fetchCustomer();
    }
    function customerFailure(e) {
        console.log(e)
    }

    const finaliseCustomer = async (CustomerName) => {
        fetchHelper.postData('api/Customer', CustomerName, customerSuccess, customerFailure);
    };

    const handleCust = () => {
        jobAdd("DialogCust", true);
    };

    const handleCustClose = () => {
        jobAdd("DialogCust", false);
    };

    const HandleCustAdd = () => {
        var customer = {
            customerid: 0,
            CustomerName: custName
        };
        finaliseCustomer(customer);
    };

    // function teamFailure() {

    // }

    // function TeamAssignedSuccess() {
    //     fetchTeam();
    //     jobAdd("DialogTeam", false);
    // }

    // function TeamAssignedFailure() {

    // }


    // const finaliseTeam = async (team, teamArr) => {
    //     console.log(team)

    //     fetchHelper.postData('api/team', team, (res) => {

    //         res.json().then(data => fetchHelper.postData(`api/TeamAssigned?TeamID=${data}`, teamArr, TeamAssignedSuccess, TeamAssignedFailure))

    //     }, teamFailure)

    // };

    // const HandleTeamOkay = (e) => {
    //     var values = addTeamAssigned.map((item) => item.teammemberid);

    //     // teamNameAdd("TeamName", '');
    //     // console.log(teamName)

    //     var teams = {
    //         teamid: 0,
    //         teamname: teamName,
    //         userid: userId,
    //     };

    //     finaliseTeam(teams, values);
    // };

    // const HandleTeamClose = () => {
    //     jobAdd("DialogTeam", false);
    // };

    const handleTeam = () => {
        jobAdd("DialogTeam", true);
    };

    const addingJob = async (ab) => {
        fetchHelper.postData("api/job", ab, jobSuccess, jobFailure);
    };

    function jobSuccess() {
        fetchJobAll();
        jobAdd("Snack", true);
        HandleClose();

    }

    function jobFailure() {
        jobAdd("SnackNegative", true);
    }

    const changeValueJob = (e) => {
        jobAdd(e.target.name, e.target.value);
    };

    const submitValue = () => {

        var valid = true;

        if ((jobName.trim() === "") ) {
            valid = false;
            jobAdd("errJobName", true);
            jobAdd("SnackNegative", true);
            return;
        }

        if (startTime.trim() === "") {
            valid = false;
            jobAdd("errStartTime", true);
            jobAdd("SnackNegative", true);
            return;
        }
        if (endTime.trim() === "") {
            valid = false;
            jobAdd("errEndTime", true);
            jobAdd("SnackNegative", true);
            return;
        }
        if (delays.trim() === "" ) {
            valid = false;

            jobAdd("errDelays", true);
            jobAdd("SnackNegative", true);
            return;
        }

        if (risk.trim() === "" ) {
            valid = false;

            jobAdd("errRisk", true);
            jobAdd("SnackNegative", true);
            return;
        }
        if (weather.trim() === "" ) {
            valid = false;

            jobAdd("errWeather", true);
            jobAdd("SnackNegative", true);
            return;
        }

        if (vehicle === 0) {
            valid = false;

            jobAdd("errVehicle", true);
            jobAdd("SnackNegative", true);
            return;
        }

        if (jobtypeId === 0) {
            valid = false;

            jobAdd("errJobType", true);
            jobAdd("SnackNegative", true);
            return;
        }

        if (custId === 0) {
            valid = false;

            jobAdd("errCustomer", true);
            jobAdd("SnackNegative", true);
            return;
        }

        if (teamassignedId === 0) {
            valid = false;

            jobAdd("errTeamName", true);
            jobAdd("SnackNegative", true);
            return;
        }

        if (projId === 0) {
            valid = false;

            jobAdd("errProject", true);
            jobAdd("SnackNegative", true);
            return;
        }

        if (valid) {
            var joba = {
                jobid: 0,
                starttime: startTime,
                endtime: endTime,
                delays: delays,
                risk: risk,
                weather: weather,
                vehicleid: vehicle,
                projectid: projID,
                customerid: custId,
                jobtypeid: jobtypeId,
                teamid: teamassignedId,
                jobname: jobName
            };
            addingJob(joba);
        } else {
            console.log("Problem");
        }

    };

    const classBtn = btnStyle();
    const minDate = new Date().toISOString().split("T")[0];


    return (
        <Container maxWidth="xl">
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => {
                    jobAdd("Snack", false);
                }}
                message="Job has been added"
                action={<React.Fragment></React.Fragment>}
            />

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snackNegative}
                onClose={() => {
                    jobAdd("SnackNegative", false);
                }}
                autoHideDuration={6000}
                message="The Job could not be added"
                action={<React.Fragment></React.Fragment>}
            />

            <Dialog name="DialogOpen" open={dialogOpen} onClose={HandleClose}>
                <DialogTitle>Job</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <TextField
                                name="JobName"
                                onChange={changeValueJob}
                                className={classBtn.root}
                                error={errjobName}
                                label="Job Name"
                                variant="standard"
                                color="primary"
                                placeholder="Job Name"
                                required
                                helperText={errjobName ? "Please enter Job Name " : ""}
                                value={jobName}
                                disabled={isEd}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="StartTime"
                                type="date"
                                onChange={changeValueJob}
                                value={startTime}
                                fullWidth
                                error={errstartTime}
                                inputProps={{ min: minDate }}
                                label="Start Time"
                                variant="standard"
                                color="primary"
                                required
                                placeholder="Start Time"
                                helperText={errstartTime ? "Please select Start Date " : ""}
                                disabled={isEd}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                name="EndTime"
                                type="date"
                                onChange={changeValueJob}
                                value={endTime}
                                fullWidth
                                inputProps={{ min: minDate }}
                                error={errendTime}
                                label="End Time"
                                variant="standard"
                                color="primary"
                                placeholder="End Time"
                                helperText={errendTime ? "Please select End Date " : ""}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                name="Weather"
                                onChange={changeValueJob}
                                error={errweather}
                                label="Weather"
                                variant="standard"
                                color="primary"
                                placeholder="Weather"
                                helperText={errweather ? "Please enter Weather " : ""}
                                value={weather}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                name="Delays"
                                onChange={changeValueJob}
                                error={errdelays}
                                className={classBtn.root}
                                label="Delays"
                                variant="standard"
                                color="primary"
                                placeholder="Delays"
                                helperText={errdelays ? "Please enter Delays " : ""}
                                value={delays}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <TextField
                                name="Risk"
                                onChange={changeValueJob}
                                error={errrisk}
                                className={classBtn.root}
                                label="Risks"
                                variant="standard"
                                color="primary"
                                placeholder="Risks"
                                helperText={errrisk ? "Please enter Risks " : ""}
                                value={risk}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            
                                <InputLabel error={errVehicle} required>
                                    Vehicles
                                </InputLabel>

                                <JobFleet cList={fleet} onCh={changeValueJob} />
                            
                        </Grid>

                        <Grid item xs={6}>
                           
                                <InputLabel error={errJobType}>Job Type</InputLabel>

                                <JobType cList={jobtype} onCh={changeValueJob} />
                           
                        </Grid>

                        <Grid item xs={6}>
                            
                                <InputLabel error={errTeamName}>Team Name</InputLabel>

                                <JobTeam cList={teams} onCh={changeValueJob} />
                            

                            <Button
                                onClick={handleTeam}
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ textTransform: "none" }}
                                className="mt-2"
                            >
                                Add new Team{" "}
                            </Button>
                        </Grid>

                        <Grid item xs={6}>
                           
                                <InputLabel error={errProject}>Project</InputLabel>

                                <JobProj cList={proj}  onCh={changeValueJob} />
                          
                        </Grid>

                        <Grid item xs={6}>
                          
                                <InputLabel error={errCustomer}>Client</InputLabel>

                                <JobCust cList={cust} onCh={changeValueJob} />
                            
                            <Button
                                onClick={handleCust}
                                variant="contained"
                                color="primary"
                                size="small"
                                style={{ textTransform: "none" }}
                                className="mt-2"
                            >
                                Add new Client{" "}
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={HandleClose}>
                        Cancel
                    </Button>

                    <Button
                        onClick={submitValue}
                        color="primary"
                        variant="contained"
                        style={{ textTransform: "none" }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            {/* <Dialog name="DialogTeam" open={dialogTeam} onClose={HandleTeamClose}>
                <DialogTitle>Add Team</DialogTitle>
                <DialogContent>
                    <DialogContentText>Team</DialogContentText>

                    <TextField
                        name="TeamName"
                        onChange={changeValueTeam}
                        className={classBtn.root}
                        label="Enter new Team Name"
                        variant="standard"
                        color="primary"
                        placeholder="Enter new Team Name "
                    />

                    <Select
                        disabled={isEd}
                        name="UserID"
                        error={errUserid}
                        onChange={changeValueJob}
                        fullWidth
                        placeholder="Hello World"
                        value={userId}
                    >
                        <MenuItem value={0}>
                            {" "}
                            <em>Team Leader</em>
                        </MenuItem>
                        {users.map((item) => (
                            <MenuItem value={item.userId}>{item.username}</MenuItem>
                        ))}
                    </Select>

                    <TeamTrans iList={teamMem} />
                </DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={HandleTeamClose}>
                        Cancel
                    </Button>

                    <Button color="primary" onClick={HandleTeamOkay}>
                        Finalise
                    </Button>
                </DialogActions>
            </Dialog> */}

            <Dialog name="DialogCust" open={dialogCust} onClose={handleCustClose}>
                <DialogTitle>Add Customer</DialogTitle>
                <DialogContent>
                    <DialogContentText>Customer</DialogContentText>

                    <TextField
                        name="CustomerName"
                        onChange={changeValueCust}
                        className={classBtn.root}
                        label="Enter new Customer"
                        variant="standard"
                        color="primary"
                        placeholder="Enter new Customer "
                    />
                </DialogContent>

                <DialogActions>
                    <Button color="primary" onClick={handleCustClose}>
                        Cancel
                    </Button>

                    <Button color="primary" onClick={HandleCustAdd}>
                        Finalise
                    </Button>
                </DialogActions>
            </Dialog>


        </Container>
    )
}

const mapStateToProps = (state) => ({
    jobId: state.jobRed.JobID,
    jobName: state.jobRed.JobName,
    startTime: state.jobRed.StartTime,
    endTime: state.jobRed.EndTime,
    vehicle: state.jobRed.Vehicle,
    delays: state.jobRed.Delays,
    risk: state.jobRed.Risk,
    weather: state.jobRed.Weather,
    projID: state.jobRed.ProjectID,
    custId: state.jobRed.CustomerID,
    jobtypeId: state.jobRed.JobTypeID,
    teamassignedId: state.jobRed.TeamAssignedID,
    errjobName: state.jobRed.errJobName,
    errstartTime: state.jobRed.errStartTime,
    errendTime: state.jobRed.errEndTime,
    errvehicle: state.jobRed.errVehicle,
    errdelays: state.jobRed.errDelays,
    errrisk: state.jobRed.errRisk,
    errweather: state.jobRed.errWeather,
    proj: state.jobRed.proj,
    cust: state.jobRed.cust,
    jobtype: state.jobRed.jobType,
    job: state.jobRed.job,
    teamassigned: state.jobRed.team,
    custName: state.jobRed.CustomerName,
    dialogCust: state.jobRed.DialogCust,
    snack: state.jobRed.Snack,
    snackNegative: state.jobRed.SnackNegative,
    fleet: state.jobRed.fleet,
    teamMem: state.teamMemRed.TeamMem,
    dialogTeam: state.jobRed.DialogTeam,
    addTeamAssigned: state.jobRed.addTeamAssigned,
    teamName: state.jobRed.TeamName,
    teams: state.jobRed.teams,
    errJobType: state.jobRed.errJobType,
    errTeamName: state.jobRed.errTeamName,
    errProject: state.jobRed.errProject,
    errCustomer: state.jobRed.errCustomer,
    errVehicle: state.jobRed.errVehicle,
    isEd: state.jobRed.isEd,
    users: state.jobRed.users,
    userId: state.jobRed.UserID,
    errUserid: state.jobRed.errUserID,
    docpath: state.jobRed.docpath,
    dialogOpen: state.jobRed.dialogOpen,
    jobpath: state.jobRed.jobpath

});

export default connect(mapStateToProps, {
    fetchUsers,
    jobAdd,
    custAdd,
    fetchTeamAssigned,
    fetchCustomer,
    fetchJob,
    fetchJobType,
    fetchProject,
    fetchReg,
    fetchTeamMem,
    fetchTeam,
    teamNameAdd,
    fetchJobAll,
})(CreateJob)
