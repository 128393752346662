import { Button, Container, Grid, InputLabel, Snackbar, TextField } from '@material-ui/core'
import React , {useEffect} from 'react'
import { connect } from 'react-redux';
import {fecthMake , fetchModels, fetchVehicleType , vehicelEdit } from '../../Actions/vehicleAction';
import VehicleMakes from './VehicleMakes';
import VehicleMakesDialog from './VehicleMakesDialog';
import VehicleTypes from './VehicleTypes';
import VehicleTypesDialog from './VehicleTypesDialog';
import { fetchHelper } from '../../RequetsHandler/FetchHandler';


const AddVehicle = ({
    fecthMake ,
    fetchVehicleType,
    modelname ,
    description , 
    year ,
    vehicleType ,
    vehicleMake ,
    vTypeArr ,
    vMakeArr , 
    vehicelEdit,
    fetchModels,
    eModelname ,
    eDescription ,
    eYear,
    snack,
    snackNegative
}) => {

    const cval = (e) =>{
        
        vehicelEdit(e.target.name , e.target.value)

        if(e.target.value ==="" | e.target.value === 0 ){
            vehicelEdit(`err${e.target.name}` , true )
        }else{
            vehicelEdit(`err${e.target.name}` , false )            
        }
    }

    function VehicleAddSuccess(){

        fetchModels();
        vehicelEdit("snack" , true);   
        vehicelEdit("modelname", "");
        vehicelEdit("description", "");     
        vehicelEdit("year", "");
        vehicelEdit("vehicleMake", 0);     
        vehicelEdit("vehicleType", 0);

       
    }
    function VehicleAddFailure(){
        vehicelEdit("snackNegative" , true);
    }

const addModel = async (objAdd) => {
    fetchHelper.postData('api/Models', objAdd, VehicleAddSuccess , VehicleAddFailure)
}

    const handleVehicleAdd =() =>{

        if(modelname !== "" & 
         
         vehicleMake !== 0 & 
         vehicleType !== 0)
         {

        var objAdd = {
            modelID:0,
            modelName:modelname,
           
            makeID:vehicleMake,
            vehicleTypeID:vehicleType
        };

        addModel(objAdd);

        }
        else
        {
            vehicelEdit("errvehicleType" , true);

       if( modelname === "" | (!(/^[a-z A-Z]*$/.test(modelname))))
       {
            vehicelEdit("errmodelname" , true);
            vehicelEdit("snackNegative" , true);
            return;
       }

         if(vehicleMake === 0 ){
            vehicelEdit("errvehicleMake" , true) ; 
         }
         if(vehicleType === 0){
            vehicelEdit("errvehicleType" , true);

         }

        }

    }

    const handleMakeOpen = () => {


        vehicelEdit("vMakeOpen" , true) ; 


    }
    const handleTypes =() => {
       
        vehicelEdit("vTypeOpen" , true) ; 
    }
   

    useEffect(() => {
        
        fecthMake();
        fetchVehicleType();
        // console.log(vTypeArr);
        // console.log(vMakeArr);
        return () => {
        }
    }, [fetchVehicleType,fecthMake])

    

    return (
        <Container style={{padding:32}} maxWidth="xl">          

                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                      }}
                     open={snack}
                     autoHideDuration={6000}
                     onClose={() =>{vehicelEdit("snack",false)}}
                     message="Vehicle Model has been added"
                    action={
                    <React.Fragment>

                    </React.Fragment>
                } />
                 <Snackbar anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
        }}
        open={snackNegative}
                autoHideDuration={6000}
                onClose={() =>{vehicelEdit("snackNegative",false)}}
                message="Vehicle Model could not be added"
                action={
                    <React.Fragment>

                    </React.Fragment>
                } />

            <Grid container spacing={2}>
                
                <Grid item md={12}>    

                <TextField  name="modelname" 
               
                label="Model Name"
                variant="standard"
                fullWidth={true}
                placeholder="Model Name"
                onChange={cval}
                error={eModelname}
                helperText="Please fill in the value"
                />
                               
                </Grid>
                <Grid item md={12}>    
                
                {/* <TextField 
                helperText="Please fill in the value"
                name="description"
                label="Description" 
                variant="standard" 
                fullWidth={true} 
                placeholder="Description"
                onChange={cval}
                error = {eDescription} 
                 />
                                 
                </Grid>
                <Grid item md={12}>
                
                <TextField 
                
                helperText="Please fill in the value"
                name="year" 
                label="Year" 
                variant="standard"
                fullWidth={true} 
                placeholder="Year"
                type="Number"
                onChange={cval}
                error = {eYear}
                
                /> */}
                      
                </Grid>
                <Grid item md={6}>                   
                
                
                    <InputLabel style={{marginLeft:10}} fullWidth={true}>Vehicle type</InputLabel>                    
                        
                        <VehicleTypes onCh={cval}></VehicleTypes>                    

                
                   
                </Grid>
                <Grid item md={6}>                   
               
                   <InputLabel style={{marginLeft:10}} fullWidth={true}>Vehicle Make</InputLabel>

                    <VehicleMakes onCh={cval} arr={vMakeArr}></VehicleMakes>
                                      
                
                     
                </Grid>
                <Grid item md={6}>                   
                                
                    <Button onClick={handleTypes} color="primary" variant="standard" fullWidth={true}>
                    Add Vehicle Type
                        </Button>
               
                </Grid>
                <Grid item md={6}>                   
                       
                    <Button onClick={handleMakeOpen} color="primary" variant="standard" fullWidth={true}>
                        
                        Add make 
                    </Button>
                </Grid>

                <Grid item md={12}>                   
                       
                        <Button    
                        color="primary" variant="contained" fullWidth={true}  onClick = {handleVehicleAdd} >
                              Add Vehicle
                        </Button>
                   </Grid>


            </Grid>

            <VehicleMakesDialog/>
            <VehicleTypesDialog/>


        </Container>
    )
}

const mapStateToProps = state => ({

    modelname : state.Vehicle.modelname ,
    vehicleType  : state.Vehicle.vehicleType,
    vehicleMake : state.Vehicle.vehicleMake ,
    vTypeArr : state.Vehicle.vType ,
    vMakeArr : state.Vehicle.vMake,
    eModelname  : state.Vehicle.errmodelname ,
    evTypeName : state.Vehicle.errvTypeName ,
    evMakeNmae : state.Vehicle.errvMakeName,
    snack  :state.Vehicle.snack ,
    snackNegative : state.Vehicle.snackNegative

})



export default connect( mapStateToProps ,{fetchVehicleType , fecthMake,vehicelEdit ,fetchModels })(AddVehicle)
