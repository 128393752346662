import React, { useEffect } from 'react'
import { Button, Container, Grid, Paper } from '@material-ui/core';
import { DataGrid } from "@material-ui/data-grid";
import { connect } from 'react-redux';
import { fetchUsers } from '../Actions/signUpAction';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { editValue} from '../Actions/signUpAction';
const UserDetails = ({
    UserList,
    fetchUsers,
    editValue,
}) => {


const rows = UserList.map((row)=>{

return {

    id: row.userId,
    username: row.username,
    password: row.password,
    name: row.name,
    surname: row.surname,
    email: row.email,
    idNo: row.idNo,
    company: row.companyName,
    role: row.roleName,
    edit: row,
};
});


const columns = [
    {field: "userId", hide: true},

    {
        field: "username",
        headerName: "Username",
        width: 150,
    },
    {
        field: "password",
        headerName: "Password",
        width: 150,
        hide: true,
    },
    {
        field: "name",
        headerName: "Name",
        width: 150,
    },
    {
        field: "surname",
        headerName: "Surname",
        width: 150,
    },
    {
        field: "email",
        headerName: "Email",
        width: 200,
    },
    {
        field: "idNo",
        headerName: "idNo",
        width: 150,
        hide: true,
    },
    {
        field: "company",
        headerName: "CompanyName",
        width: 150,
    },
    {
        field: "role",
        headerName: "RoleName",
        width: 150,
    },

    {
        field: "edit",
        headerName: "Edit",
        sortable: false,
        filterable: false,
        width: 150,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          return (
            <strong>
              <Button
                onClick={() => {
                  handleLink(params.value);
                }}
                variant="contained"
                color="primary"
                size="small"
                style={{ margin: 16 }}
              >
                Edit
              </Button>
            </strong>
          );
        },
      },
];

    useEffect(() => {
        
        fetchUsers()
        return () => {

        }
    }, [fetchUsers])

    const handleLink = (row) => {
        console.log(row);
        editValue("UserId",row.userId);
        editValue("UsrUsername", row.username);
        editValue("UsrPassword", row.password);
        editValue("UsrName", row.name);
        editValue("UsrSurname",row.surname);
        editValue("UsrEmail", row.email);
        editValue("UsrIDNo", row.idNo);
        editValue("UsrCompany",row.companyID);
        editValue("UsrRole",row.roleID);
        editValue("isEditing", true);
    };



    return (
        <Container maxWidth="xl">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">
                {" "}
                <Box fontWeight="fontWeightBold">User Records </Box>
              </Typography>
            </Grid>
    
            <Grid item xs={12}>
              <div style={{ display: "flex", height: "100%" }}>
                <Paper elevation={5} />
                <div style={{ flexGrow: 1, height: "80vh" }}>
                  <DataGrid rows={rows} columns={columns} />
                </div>
                <Paper />
              </div>
            </Grid>
          </Grid>
        </Container>
      );
}

const mapStateToProps = state => ({
    UserList: state.signUpPage.UserList,
})

export default connect(mapStateToProps, { fetchUsers,  editValue })(UserDetails)
