import {ADDTEAMMEM, FETCHTEAMMEMBERASSIGNED} from '../Actions/types';

const initialState = {
    
    TeamMemberAssigned: [],
    teamassignedid: 0,
    teamid: 0,
    teammemberid: 0,
    teamname: "",
    teammembername: "",
    snack: false,
    snacknegative: false,
}

export default function red(state = initialState, action){
    switch(action.type)
    {
        case ADDTEAMMEM:
            return{
            ...state,
            [action.objName] : action.payload
            }
        
            case FETCHTEAMMEMBERASSIGNED :
                return {
                ...state ,
                TeamMemberAssigned : action.payload
                }
        
        default:
            return state;
    }
}