import React, { useEffect } from 'react'
import { Dialog, DialogActions, DialogTitle, DialogContent, Grid, Paper, Snackbar, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { fetchJobSP, fetchTaskSpec } from "../Actions/JobAction";
import Tooltip from '@material-ui/core/Tooltip';
import { fetchTask, taskAdd, fetchTaskTracker } from '../Actions/TaskAction';
import MakeWork from './Create/MakeTask';
import UpdateIcon from '@material-ui/icons/Update';
import PageviewIcon from '@material-ui/icons/Pageview';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { fetchSubTaskSpec, editSubTask } from '../Actions/SubTaskAction';
import SubTaskType from './SubTaskType';
import SubTaskDialog from './SubTaskDialogView'

const TaskList = ({

    task,
    fetchTask,
    tasks,
    taskAdd,
    snack,
    snackNegative,
    editSubTask,
    fetchSubTaskSpec,
    subtaskAdd,
    subtaskOpen,
    fetchTaskTracker,
    taskTracker,
    trackerdialog
}) => {

    useEffect(() => {
        fetchTask();
        return () => {

        }
    }, [fetchTask])

    const useStyles = makeStyles((theme) => ({
        table: {
            align: "center",
        },
        tableCell: {
            border: "1px solid black",
            borderStyle: "paper",
            width: 250,
            fontSize: '8pt',
        },
        tableCellB: {
            background:"#007bff",
            border: "1px solid black",
            borderStyle: "paper",
            width: 250,
            fontSize: '8pt',
        },
        tableHead: {
            paddingLeft: 10,
            borderStyle: "paper",

        },
        heading: {

            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "left",
            color: theme.palette.text.primary,
        },
    }));

    const classes = useStyles();


    const handleUpdate = (row) => {
        taskAdd("taskOpen", true);
        taskAdd("isEd", true);
        fetchTask();
        taskAdd("TaskID", row.taskid);
        taskAdd("TaskName", row.taskname);
        taskAdd("TaskType", row.tasktype);
        taskAdd("EndTime", row.endtime);
        taskAdd("StartTime", row.starttime);
        taskAdd("StatusID", row.jobstatusid);
        taskAdd("JobID", row.jobid);

    }


    const openSubTasks = (a) => {
        editSubTask("subtaskOpen", true);
        editSubTask("SubTaskID", 0)
        editSubTask("SubTaskName", "")
        editSubTask("Description", "")
        editSubTask("TaskID", a)
        taskAdd("StatusID", 0)
    }


    const handleViewSubTask = (a) => {
        editSubTask("DisplaySubTasks", true);
        fetchSubTaskSpec(a);

        //const arr = [tasks]


        tasks.forEach(function (item, i) {
            if (item.taskid === a) {
                tasks.splice(i, 1);
                tasks.unshift(item);
            }
        });


    }

    const handleTaskTracker = (a) => {
        fetchTaskTracker(a)
        //console.log(taskTracker)
        taskAdd("trackerdialog", true)
        //console.log(trackerdialog)
    }

    const handleTaskTrackerclose = (a) => {
        taskAdd("trackerdialog", false)

    }


    function Row(props) {
        const { row, dId } = props;
        const [open, setOpen] = React.useState(false);

        const handleClick = () => {
            setOpen(!open);
        };

        return (
            <React.Fragment>

                <TableRow >
                    <TableCell onClick={() => handleClick()} align="center" component="th" scope="row" className={open === false ? classes.tableCell: classes.tableCellB}>{row.taskname}
                        <IconButton aria-label="expand row" size="small" onClick={() => handleClick()}>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </TableCell>
                    {/* <TableCell >
                        <IconButton aria-label="expand row" size="small" onClick={() => handleClick()}>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </TableCell> */}
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} className={classes.tableCell}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div" align="center">
                                    Task Details
                                    <Tooltip title="Update Task"><UpdateIcon color="primary" onClick={() => { handleUpdate(row) }} /></Tooltip>
                                    <Tooltip title="Add Sub-Task"><AddBoxIcon color="primary" onClick={() => { openSubTasks(row.taskid) }} /></Tooltip>
                                    <Tooltip title="View Sub-Task"><PageviewIcon color="primary" onClick={() => { handleViewSubTask(row.taskid) }} /></Tooltip>
                                </Typography>

                                <Table size="small" aria-label="jobs">


                                    <TableBody>
                                        {task.filter(a => a.taskid === dId).map((licRow) => (
                                            <Paper className={classes.paper}>
                                                <TableRow key={licRow.taskid}>

                                                    <Grid item >
                                                        <b>Task:</b> {row.taskname}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Task Type:</b> {row.tasktype}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Start Time:</b> {row.starttime}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>End Time:</b> {row.endtime}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Job:</b> {row.jobname}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Status:</b> {row.status}
                                                    </Grid>
                                                    <Grid>
                                                        <b>Track Task: </b><Tooltip title="Track Task"><PageviewIcon color="primary" onClick={() => { handleTaskTracker(row.taskid) }} /></Tooltip>
                                                    </Grid>

                                                </TableRow>
                                            </Paper>
                                        ))}


                                    </TableBody>



                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <Grid container spacing={2} >
            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => { taskAdd("Snack", false) }}
                message="Task has been added"
                action={
                    <React.Fragment>

                    </React.Fragment>
                } />

            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={snackNegative}

                onClose={() => { taskAdd("SnackNegative", false) }}
                autoHideDuration={6000}
                message="The Task could not be added"
                action={
                    <React.Fragment>
                    </React.Fragment>
                } />

            <Grid item xs={12}>
                <TableContainer >
                    <Table >
                        <TableHead className={classes.tableHead} >
                            <TableRow>
                                <TableCell align="center" className={classes.tableCell}><Box fontSize={15} fontWeight="fontWeightBold">Task</Box></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tasks.map((row) => (
                                <Row key={row.taskid} row={row} dId={row.taskid} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>



                <MakeWork />

            </Grid>

            <Dialog open={trackerdialog} onClose={handleTaskTrackerclose} >
                <DialogTitle>
                    {taskTracker.length > 0 ? taskTracker[0].duration : ""}
                </DialogTitle>
                <DialogContent>
                    <b> {taskTracker.length > 0 ? "" : "No Tasks Logged"}</b>
                    <Table>
                        <TableBody>
                            {taskTracker.map((licRow) => (
                                <Paper className={classes.paper}>
                                    <TableRow key={licRow.durationid}>

                                        <Grid item >
                                            <b>Duration:</b> {licRow.duration}
                                        </Grid>
                                        <Grid item >
                                            <b>Reason:</b> {licRow.reason}
                                        </Grid>

                                    </TableRow>
                                </Paper>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button color="primary"
                        variant="outlined"
                        size="small"
                        onClick={handleTaskTrackerclose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            <SubTaskType />
            <SubTaskDialog />
        </Grid>
    )
}

const mapStateToProps = state => ({

    task: state.taskRed.task,
    tasks: state.jobRed.tasks,
    DisplayTasks: state.jobRed.DisplayTasks,
    selectedShown: state.jobRed.selectedShown,
    taskOpen: state.taskRed.taskOpen,
    isEd: state.taskRed.isEd,
    snack: state.taskRed.Snack,
    snackNegative: state.taskRed.SnackNegative,
    subtask: state.subred.subtask,
    subtaskOpen: state.subred.subtaskOpen,
    DisplaySubTasks: state.subred.DisplaySubTasks,
    subTaskType: state.taskRed.subTaskType,
    taskTracker: state.taskRed.taskTracker,
    trackerdialog: state.taskRed.trackerdialog


})

export default connect(mapStateToProps, { fetchTaskSpec, fetchJobSP, fetchTask, taskAdd, fetchSubTaskSpec, editSubTask, fetchTaskTracker })(TaskList)