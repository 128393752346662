import { FETCHCOMPANYFLEET } from "./types"
import {API_ADDR} from '../DatabaseStrings/dbStrings'
import auth from '../Authentication/auth'

export const fetchCompanyFleet =() =>async (dispatch)=> {

    fetch (`${API_ADDR}/api/companyfleet`,
    {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
            'Accept': 'application/json',
            
    }
    }).then(res => res.json())
    .then(data => dispatch({
        type:FETCHCOMPANYFLEET,
        payload: data
    })).catch(err => console.log(err))
}


