import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@material-ui/core';
import { connect } from 'react-redux';
import { serviceAdd, fetchServices, fetchServiceTypes, fetchCFM } from '../../Actions/ServiceAction';
import {fetchHelper} from '../../RequetsHandler/FetchHandler';
const ServiceType = ({
    serviceAdd , 
    vOpen,
    vServGroup,
    fetchServiceTypes,
    fetchServices,
    fetchCFM
}) => {

    const changeHandler = (e) =>{
        serviceAdd(e.target.name , e.target.value)
    }

    const HandleClose = () => {

        serviceAdd("vServiceOpen" , false)
        
        } 

    function serviceTypeSuccess(){
        fetchCFM();
        fetchServiceTypes();
        fetchServices();
        HandleClose();
    }
    function serviceTypeFailure(){

    }

        const handleServiceTypepAdd = () => {


            if(vServGroup.trim() !== ""){
               
                var raw = {"serviceTypeID":0,"description":vServGroup};

                fetchHelper.postData('api/ServiceType' , raw , serviceTypeSuccess , serviceTypeFailure)
                
            
            }else{
            
           
            
            }
            
            }

    return (
        <Dialog name= "DialogRole" open={vOpen} onClose={HandleClose} fullWidth={true} maxWidth="sm" >
                <DialogTitle >Add Service Type</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Enter Service Type
                </DialogContentText>
        
        
                        <TextField placeholder="Service Type" label="Service Type" fullWidth name="vServiceTypeName" onChange={changeHandler}/>
        
                </DialogContent>
        
                <DialogActions>
                    <Button color="primary" onClick={HandleClose}>
                        Cancel
                    </Button>
        
                    <Button color="primary" onClick={handleServiceTypepAdd}>
                        Finalise
                    </Button>
                </DialogActions>
            </Dialog>
    )
}

const mapStateToProps = state => ({

    vOpen : state.service.vServiceOpen , 
    vServGroup :state.service.vServiceTypeName
    
    })
    
    
export default connect(mapStateToProps , {serviceAdd , fetchCFM , fetchServiceTypes, fetchServices})(ServiceType)
