import { ADDFOLDER, FETCHFOLDER } from "../Actions/types";

const initialState = {
    folderid: 0,
    foldername: '',
    SnackAdd: false,
    SnackUpdate: false,
    SnackDelete: false,
    SnackNegative: false,
    SnackTaken: false,
    folder: [],
    UpdateDialog: false,
    DeleteDialog: false,
    isEd: false,
    errorFolder: false,
    errorjobname: false,
}

export default function folder(state = initialState, action) 
{
    switch (action.type) 
    {
        case ADDFOLDER:
            return{
            ...state,
            [action.objName] : action.payload
            }
        
            case FETCHFOLDER :
                return {
                ...state ,
                folder : action.payload
                }

        default:
        return state;
    }
}