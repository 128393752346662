import { Box, Button, Grid, Typography, Container } from "@material-ui/core";
import React, { useEffect } from "react";
import { fetchCompanyFleet } from "../../Actions/FleetDetailsAction";
import CompanyFleetDetails from "../../CompanyFleetDetails/CompanyFleetDetails";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import {
  entryValue,
  fetchCompany,
} from "../../Actions/CompanyFleetDetailsAction";
import { DataGrid } from "@material-ui/data-grid";

const FleetDetails = ({
  cfDetails,
  fetchCompanyFleet,
  fetchCompany,
  entryValue,
}) => {
  const rows = cfDetails.map((row) => {
    return {
      id: row.companyFleetID,
      fleetNumber: row.fleetNumber,
      description: row.description,
      location: row.location,
      companyname: row.companyname,
      edit: row,
    };
  });
  const columns = [
    { field: "id", hide: true },
    {
      field: "fleetNumber",
      headerName: "Fleet Number",
      width: 300,
    },
    {
      field: "description",
      headerName: "Description",
      width: 300,
    },
    {
      field: "location",
      headerName: "Location",
      width: 300,
    },
    {
      field: "companyname",
      headerName: "Company",
      width: 300,
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <strong>
            <Button
              onClick={() => {
                handleLink(params.value);
              }}
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
            >
              Edit
            </Button>
          </strong>
        );
      },
    },
  ];

  useEffect(() => {
    fetchCompanyFleet();
    return () => {};
  }, [fetchCompanyFleet]);

  const handleLink = (row) => {
    entryValue("usrCompanyFleetID", row.companyFleetID);
    entryValue("usrDescription", row.description);
    entryValue("usrLocation", row.location);
    entryValue("usrFleetNumber", row.fleetNumber);
    entryValue("usrCompanyID", row.companyId);
    entryValue("isEditing", true);
    fetchCompany();
  };

  return (
    <Container>
      <Grid container spacing={2} alignItems="stretch">
        <Grid item xs={12}>
          <Typography variant="h4">
            <Box fontWeight="fontWeightBold"> Company Fleet Details </Box>
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <div style={{ display: "flex", height: "100%" }}>
            <Paper elevation={5} />
            <div style={{ flexGrow: 1, height: "80vh" }}>
            <DataGrid rows={rows} columns={columns} />
            </div>
            <Paper />
          </div>
        </Grid>

        <Grid item xs={3}>
          <Paper style={{ padding: 10 }}>
            <CompanyFleetDetails></CompanyFleetDetails>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  cfDetails: state.companyfleetdetails.companyfleetdetails,
  FleetNumber: state.companyfleetdetails.usrFleetNumber,
  Description: state.companyfleetdetails.usrDescription,
  Location: state.companyfleetdetails.usrLocation,
  CompanyID: state.companyfleetdetails.usrCompanyID,
});

export default connect(mapStateToProps, {
  fetchCompanyFleet,
  entryValue,
  fetchCompany,
})(FleetDetails);
