import { FETCHJOBUSER, ADDJOB, FETCHJOB, FETCHPROJECT, FETCHSELECTEDJOB, FETCHCUSTOMER, FETCHJOBTYPE, FETCHTEAMASSIGNED, ADDCUSTOMER, FETCHREG, ADDTEAM, ADDTEAMASSIGNED, FETCHTEAM, FETCHUSERS, FETCHTASKSPEC, FETCHJOBSP, FETCHNOTES, FETCHJT } from '../Actions/types';
import { API_ADDR } from '../DatabaseStrings/dbStrings';
import auth from '../Authentication/auth'

export const jobAdd = (objName, value) => dispatch => {
    dispatch({
        type: ADDJOB,
        payload: value,
        objName: objName
    });
}

export const custAdd = (objName, value) => dispatch => {
    dispatch({
        type: ADDCUSTOMER,
        payload: value,
        objName: objName
    });
}

export const teamNameAdd = (objName, value) => dispatch => {
    dispatch({
        type: ADDTEAM,
        payload: value,
        objName: objName
    });
}

export const teamAssignedAdd = (value) => dispatch => {
    dispatch({
        type: ADDTEAMASSIGNED,
        payload: value
    });
}

export const fetchSelectedJOb = (jobid) => async (dispatch) => {
    fetch(`${API_ADDR}/api/TaskSpecific?JobID=${jobid}`,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res => res.json()).then(data => {
            dispatch({
                type: FETCHSELECTEDJOB,
                payload: data
            })

        }).catch(err => console.log(err));
}


export const fetchJob = (cb) => async (dispatch) => {
    fetch(`${API_ADDR}/api/Job?UserID=${auth.getUserId()}`,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res =>
            res.json()
        )
        .then(data => {
            var dt = data;
            console.log(dt);
            cb(dt[0].jobid)
            dispatch({
                type: FETCHJOBUSER,
                payload: dt,
            })
        }
        ).catch(err => console.error(err))
}

export const fetchJobAll = () => async (dispatch) => {
    fetch(`${API_ADDR}/api/JobAll`,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res => res.json())
        .then(data => dispatch({
            type: FETCHJOB,
            payload: data
        })).catch(err => console.error(err))
}

export const fetchProject = () => async (dispatch) => {
    fetch(`${API_ADDR}/api/Project`,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res => res.json())
        .then(data => dispatch({
            type: FETCHPROJECT,
            payload: data
        })).catch(err => console.error(err))
}

export const fetchCustomer = () => async (dispatch) => {
    fetch(`${API_ADDR}/api/Customer`,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res => res.json())
        .then(data => dispatch({
            type: FETCHCUSTOMER,
            payload: data
        })).catch(err => console.error(err))
}

export const fetchJobType = () => async (dispatch) => {
    fetch(`${API_ADDR}/api/JobType`,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res => res.json())
        .then(data => dispatch({
            type: FETCHJOBTYPE,
            payload: data
        })).catch(err => console.error(err))
}

export const fetchTeamAssigned = () => async (dispatch) => {
    fetch(`${API_ADDR}/api/Team`,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res => res.json())
        .then(data => dispatch({
            type: FETCHTEAMASSIGNED,
            payload: data
        })).catch(err => console.error(err))
}

export const fetchReg = () => async (dispatch) => {
    fetch(`${API_ADDR}/api/CompanyFleetModel    `,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res => res.json())
        .then(data => dispatch({
            type: FETCHREG,
            payload: data
        })).catch(err => console.error(err))
}

export const fetchTeam = () => async (dispatch) => {
    fetch(`${API_ADDR}/api/Team    `,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res => res.json())
        .then(data => dispatch({
            type: FETCHTEAM,
            payload: data
        })).catch(err => console.error(err))
}

export const fetchUsers = () => async (dispatch) => {
    fetch(`${API_ADDR}/api/UsersRoleSpec`,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res => res.json())
        .then(data => dispatch({
            type: FETCHUSERS,
            payload: data
        })).catch(err => console.error(err))
}

export const fetchTaskSpec = (jobids) => async (dispatch) => {
    fetch(`${API_ADDR}/api/TaskSpecific?JobID=${jobids}`,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res => res.json())
        .then(data => dispatch({
            type: FETCHTASKSPEC,
            payload: data
        })).catch(err => console.error(err))
}

export const fetchJobSP = (projectid) => async (dispatch) => {
    fetch(`${API_ADDR}/api/JobSpecificProject?ProjectID=${projectid}`,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res => res.json())
        .then(data => dispatch({
            type: FETCHJOBSP,
            payload: data
        })).catch(err => console.error(err))
}

export const fetchNotes = (jobid) => async (dispatch) => {
    console.log("inside fetch request for job notes",jobid)
    fetch(`${API_ADDR}/api/Notes?JobID=${jobid}`,
        {
            mode: 'cors',
            credentials: 'include',
            referrerPolicy: 'strict-origin-when-cross-origin',
            headers: {
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',

            }
        }).then(res => res.json())
        .then(data => dispatch({
            type: FETCHNOTES,
            payload: data
        })).catch(err => console.error(err))
    }

    export const fetchJT = () => async (dispatch) => {
        fetch(`${API_ADDR}/api/JobTime`,
            {
                mode: 'cors',
                credentials: 'include',
                referrerPolicy: 'strict-origin-when-cross-origin',
                headers: {
                    Authorization: `Bearer ${auth.getToken()}`,
                    'Accept': 'application/json',
    
                }
            }).then(res => res.json())
            .then(data => dispatch({
                type: FETCHJT,
                payload: data
            })).catch(err => console.error(err))
    }