import React, { useEffect } from 'react'
import { AddNsb, fetchNsb } from '../Actions/NsbAction'
import { connect } from 'react-redux'
import { Container, Grid, Paper, Card, CardContent, Button, TextField, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Snackbar, Select, MenuItem, FormControl, FormHelperText} from '@material-ui/core';
import { DataGrid, GridToolbarContainer } from "@material-ui/data-grid";
import { makeStyles } from '@material-ui/core/styles';
import { fetchHelper } from '../RequetsHandler/FetchHandler';
import AddBoxIcon from '@material-ui/icons/AddBox';
import UpdateIcon from '@material-ui/icons/Update';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';

import { Addfolder, fetchfolder } from '../Actions/FolderAction'
const NsbFolder = ({

    Nsb,
    NsbID,
    NsbName,
    SnackAdd,
    SnackUpdate,
    SnackDelete,
    SnackNegative,
    fetchNsb,
    AddDialog,
    UpdateDialog,
    DeleteDialog,
    AddNsb,
    isEd,
    //folder stuff
    fld,
    fetchfolder,
    foldid,
    foldername,
    Addfolder,
    AddFolderDialog,
    SnackTaken,
    errorFolder,
    errorjobname,
    

}) => {
 

        const rows = Nsb.sort((a,b) => a.nsbID - b.nsbID).map((row) => {
        return {
            id: row.nsbID,
            NsbName: row.nsbName,
            FolderID: row.folderID,
            FolderName: row.folderName,
            Add: row,
            Update: row,
            Delete: row,
        };
    });

    console.log(Nsb)
    const columns = [
        {
            field: "id",
            headerName: "ID",
            width: 100,
            hide: true,
        },
        {
            field: "NsbName",
            headerName: "Sub-Task Folder",
            width: 200,
        },
        {
            field: "FolderID",
            headerName: "Folder",
            width: 200,
            hide: true,
        },
        {
            field: "FolderName",
            headerName: "Job ",
            width: 200,
        },
        {
            field: "Update",
            headerName: "Update",
            sortable: false,
            filterable: false,
            width: 200,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <strong>
                        <Tooltip title="Update Sub-Task Folder" ><UpdateIcon style={{ marginLeft: 15 }} color="primary" onClick={() => {  handleUpdateLink(params.value)}} /></Tooltip>
                        {/* <Button
                            onClick={() => {
                                handleUpdateLink(params.value);
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 0 }}
                        >
                            Update
                        </Button> */}
                    </strong>
                );
            },
        },
        {
            field: "Delete",
            headerName: "Delete",
            sortable: false,
            filterable: false,
            width: 200,
            disableClickEventBubbling: true,
            renderCell: (params) => {
                return (
                    <strong>
                         <Tooltip title="Delete Sub-Task Folder" ><DeleteIcon style={{ marginLeft: 15 }} color="primary" onClick={() => {  handleDeleteLink(params.value)}} /></Tooltip>
                        {/* <Button
                            onClick={() => {
                                handleDeleteLink(params.value);
                            }}
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: 0 }}
                        >
                            Delete
                        </Button> */}
                    </strong>
                );
            },
        },

    ];
    const btnStyle = makeStyles((theme) => ({

        root: {
            width: '99%',
            margin: 2

        },
        paperHead: {
            margin: '5px',
            marginBottom: '15px',
            fontWeight: "bold"
        },
        table:
        {

            align: "center"
        },

        tableCell: {
            width: 250,
            borderStyle: "none",

        },
        tableHead: {
            paddingLeft: 10,
            borderStyle: 'none'

        },
        paper: {

            margin: '5px'
        }

    }))

    useEffect(() => {

        fetchfolder();
        fetchNsb();
        return () => {

        }
    }, [fetchNsb, fetchfolder])


    const changeNSB = (e) => 
    {
        AddNsb(e.target.name, e.target.value);
    }

    const changeFolder = (e) => {
        //console.log(e.target.value)
        Addfolder("foldername", e.target.value);
        foldername = e.target.value;
        AddNsb("folderid", e.target.value);
        foldid = e.target.value;
        //console.log("onchange",foldid)
      };

    // function addNsbSuccess() {
    //     fetchNsb();
    //     AddNsb("SnackAdd", true);
    //     AddNsb("isEd", false);
    //     AddNsb("nsbid", 0);
    //     AddNsb("nsbname", "");
    //     AddNsb("folderid", 0);
    //     AddNsb("AddDialog", false)
    // }
    // function addNsbFailure() {
    //     AddNsb("SnackNegative", true)
    // }

    const addNewNSB = async (ab) => {
        fetchHelper.postData('api/Nsb', ab, (res) => {
            if (res.status === 200) {
                fetchNsb();
        AddNsb("SnackAdd", true);
        AddNsb("isEd", false);
        AddNsb("nsbid", 0);
        AddNsb("nsbname", "");
        AddNsb("folderid", 0);
        AddNsb("AddDialog", false)
            }
            else if (res.status === 400) 
            {
                        Addfolder("SnackTaken", true)
            }
            else
            {
                AddNsb("SnackNegative", true);
            }
        });
    }
    function updateNsbSuccess() {
        fetchNsb();
        AddNsb("SnackUpdate", true);
        AddNsb("isEd", false);
        AddNsb("nsbid", 0);
        AddNsb("nsbname", "");
        AddNsb("UpdateDialog", false)
    }
    function updateNsbFailure() {
        AddNsb("SnackNegative", true)
    }

    const editNsb = async (ab) => {
        fetchHelper.updateData('api/Nsb', ab, updateNsbSuccess, updateNsbFailure);
    }

    const deleteNsb = async (ab) => {

        fetchHelper.deleteData('api/Nsb', ab, (res) => {
            if (res.status === 200) {
                fetchNsb();
                AddNsb("SnackDelete", true);
                AddNsb("DeleteDialog", false)
                AddNsb("nsbid", 0);
                AddNsb("nsbname", "");
            }
            else
                {
                    if (res.status === 400) 
                    {
                        AddNsb("SnackNegative", true)
                    }
                }
        });

    }

    const addJob = async (ab) => {

        fetchHelper.postData('api/Folder', ab, (res) => {
            if (res.status === 200) {
                fetchfolder();
                Addfolder("folderid", 0);
                Addfolder("foldername", "");
                AddNsb("AddFolderDialog", false);
                Addfolder("SnackTaken", false)
                AddNsb("SnackNegative", false);
                Addfolder("errorFolder",false);
                Addfolder("errorjobname",false);
            }
            else if (res.status === 400) 
            {
                        Addfolder("SnackTaken", true)
            }
            else
            {
                AddNsb("SnackNegative", true);
            }
                
        });

    }
    const submitVals = () => {
        if (isEd) 
        {

            if ((NsbName.trim() === "") || (/[^A-Za-z0-9^<>{}\"|;:.,~!?@#$%^=&*_]+/.test(NsbName))) 
            {
                AddNsb("SnackNegative", true);
                Addfolder("errorjobname",true);
                return;
            }
            
            else  
            {
                var obj = 
                {
                    "nsbid": NsbID,
                    "nsbname": NsbName,
                }
            }
         
                editNsb(obj);
        }
        else 
        {
            var valid = true;

            if ((NsbName.trim() === "") || (/[^A-Za-z0-9^<>{}\"|;:.,~!?@#$%^=&*_]+/.test(NsbName))) 
            {
                valid = false;
                AddNsb("SnackNegative", true);
                Addfolder("errorjobname",true);
                return;
            }
            if  (foldid === 0 || foldid === "") 
            {
                AddNsb("SnackNegative", true);
                Addfolder("errorFolder",true);
                valid = false;
                return;
            }

            if (valid) 
            {
                
                var objAdd = 
                {
                    nsbid: 0,
                    nsbname: NsbName,
                    folderid: foldid,
                }
                //console.log(objAdd)
                addNewNSB(objAdd);
            }
            else 
            {

                if ((NsbName.trim() === "") || (/[^A-Za-z0-9^<>{}\"|;:.,~!?@#$%^=&*_]+/.test(NsbName))) 
                {
                    AddNsb("SnackNegative", true);
                    Addfolder("errorjobname",true);
                    return;
                }

            }
        }
    }

    const DeleteNsB = () => {
        var DNSB = {
            "nsbid": NsbID,
            "nsbname": NsbName,
        }
        deleteNsb(DNSB)
    }

    const AddJob = () => {

        var valid = true;

        if ((foldername.trim() === "") || (/[^A-Za-z0-9^<>{}\"|;:.,~!?@#$%^=&*_]+/.test(foldername))) 
        {
            valid = false;
            AddNsb("SnackNegative", true);
            Addfolder("errorjobname",true);
            return;
        }

        if (valid) 
        {
            
            var AJOB = {
            
                folderid: 0,
                foldername: foldername,
            }
            addJob(AJOB)
        }
        
    }

    const handleAddJob = () => {
        //console.log("row");
        AddNsb("isEd", false);
        AddNsb("AddFolderDialog", true);
        //AddNsb("nsbid", row.nsbID);
    };
    const handleAddLink = () => {
        //console.log("row",row.nsbID);
        AddNsb("isEd", false);
        AddNsb("AddDialog", true);
        //AddNsb("nsbid", row.nsbID);
    };
    const handleUpdateLink = (row) => {
        //console.log("row",row);
        AddNsb("UpdateDialog", true);
        AddNsb("nsbid", row.nsbID);
        AddNsb("nsbname", row.nsbName);
        AddNsb("folderid", row.folderID);
        AddNsb("isEd", true);
    };
    const handleDeleteLink = (row) => {
        //console.log("row",row.nsbID);
        AddNsb("DeleteDialog", true);
        AddNsb("nsbid", row.nsbID);
        AddNsb("nsbname", row.nsbName);
        AddNsb("isEd", true);
    };

    const handleClose = () => {
        AddNsb("AddDialog", false);
        AddNsb("UpdateDialog", false);
        AddNsb("DeleteDialog", false);
        AddNsb("AddFolderDialog", false);
        AddNsb("folderid", 0);
        Addfolder("foldername", "");
        Addfolder("errorFolder",false);
        Addfolder("errorjobname",false);

    };

    function CustomToolbar() {
        return (
          <GridToolbarContainer style={{ marginLeft: 1000}}>
            <Tooltip title="Add Job Name" >
                <Button  
                variant="contained"
                color="#343a40"
                style={{ textTransform: "none" }} 
                onClick={() => { handleAddJob() }}
                >
                    JOB NAME
                </Button>
            </Tooltip>


            <Tooltip title="Add Sub-Task Folder">
                <Button  
                variant="contained"
                color="primary"
                style={{ textTransform: "none" }} 
                onClick={() => { handleAddLink() }} 
                >
                    FOLDER NAME
                </Button>
            </Tooltip>
          </GridToolbarContainer>
        );
      }
    const classes = btnStyle();
    return (
        <Container>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={SnackAdd}
                autoHideDuration={6000}
                onClose={() => {
                    AddNsb("SnackAdd", false);
                }}
                message="Folder Has Been Added"
                action={<React.Fragment></React.Fragment>}
            />

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={SnackUpdate}
                autoHideDuration={6000}
                onClose={() => {
                    AddNsb("SnackUpdate", false);
                }}
                message="Folder Has Been Updated"
                action={<React.Fragment></React.Fragment>}
            />

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={SnackDelete}
                autoHideDuration={6000}
                onClose={() => {
                    AddNsb("SnackDelete", false);
                }}
                message="Folder Has Been Deleted"
                action={<React.Fragment></React.Fragment>}
            />

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={SnackNegative}
                onClose={() => {
                    AddNsb("SnackNegative", false);
                }}
                autoHideDuration={6000}
                message="Folder Name is Invalid"
                action={<React.Fragment></React.Fragment>}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={SnackTaken}
                onClose={() => {
                    Addfolder("SnackTaken", false);
                }}
                autoHideDuration={6000}
                message="Job Name Taken"
                action={<React.Fragment></React.Fragment>}
            />


            <Container maxWidth="lg">
                <Grid container direction="column" spacing={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <h2>Default List Management</h2>
                            <div style={{ display: "flex", height: "100%" }}>
                                <Paper elevation={5} />
                                <div style={{ flexGrow: 1, height: "80vh" }}>
                                    <DataGrid  rows={rows} columns={columns} components={{Toolbar: CustomToolbar,}}/>
                                </div>
                                <Paper />
                            </div>
                        </Grid>
                    </Grid>

                </Grid>
                {/* ADD DIALOG*/}
                <Dialog name="AddDialog" open={AddDialog} onClose={handleClose}>
                    <DialogTitle>Add Folder</DialogTitle>
                    <DialogContent>

                    <InputLabel>Job Name</InputLabel>
                        <Select

                            name="FolderID"
                            fullWidth
                            placeholder="Job"
                            error={errorFolder}
                            onChange = {changeFolder}
                            fullWidth
                            placeholder = "Hello World"
                           

                        >
                            
                            {fld.slice(0).reverse().map((item) => (

                               
                                <MenuItem 
                                value={item.folderID}
                                >
                                    {item.folderName}

                                </MenuItem>

                            ))}

                        </Select>

                    <TextField 
                        name="nsbname"
                        label="Folder Name"
                        variant="standard"
                        error={errorjobname}
                        fullWidth={true}
                        placeholder="Folder Name"
                        onChange={changeNSB}
                        
                    />
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={handleClose}>
                            Cancel
                        </Button>

                        <Button color="primary" onClick={submitVals}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
                
                   {/* UPDATE DIALOG*/}
                <Dialog name="UpdateDialog" open={UpdateDialog} onClose={handleClose}>
                    <DialogTitle>Update Folder</DialogTitle>

                    <DialogContent>
                        
                    <TextField 
                        name="nsbname"
                        label="Folder Name"
                        variant="standard"
                        fullWidth={true}
                        placeholder="Folder Name"
                        onChange={changeNSB}
                        value={NsbName}
                    />
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={handleClose}>
                            Cancel
                        </Button>

                        <Button color="primary" onClick={submitVals}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                   {/* DELETE DIALOG*/}
                <Dialog name="DeleteDialog" open={DeleteDialog} onClose={handleClose}>
                    <DialogTitle>Delete Folder</DialogTitle>
                    <DialogContent>
                        
                    <TextField 
                        name="nsbname"
                        label="Folder Name"
                        variant="standard"
                        fullWidth={true}
                        placeholder="Folder Name"
                        onChange={changeNSB}
                        value={NsbName}
                        disabled={isEd}
                    />
                       
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={handleClose}>
                            Cancel
                        </Button>

                        <Button color="primary" onClick={DeleteNsB}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                    {/*ADD JOB NAME*/}
                <Dialog name="AddFolderDialog" open={AddFolderDialog} onClose={handleClose}>
                    <DialogTitle>Add Default Job</DialogTitle>
                    <DialogContent>
                        
                    <TextField 
                        name="foldername"
                        label="Job Name"
                        variant="standard"
                        error={errorjobname}
                        fullWidth={true}
                        placeholder="Job Name"
                        onChange={changeFolder}
                        disabled={isEd}
                    />
                       
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={handleClose}>
                            Cancel
                        </Button>

                        <Button color="primary" onClick={AddJob}>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

            </Container>
        </Container>
    )
}

const mapStateToProps = state => ({

    Nsb: state.nsb.nsb,
    NsbID: state.nsb.nsbid,
    NsbName: state.nsb.nsbname,
    SnackAdd: state.nsb.SnackAdd,
    SnackUpdate: state.nsb.SnackUpdate,
    SnackDelete: state.nsb.SnackDelete,
    SnackNegative: state.nsb.SnackNegative,
    AddDialog: state.nsb.AddDialog,
    AddFolderDialog: state.nsb.AddFolderDialog,
    UpdateDialog: state.nsb.UpdateDialog,
    DeleteDialog: state.nsb.DeleteDialog,
    isEd: state.nsb.isEd,

    fld: state.folder.folder,
    foldid: state.nsb.folderid,
    foldername: state.folder.foldername,
    SnackTaken: state. folder.SnackTaken,
    errorFolder: state.folder.errorFolder,
    errorjobname: state.folder.errorjobname,
})

export default connect(mapStateToProps, { AddNsb, fetchNsb, fetchfolder, Addfolder })(NsbFolder)