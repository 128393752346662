import {ADDSITE, FETCHSCOMP, FETCHSITE, FETCHSITESPEC} from '../Actions/types';
import {API_ADDR} from '../DatabaseStrings/dbStrings';
import auth from '../Authentication/auth'

export const siteAdd = (objName, value) => dispatch => {
    dispatch({
        type: ADDSITE,
        payload: value,
        objName: objName
    });
}

export const fetchComp = () => async (dispatch) =>{
    
    fetch(`${API_ADDR}/api/Company`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHSCOMP , 
            payload: data
        })).catch(err => console.error(err))
}

export const fetchSite = () => dispatch => {
    
    fetch(`${API_ADDR}/api/Site`
    , {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
        'Accept':'application/json',
        
    }}).then(res => res.json())
.then(data => dispatch(
    {
        type : FETCHSITE,
        payload : data
    })).catch(err => console.log(err))

}

export const fetchSiteSpec = (custid, actives) => dispatch => {

    fetch(`${API_ADDR}/api/SiteSpec?CustID=${custid}&actives=${actives}`, 
    {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
        'Accept':'application/json',
        
    }}).then(res => res.json())
.then(data => dispatch(
    {
        type : FETCHSITESPEC,
        payload : data
    })).catch(err => console.log(err))
    
}