
import { EDIT_VALUE_V, FETCH_MODELS, FETCH_VEHICLE_MAKE , FETCH_VEHICLE_TYPE} from '../Actions/types'; 

const initialState={
modelname : '' ,
description : '' ,
year : '' ,
vehicleType : 0 , 
vehicleMake : 0 ,
vType : [] ,
vMake : [],
vModel : [] , 
vMakeOpen : false ,
vTypeOpen : false ,
vTypeName : '' ,
vMakeName : '' ,
errmodelname : false ,
errdescription  : false ,
erryear  :false ,
errvTypeName  :false ,
errvMakeName : false,
snack : false ,
snackNegative  :false , 
errvehicleType : false ,
errvehicleMake : false

}

export default function red(state = initialState , action){

    switch(action.type){
        case FETCH_VEHICLE_MAKE :
           // console.log(action.payload);
            return{
                ...state ,
                vMake : action.payload
            }

        case FETCH_VEHICLE_TYPE :
           // console.log(action.payload);
            return{
                ...state ,
                vType :action.payload
            }
        
        case EDIT_VALUE_V:
            return{
                ...state ,
                [action.objname] : action.payload
            }
        case FETCH_MODELS :
            return{
                ...state , vModel : action.payload
            } 

        default:
            return state ;

    }

}