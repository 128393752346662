import {EDIT_VALUE ,FETCH_ROLES,COMPANY_DISPLAY , ROLENAMECHANGE , ADDITEMTOROLE , NEWCOMPANYNAME, FETCH_USERS } from '../Actions/types' ; 
import {API_ADDR} from '../DatabaseStrings/dbStrings';
import auth from '../Authentication/auth'

export const editValue = (objName , value) => dispatch =>{
    //console.log({objName , value})    
    

    dispatch({
        type:EDIT_VALUE ,
        payload: value ,
        objName : objName

    }) ; 
}

export const changeRoleName = (value) => dispatch => {
    dispatch({

        type : ROLENAMECHANGE , 
        payload : value

    })
}

export const newCompany = (value) => dispatch => {

    dispatch({

        type:NEWCOMPANYNAME ,
        payload: value

    })
}

export const updateItemRoleAddition = (value) => dispatch =>{

    dispatch({

        type:ADDITEMTOROLE,
        payload:value 

    })
}

export const fetchUsers = () => dispatch => {
    
    fetch(`${API_ADDR}/api/User`
    , {
        mode:'cors',
      credentials:'include',
      referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
        'Accept':'application/json',
        
    }}).then(res => res.json())
.then(data => dispatch(
    {
        type : FETCH_USERS,
        payload : data
    })).catch(err => console.log(err))

}

export const fetchCompany = () => async(dispatch) => {

   await fetch(`${API_ADDR}/api/Company`
   ,
         
      {mode:'cors',
      credentials:'include',
      referrerPolicy:'strict-origin-when-cross-origin',
          headers:{
            Authorization: `Bearer ${auth.getToken()}`,
             'Accept': 'application/json',
            
          }
      }
    ).then(res => res.json())
    .then(data => dispatch({

        type:COMPANY_DISPLAY ,
        payload:data 

    })).catch(err => console.log(err))

}

export const fetchRoles =  () => async (dispatch) => {

      fetch(`${API_ADDR}/api/Role` 
      ,
         
      {  mode:'cors',
      credentials:'include',
      referrerPolicy:'strict-origin-when-cross-origin',
          headers:{
            Authorization: `Bearer ${auth.getToken()}`,
             'Accept': 'application/json',
             
          }
      }
    ).then(res  => res.json())
    .then(data => dispatch({

        type :FETCH_ROLES ,
        payload : data

    })).catch(err => console.log(err))
}