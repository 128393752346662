import React, { useEffect } from 'react'
import { teamAdd, fetchTeamMem } from '../../Actions/TeamMemberAction'
import { connect } from 'react-redux'
import { Container, Grid, Button, Snackbar, TextField, Paper } from '@material-ui/core';
import {fetchHelper} from '../../RequetsHandler/FetchHandler';
import { DataGrid } from "@material-ui/data-grid";

const TeamMembers = ({
    memName,
    memSurname,
    memDesc,
    teamMem,
    errMemName,
    errMemSurname,
    errMemDesc,
    snack,
    snackNeg,
    teamAdd,
    fetchTeamMem

}) => {


    const rows = teamMem.map((row) => {
        return {
            id: row.teammemberid,
            name: row.name,
            surname: row.surname,
            jobdesc: row.jobdesc,

        };
    });

    const columns = [
        { field: "id", hide: true },
        {
            field: "name",
            headerName: "Name",
            width: 150,
        },
        {
            field: "surname",
            headerName: "surname",
            width: 150,
        },
        {
            field: "jobdesc",
            headerName: "Job Description",
            width: 150,
        },
    ];


    //console.log(teamMem);

    useEffect(() => {


        fetchTeamMem();
        return () => {

        }
    }, [fetchTeamMem])


    function teamSuccess(){
        fetchTeamMem();
        teamAdd("Snack", true);
    }

    function teamFailure(){
        teamAdd("SnackNegative", true);
    }

    const addTeamMem = async (ab) => {

    fetchHelper.postData('api/teammembers',ab,teamSuccess , teamFailure) ; 
        
    }

    const submitVals = () => {
        
        var valid = true;

        if (memName.trim() === "" | (!(/^[a-z A-Z]*$/.test(memName)))) {
            valid = false;
            teamAdd("errMemName", true)
        }

        if (memSurname.trim() === "" | (!(/^[a-z A-Z]*$/.test(memSurname)))) {
            valid = false;
            teamAdd("errMemSurname", true)
        }

        if (memDesc.trim() === "" | (!(/^[0-9a-z A-Z]*$/.test(memDesc)))) {
            valid = false;
            teamAdd("errMemDesc", true)
        }

        if (valid) {
            var teammembers = {
                teammemberid: 0,
                name: memName,
                surname: memSurname,
                jobdesc: memDesc
            }

            console.log(teammembers);
            addTeamMem(teammembers);

        }
        else {

            if (memName.trim() === "" | (!(/^[a-z A-Z]*$/.test(memName)))) {

                teamAdd("errMemName", true)
            }

            if (memSurname.trim() === "" | (!(/^[a-z A-Z]*$/.test(memSurname)))) {

                teamAdd("errMemSurname", true)
            }

            if (memDesc.trim() === "" | (!(/^[a-z A-Z]*$/.test(memDesc)))) {

                teamAdd("errMemDesc", true)
            }


        }


    }


    const changeValue = (e) => {

        // console.log(e.target.name)
        teamAdd(e.target.name, e.target.value);

        if (e.target.value === "" | e.target.value === 0) {
            teamAdd(`err${e.target.name}`, true)
        } else {
            teamAdd(`err${e.target.name}`, false)
        }

    }




    return (
        <Container>
            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => { teamAdd("Snack", false) }}
                message="Team Member has been added"
                action={
                    <React.Fragment>

                    </React.Fragment>
                } />

            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={snackNeg}

                onClose={() => { teamAdd("SnackNegative", false) }}
                autoHideDuration={6000}
                message="The Team Member could not be added"
                action={
                    <React.Fragment>
                    </React.Fragment>
                } />

            <Container maxWidth="lg">
                <Grid container direction="column" spacing={2}>
                    <Grid container spacing={3}>


                        <Grid item xs={12} sm={6}>
                            <h2>Team Member</h2>
                            <div style={{ display: "flex", height: "100%" }}>
                                <Paper elevation={5} />
                                <div style={{ flexGrow: 1, height: "80vh" }}>
                                    <DataGrid rows={rows} columns={columns} />
                                </div>
                                <Paper />
                            </div>
                        </Grid>



                        <Grid item xs={6} container direction="column" spacing={2}>
                        <h2>Add Team Member</h2>
                            <Grid item>

                                <TextField name="MemName"
                                    label="Name"
                                    variant="standard"
                                    fullWidth={true}
                                    placeholder="Name"
                                    onChange={changeValue}
                                    error={errMemName}
                                    helperText={errMemName ? "Please enter a Name " : ''}
                                />

                            </Grid>

                            <Grid item>

                                <TextField
                                    helperText={errMemSurname ? "Please enter a Surname " : ''}
                                    name="MemSurname"
                                    label="Surname"
                                    variant="standard"
                                    fullWidth={true}
                                    placeholder="Surname"
                                    onChange={changeValue}
                                    error={errMemSurname}
                                />

                            </Grid>

                            <Grid item>

                                <TextField
                                    helperText={errMemDesc ? "Please enter Job Description " : ''}
                                    name="MemDesc"
                                    label="Job Description"
                                    variant="standard"
                                    fullWidth={true}
                                    placeholder="Job Description"
                                    onChange={changeValue}
                                    error={errMemDesc}
                                />

                            </Grid>

                            <Grid item xl  >
                                <Button onClick={submitVals} fullWidth variant="contained" color="primary" style={{ textTransform: 'none' }}>Submit</Button>
                            </Grid>


                        </Grid>
                    </Grid>
                </Grid>

            </Container>
        </Container>
    )
}

const mapStateToProps = state => ({
    memID: state.teamMemRed.TeamMemberID,
    memName: state.teamMemRed.MemName,
    memSurname: state.teamMemRed.MemSurname,
    memDesc: state.teamMemRed.MemDesc,
    teamMem: state.teamMemRed.TeamMem,
    errMemName: state.teamMemRed.errMemName,
    errMemSurname: state.teamMemRed.errMemSurname,
    errMemDesc: state.teamMemRed.errMemDesc,
    snack: state.teamMemRed.Snack,
    snackNeg: state.teamMemRed.SnackNegative
})

export default connect(mapStateToProps, { teamAdd, fetchTeamMem })(TeamMembers)