import { ADDCOMPANYFLEETDETAILS , COMPANY_DISPLAY} from '../Actions/types';

const initialState ={
    Fleet: '',
    usrFleetNumber: '' ,
    usrDescription: '' ,
    usrLocation: '' ,
    usrCompanyID: 0 ,
    usrCompanyFleetID : 0 , 
    errusrFleetNumber: false ,
    errusrDescription: false ,
    errusrLocation: false ,
    errusrCompanyID  : false , 
    snack: false , 
    snackNegative: false ,
    isEditing : false , 
    SnackFleetNumber: false , 
    companyList: [] ,
}

export default function red(state = initialState, action)
{
    switch(action.type)
    {
        case ADDCOMPANYFLEETDETAILS:
           
            return{
            ...state,
            [action.objName] : action.payload
        }
        case COMPANY_DISPLAY :
            return {
                    ...state ,
                    companyList : action.payload

            }
        default:
            return state;
    }
}