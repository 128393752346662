import { MenuItem, Select, Container } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import {vehicelEdit} from  "../../Actions/vehicleAction"
const VehicleMakes = ({arr  ,onCh, makeChoice ,eVehicleMake, vehicelEdit}) => {
    
    // const c = (e) => {

    //     vehicelEdit(e.target.name , e.target.value)

    // }
    
    return (
       <Container>
            <Select error={eVehicleMake}
         
            name="vehicleMake"  label="Vehicle Make" value={makeChoice} onChange={onCh} variant="standard"fullWidth={true} placeholder="Vehicle Make">
                <MenuItem value={0}><em> None </em></MenuItem>
                {arr.map(x => (
                    <MenuItem key={x.makeID} 
                    value={x.makeID}>
                        {x.makeName}
                    </MenuItem>
                ))}
            </Select>
       </Container>
    )
}
const mapStateToProps = state => ({

makeChoice : state.Vehicle.vehicleMake , 
eVehicleMake : state.Vehicle.errvehicleMake


})


export default connect (mapStateToProps , {vehicelEdit})(VehicleMakes)
