import { ADDCOMPANYFLEETMODEL, FETCHMODEL, FETCHDRIVER, FETCHCOMPANYFLEET, FETCHVEHICLEGROUP, FETCHVEHICLEDETAILS} from './types'
import {API_ADDR} from '../DatabaseStrings/dbStrings';
import auth from '../Authentication/auth'

export const vehicelDetailsAdd = (name , value) => dispatch => {
    console.log({name , value})    

dispatch({
        type : ADDCOMPANYFLEETMODEL ,
        payload : value ,
        objname : name,
    })
}

export const fetchVehicleDetails = () => dispatch => {
    //console.log("in fetch make");
    fetch(`${API_ADDR}/api/CompanyFleetModel`
    , {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
        'Accept':'application/json',
       
    }}).then(res => res.json())
.then(data => dispatch(
    {
        type : FETCHVEHICLEDETAILS,
        payload : data
    })).catch(err => console.log(err))

}

export const fetchModel = () => dispatch => {
    //console.log("in fetch model");
    fetch(`${API_ADDR}/api/Models`
    , 
    {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
        'Accept':'application/json',
        
    }}
    ).then(res => res.json())
.then(data => dispatch(
    {
        type : FETCHMODEL,
        payload : data
    })).catch(err => console.log(err))

}

export const fetchDriver = () => dispatch => {
   // console.log("in fetch driver");
    fetch(`${API_ADDR}/api/Driver`
    , {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
        'Accept':'application/json',
        
    }}).then(res => res.json())
.then(data => dispatch(
    {
        type : FETCHDRIVER,
        payload : data
    })).catch(err => console.log(err))

}

export const fetchCompanyFleet = () => dispatch => {
   // console.log("in fetch make");
    fetch(`${API_ADDR}/api/CompanyFleet`
    , {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
        'Accept':'application/json',
        
    }}).then(res => res.json())
.then(data => dispatch(
    {
        type : FETCHCOMPANYFLEET,
        payload : data
    })).catch(err => console.log(err))

}

export const fecthVehicleGroup = () => dispatch => {
    //console.log("in fetch make");
    fetch(`${API_ADDR}/api/VehicleGroup`
    , {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
        'Accept':'application/json',
        
    }}).then(res => res.json())
.then(data => dispatch(
    {
        type : FETCHVEHICLEGROUP,
        payload : data
    })).catch(err => console.log(err))

}