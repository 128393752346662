import React from 'react'
import { connect } from 'react-redux';


import {editValue} from '../Actions/signUpAction'
import {Container, MenuItem, Select} from '@material-ui/core';

const CompanySelect = ({usrComp , cList , editValue, edi}) => {

    const changeValue = (e) =>{


        editValue(e.target.name , e.target.value)
        
        
        }

    return (
        <Container>
             <Select 
                name="UsrCompany"
                disabled={edi}
                onChange={changeValue} 
                fullWidth
                placeholder="Hello world"
                value={usrComp}
                >
                <MenuItem value={0}> <em>None</em></MenuItem>
                {cList.map((item) => (

                <MenuItem value={item.companyId}>{item.companyName}</MenuItem>

                ))}

            </Select>
        </Container>
    )
}

const mapStateToProps = state =>({
    edi  :state.signUpPage.isEditing , 
    usrComp : state.signUpPage.UsrCompany

})


export default connect(mapStateToProps , {editValue})(CompanySelect)
