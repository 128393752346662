import { MenuItem, Select, Container } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import {serviceAdd} from '../../Actions/ServiceAction'

const cfmDrop = ({arr , CfmChoice , serviceAdd,onCh , eCFMID}) => {
  
    // const c = (e) => {

    //     console.log(e.target.value)
    //     console.log(e.target.name);
    //     console.log(arr);
    //     console.log("fdwsf");
    //     serviceAdd(e.target.name , e.target.value)
    // }  
  
    return (
        <Container>

        <Select 
        error={eCFMID}
        name="comppanyFleetModelId" value={CfmChoice} variant="standard" onChange={onCh} fullWidth={true} placeholder="Company Fleet Model" >
                <MenuItem value={0}><em>None</em></MenuItem>
            {arr.map(x => (

                <MenuItem 
                key={x.companyFleetModelID} 
                value={x.companyFleetModelID}>

                     {x.registration}
                     
                     </MenuItem>

            ))}
        </Select>
        </Container>
    )
}

const mapStateToProps = state => ({

    CfmChoice : state.service.comppanyFleetModelId , 
    arr : state.service.vCFM ,  
    eCFMID :state.service.errcomppanyFleetModelId

})


export default connect(mapStateToProps , {serviceAdd})(cfmDrop)
