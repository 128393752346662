import { Container, MenuItem, Select } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { jobAdd, fetchJobType, custAdd } from '../../Actions/JobAction'


const JobTypeSelect = ({usrJobType, cList,eJobTypeID, onCh, isEd }) => {

    return (
        <Container>
        <Select
        disabled={isEd}
            name =  "JobTypeID"
            error = {eJobTypeID}
            onChange = {onCh}
            fullWidth
            placeholder = "Hello World"
            value = {usrJobType}
        >
            <MenuItem value={0}> <em>None</em></MenuItem>
                {cList.map((item) => (

                <MenuItem value={item.jobtypeid}>{item.typename}</MenuItem>
                
                ))}

        </Select>



        </Container>
    )
}
const mapStateToProps = state =>({
    usrJobType : state.jobRed.JobTypeID , 
    eJobTypeID :state.jobRed.errJobType,
    isEd: state.jobRed.isEd

})

export default connect(mapStateToProps , {custAdd , fetchJobType, jobAdd})(JobTypeSelect)
