import React, { useEffect } from 'react'
import { Grid, Paper, Card, CardContent, Button, TextField, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Snackbar, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { connect } from 'react-redux'
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { fetchProj, projAdd, fetchSites, fetchStatus, fetchProjSite } from "../Actions/ProjectAction";
import { fetchJobAll, jobAdd, fetchJobSP, fetchCustomer } from "../Actions/JobAction";
import { fetchTask } from '../Actions/TaskAction';
import { siteAdd, fetchSite, fetchComp, fetchSiteSpec } from '../Actions/SiteAction'
//import AddBoxIcon from '@material-ui/icons/AddBox';
import PageviewIcon from '@material-ui/icons/Pageview';
import Tooltip from '@material-ui/core/Tooltip';
import ProjMan from './ViewProjectManagement'
import { fetchHelper } from '../RequetsHandler/FetchHandler';
import CompSelect from '../Pages/Job/JobCustomer';


const SiteList = ({

    fetchProj,
    fetchJobAll,
    fetchTask,
    siteAdd,
    projAdd,
    jobAdd,
    fetchSite,
    sitelist,
    fetchProjSite,
    siteName,
    siteRef,
    fetchComp,
    eName,
    eRef,
    eCompId,
    snack,
    snackNegative,
    fetchCustomer,
    cust,
    custName,
    dialogCust,
    custAdd,
    custid,
    fetchSites,
    fetchStatus,
    dialogOpen,
    opens,
    sitespec,
    fetchSiteSpec,
    SnackPath

}) => {

    useEffect(() => {

        jobAdd("DisplayJobs", false)
        fetchProj();
        fetchTask();
        fetchStatus();
        fetchCustomer();
        fetchComp();
        return () => {

        }
    }, [fetchProj, fetchTask, fetchStatus, jobAdd, fetchCustomer, fetchComp, siteAdd])

    const useStyles = makeStyles((theme) => ({
        table: {
            align: "center",
            border: "solid",

        },
        tableCell: {
            border: "1px solid black",
            borderStyle: "paper",
            width: 250,
            fontSize: '8pt',
        },
        tableCellB: {
            background:"#007bff",
            border: "1px solid black",
            borderStyle: "paper",
            width: 250,
            fontSize: '8pt',
        },
        tableHead: {
            fontsize: '8pt',
            paddingLeft: 1,
            borderStyle: "paper",

        },
        heading: {
            fontSize: theme.typography.pxToRem(8),
            fontWeight: theme.typography.fontWeightRegular,
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "left",
            color: theme.palette.text.primary,
        },
        label: {
            fontsize: '15pt',
            color: 'black',
            fontWeight: 'Bold'
        },
    }));


    const classes = useStyles();

    const handleViewProj = (a) => {
        jobAdd("tasks", [])
        jobAdd("jobproj", [])
        fetchProjSite(a);
        fetchProj();

        //const arr = [sitespec]


        sitespec.forEach(function (item, i) {
            if (item.siteid === a) {
                sitespec.splice(i, 1);
                sitespec.unshift(item);
            }
        });

    }

    // const handleproj = (siteid) => {
    //     fetchSites()
    //     projAdd("dialogOpen", true);
    //     projAdd("isEd", false)
    //     projAdd("ProjID", 0);
    //     projAdd("ProjName", "");
    //     projAdd("ProjDet", "");
    //     projAdd("SiteID", siteid);
    //     projAdd("StatusID", 0);

    // }

    const changeValue = (e) => {
        siteAdd(e.target.name, e.target.value);

        if (e.target.value === "" | e.target.value === 0) {
            siteAdd(`err${e.target.name}`, true)
        } else {
            siteAdd(`err${e.target.name}`, false)
        }
    }

    // function siteSuccess() {
    //     fetchComp();
    //     fetchSite();
    //     siteAdd("Snack", true);
    //     siteAdd("SiteName", "");
    //     siteAdd("SiteRef", "");
    //     siteAdd("CompanyID", 0);
    //     jobAdd("CustomerID", 0)
    //     siteAdd("dialogOpen", false);
    // }
    // function siteFailure() {
    //     siteAdd("SnackNegative", true);
    // }

    const addSite = async (objAdd) => {
        fetchHelper.postData("api/Site", objAdd, (res) => {
            console.log(res.status)
            if (res.status === 200) {
                fetchComp();
                fetchSite();
                siteAdd("Snack", true);
                siteAdd("SiteName", "");
                siteAdd("SiteRef", "");
                siteAdd("CompanyID", 0);
                jobAdd("CustomerID", 0)
                siteAdd("dialogOpen", false);
            }
            else {
                if (res.status === 400) {
                    siteAdd("SnackPath", true)
                }
            }
        }, () => { siteAdd("SnackNegative", true) }
        )
    }


    const submitVals = () => {

        var valid = true;

        if ((siteName.trim() === "")) {
            valid = false;
            siteAdd("errSiteName", true)
            siteAdd("SnackNegative", true);
            return;
        }

        if (siteRef.trim() === "") {
            valid = false;
            siteAdd("errSiteRef", true)
            siteAdd("SnackNegative", true);
            return;
        }

        if (valid) {
            var objAdd = {
                siteid: 0,
                sitename: siteName,
                siteref: siteRef,
                custid: custid

            }
            addSite(objAdd);
        }
        else {

            if ((siteName.trim() === "")) {

                siteAdd("errSiteName", true)
                siteAdd("SnackNegative", true);
                return;
            }

            if (siteRef.trim() === "") {

                siteAdd("errSiteRef", true)
                siteAdd("SnackNegative", true);
                return;
            }

            if (custid === 0) {

                siteAdd("errCompID", true)
                siteAdd("SnackNegative", true);
                return;
            }
        }
    }

    function customerSuccess() {
        jobAdd("DialogCust", false);
        fetchCustomer();
    }
    function customerFailure(e) {
        console.log(e)
    }

    const finaliseCustomer = async (CustomerName) => {
        fetchHelper.postData('api/Customer', CustomerName, customerSuccess, customerFailure);

    };

    const handleCust = () => {
        jobAdd("DialogCust", true);
    };

    const handleCustClose = () => {
        jobAdd("DialogCust", false);
    };

    const HandleCustAdd = () => {
        var customer = {
            customerid: 0,
            CustomerName: custName,
        };

        finaliseCustomer(customer);
    };

    const changeValueCust = (e) => {
        custAdd(e.target.name, e.target.value);
    };

    const changeValueCu = (e) => {
        jobAdd(e.target.name, e.target.value);
    };

    const changeValueCussite = (e) => {
        jobAdd(e.target.name, e.target.value);
        fetchSiteSpec(e.target.value)
        jobAdd("tasks", [])
        jobAdd("jobproj", [])
        projAdd("projsite", [])
    };

    // const handleLink = (row) => {

    //     siteAdd("dialogOpen", true);
    //     siteAdd("SiteID", row.siteid);
    //     siteAdd("siteName", row.siteName);
    //     siteAdd("siteRef", row.siteref);
    //     siteAdd("CompanyID", row.companyid);
    //     siteAdd("CustomerID", row.custid)
    //     siteAdd("isEd", true);
    //     fetchComp();
    //     fetchSite();
    // }

    // const handleOpenSite = () => {
    //     console.log("hello")
    //     fetchComp();
    //     fetchSite();
    //     siteAdd("dialogOpen", true);
    //     siteAdd("SiteID", 0);
    //     siteAdd("SiteName", "");
    //     siteAdd("SiteRef", "");
    //     siteAdd("CustomerID", 0);
    // };

    const HandleClose = () => {
        siteAdd("dialogOpen", false);
    };



    function Row(props) {
        const { row, dId } = props;
        const [open, setOpen] = React.useState(false);

        const handleClick = () => {
            setOpen(!open);
        };

        // const handleClicker = () => {
        //     siteAdd("opens", true); 
        // };

        return (
            <React.Fragment>

                <TableRow >
                    <TableCell onClick={() => handleClick()} align="center" component="th" scope="row" className={open === false ? classes.tableCell : classes.tableCellB}  >{row.sitename}
                        <IconButton aria-label="expand row" align="right" size="small" onClick={() => handleClick()}>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </IconButton>
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7} className={classes.tableCell}>
                        <Collapse in={open} keepMounted >
                            <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div" align="center">
                                    Site Details
                                    {/* <Tooltip title="Update Site"><UpdateIcon color="primary" onClick={()=>{ handleLink(row) }} /></Tooltip> */}
                                    {/* <Tooltip title="Add Project"><AddBoxIcon color="primary" onClick={() => { handleproj(row.siteid) }} /></Tooltip> */}
                                    <Tooltip title="View Project"><PageviewIcon color="primary" onClick={() => { handleViewProj(row.siteid) }} /></Tooltip>
                                </Typography>

                                <Table size="small" aria-label="jobs">

                                    <TableBody>
                                        {sitespec.filter(a => a.siteid === dId).map((licRow) => (
                                            <Paper className={classes.paper}>
                                                <TableRow key={licRow.siteid}>
                                                    <Grid item >
                                                        <b>Site:</b> {row.sitename}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Site Reference:</b> {row.siteref}
                                                    </Grid>
                                                    <Grid item >
                                                        <b>Client:</b> {row.custname}
                                                    </Grid>


                                                </TableRow>
                                            </Paper>
                                        ))}
                                    </TableBody>

                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>



            </React.Fragment>
        );
    }

    return (

        <Card>
            <CardContent>
                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                    open={snack}
                    autoHideDuration={6000}
                    onClose={() => { siteAdd("Snack", false) }}
                    message="Site has been added"
                    action={
                        <React.Fragment>

                        </React.Fragment>
                    } />

                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                    open={snackNegative}

                    onClose={() => { siteAdd("SnackNegative", false) }}
                    autoHideDuration={6000}
                    message="The site could not be added"
                    action={
                        <React.Fragment>
                        </React.Fragment>
                    } />

                <Snackbar anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                    open={SnackPath}
                    autoHideDuration={6000}
                    onClose={() => { siteAdd("SnackPath", false) }}
                    message="SitePath has been Taken"
                    action={
                        <React.Fragment>

                        </React.Fragment>
                    } />
                <Grid container spacing={2} >
                    <Grid item xs={12}>
                        <Grid item xs={3}>
                            <InputLabel className={classes.label} error={eCompId}>Select Client</InputLabel>

                            <CompSelect item xs={3} cList={cust} onCh={changeValueCussite} />

                        </Grid>

                    </Grid>

                    <Grid item xs={3}>

                        <TableContainer >
                            <Table >
                                <TableHead className={classes.tableHead} >
                                    <TableRow >
                                        <TableCell align="center" className={classes.tableCell}><Box fontSize={15} fontWeight="fontWeightBold">Site</Box></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sitespec.map((row) => (
                                        <Row key={row.siteid} row={row} dId={row.siteid} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Grid>

                    <Grid item xs={9}>
                        <ProjMan></ProjMan>
                    </Grid>

                </Grid>

                <Dialog name="DialogOpen" open={dialogOpen} onClose={HandleClose}>
                    <DialogTitle>Add New Site</DialogTitle>
                    <DialogContent>
                        <Grid item xl={3} container direction="column" spacing={2}>

                            <Grid item>

                                <TextField name="SiteName"
                                    label="Site Name"
                                    variant="standard"
                                    fullWidth={true}
                                    placeholder="Site Name"
                                    onChange={changeValue}
                                    error={eName}
                                    helperText={eName ? "Please enter Site Name " : ''}
                                />

                            </Grid>

                            <Grid item>

                                <TextField
                                    helperText={eRef ? "Please enter Site Reference " : ''}
                                    name="SiteRef"
                                    label="Site Reference"
                                    variant="standard"
                                    fullWidth={true}
                                    placeholder="Site Reference"
                                    onChange={changeValue}
                                    error={eRef}
                                />

                            </Grid>

                            <Grid item >
                                <InputLabel error={eCompId}>Select Client</InputLabel>

                                <CompSelect cList={cust} onCh={changeValueCu} />

                                <Button
                                    onClick={handleCust}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    style={{ textTransform: "none" }}
                                    className="mt-2"
                                >
                                    Add new Client{" "}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary" onClick={HandleClose}>
                            Cancel
                        </Button>

                        <Button
                            onClick={submitVals}
                            color="primary"
                            variant="contained"
                            style={{ textTransform: "none" }}
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>




                <Dialog name="DialogCust" open={dialogCust} onClose={handleCustClose}>
                    <DialogTitle>Add Client</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Client</DialogContentText>

                        <TextField
                            name="Client Name"
                            onChange={changeValueCust}
                            label="Client Name "
                            variant="standard"
                            color="primary"
                            placeholder="Client Name "
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button color="primary" onClick={handleCustClose}>
                            Cancel
                        </Button>

                        <Button color="primary" onClick={HandleCustAdd}>
                            Finalise
                        </Button>
                    </DialogActions>
                </Dialog>
            </CardContent>
        </Card >
    )
}

const mapStateToProps = state => ({
    proj: state.projRed.Project,
    job: state.jobRed.job,
    task: state.taskRed.task,
    projId: state.projRed.ProjID,
    projName: state.projRed.ProjName,
    projDet: state.projRed.ProjDet,
    siteId: state.projRed.SiteID,
    statusId: state.projRed.StatusID,
    stat: state.projRed.Status,
    site: state.projRed.Site,
    errProjName: state.projRed.errProjName,
    errProjDet: state.projRed.errProjDet,
    errStatusId: state.projRed.errStatusID,
    errSiteId: state.projRed.errSiteID,
    isEd: state.projRed.isEd,
    snack: state.sitecom.Snack,
    snackNegative: state.sitecom.SnackNegative,
    SnackPath: state.sitecom.SnackPath,
    projpath: state.projRed.projpath,
    dialogOpen: state.sitecom.dialogOpen,
    jobproj: state.jobRed.jobproj,
    DisplayJobs: state.jobRed.DisplayJobs,
    DisplayTasks: state.taskRed.DisplayTasks,
    tasks: state.jobRed.tasks,
    sitelist: state.sitecom.SiteList,
    siteName: state.sitecom.SiteName,
    siteRef: state.sitecom.SiteRef,
    compId: state.sitecom.CompanyID,
    company: state.sitecom.Company,
    eName: state.sitecom.errSiteName,
    eRef: state.sitecom.errSiteRef,
    eCompId: state.sitecom.errCompID,
    companyList: state.sitecom.companyList,
    cust: state.jobRed.cust,
    custName: state.jobRed.CustomerName,
    dialogCust: state.jobRed.DialogCust,
    custid: state.jobRed.CustomerID,
    opens: state.sitecom.opens,
    sitespec: state.sitecom.sitespec
})

export default connect(mapStateToProps, { fetchSiteSpec, fetchProj, fetchJobAll, fetchTask, projAdd, fetchSites, fetchStatus, jobAdd, fetchJobSP, fetchSite, fetchProjSite, fetchCustomer, fetchComp, siteAdd })(SiteList)



