import { FETCHJOBUSER, ADDJOB, FETCHPROJECT, FETCHSELECTEDJOB, FETCHCUSTOMER, FETCHJOBTYPE, FETCHTEAMASSIGNED, ADDCUSTOMER, FETCHJOB, FETCHREG, ADDTEAM, ADDTEAMASSIGNED, FETCHTEAM, FETCHUSERS, FETCHTASKSPEC, FETCHJOBSP, FETCHNOTES, FETCHJT } from '../Actions/types';

const initialState = {
    JobID: 0,
    JobName: '',
    StartTime: '',
    EndTime: '',
    Vehicle: 0,
    Delays: '',
    Risk: '',
    Weather: '',
    ProjectID: 0,
    CustomerID: 0,
    JobTypeID: 0,
    TeamAssignedID: 0,
    proj: [],
    cust: [],
    jobType: [],
    team: [],
    job: [],
    jobproj: [],
    selectjobproj: false,
    errJobName: false,
    errStartTime: false,
    errEndTime: false,
    errVehicle: false,
    errDelays: false,
    errRisk: false,
    errWeather: false,
    DialogCust: false,
    DialogTeam: false,
    DialogMobileTeam: false,
    Snack: false,
    SnackNegative: false,
    CustomerName: '',
    fleet: [],
    TeamName: '',
    addTeamAssigned: [],
    teams: [],
    errJobType: false,
    errTeamName: false,
    errProject: false,
    errCustomer: false,
    isEd: false,
    users: [],
    UserID: 0,
    errUserID: false,
    tasks: [],
    docpath: '',
    dialogOpen: false,
    jobpath: '',
    selectedJob: [],
    selectedShown: false,
    DisplayJobs: false,
    taskproj: [],
    dialogOpenjm: false,
    jobuser: [],
    notes: [],
    note: '',
    DialogNote: false,
    SnackNote: false,
    SnackNegativeNote: false,
    errnotes: false,
    DialogNoteView: false,
    JT: [],
}

export default function red(state = initialState, action) {
    switch (action.type) {

        case ADDJOB:

            return {
                ...state,
                [action.objName]: action.payload

            }

        case FETCHSELECTEDJOB:
            return {
                ...state,
                selectedJob: action.payload
            }

        case ADDCUSTOMER:
            return {
                ...state,
                CustomerName: action.payload
            }

        case ADDTEAM:
            return {
                ...state,
                TeamName: action.payload
            }

        case ADDTEAMASSIGNED:
            return {
                ...state,
                addTeamAssigned: action.payload
            }

        case FETCHCUSTOMER:
            return {

                ...state,
                cust: action.payload

            }

        case FETCHTEAM:
            return {

                ...state,
                teams: action.payload

            }

        case FETCHPROJECT:
            return {

                ...state,
                proj: action.payload

            }

        case FETCHJOBTYPE:
            return {

                ...state,
                jobType: action.payload

            }
        case FETCHTEAMASSIGNED:
            return {

                ...state,
                team: action.payload

            }

        case FETCHJOB:
            return {

                ...state,
                job: action.payload

            }

        case FETCHREG:
            return {

                ...state,
                fleet: action.payload

            }

        case FETCHUSERS:
            return {

                ...state,
                users: action.payload

            }

        case FETCHTASKSPEC:
            return {

                ...state,
                tasks: action.payload

            }


        case FETCHJOBSP:
            return {

                ...state,
                jobproj: action.payload

            }

        case FETCHJOBUSER:
            return {

                ...state,
                jobuser: action.payload

            }

            case FETCHNOTES:
            //console.log(action.payload);
            return {

                ...state,
                notes: action.payload

            }
            case FETCHJT:
                return {
    
                    ...state,
                    JT: action.payload
    
                }
        default:
            return state;
    }

}