import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Input,
    Paper,
    Typography,
    Grid,
    Snackbar,
    TextField
} from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { editSubTask } from '../../Actions/SubTaskAction';
import { fetchHelper } from "../../RequetsHandler/FetchHandler";
import { localAdd } from '../../Actions/localstore'
import Loader from "../../Login/Loader";
import { showLoader, hideLoader } from "../../Actions/LoginAction";
import Compress from "react-image-file-resizer";

const SubTask = ({ subtask, subtaskOpen, editSubTask, snack, snackNegative, startdisST, closedisST, reasonST, localAdd, disabledST, snackST, snackNegativeST, snackTimerUpdate, eReasonST, showLoader, hideLoader, subtaskid }) => {

    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedExcel, setSubSelectedExcel] = useState([]);

    const handleClose = () => {
        editSubTask("subtaskOpen", false);
        editSubTask("errReasonST", false)
    };

    const success = () => {
        editSubTask("Snack", true)
        hideLoader()
        handleClose()
    }

    const failure = () => {
        editSubTask("SnackNegative", true)
    }


    const changeSTValue = (e) => {
        // console.log(e.target.value)
        // console.log(e.target.name)
        editSubTask(e.target.name, e.target.value);
    }

    function SubTaskSuccess() {
        //console.log("Time Added ", cdates)
        localAdd("disabled", true)
        editSubTask("SnackTimer", true)
    }

    function SubTaskFailure() {
        //console.log("Time Not Added")
        editSubTask("SnackNegativeTimer", true)
    }



    const addingSTTime = async (ab) => {
        //console.log(ab);

        fetchHelper.postData("api/SubTaskTracker", ab, SubTaskSuccess, SubTaskFailure);

    }

    const dts = null;
    const [cdates, setDates] = useState(dts);

    const handlestartSt = async (a) => {
        let dts = new Date();
        setDates(dts);
        //console.log(a)
        var objSt = {

            durationid: 0,
            subtaskid: a.tasktypeid,
            starts: dts
        }

        addingSTTime(objSt)
    }





    function SubTaskSuccessu() {
        //console.log("Time Updated", cdatec)
        localAdd("disabled", true)
        editSubTask("SnackTimer", true)
        editSubTask("reasonST", "")
    }

    function SubTaskFailureu() {
        //console.log("Time Not Updated")
        editSubTask("SnackNegativeTimer", true)
    }


    const UpdataingTimeSt = async (ab) => {
        //console.log(ab)
        fetchHelper.updateData("api/SubTaskTracker", ab, SubTaskSuccessu, SubTaskFailureu);

    };

    const dtc = null;
    const [cdatec, setDatec] = useState(dtc)
    const handlcloseSt = (tasktypeid) => {
        let dtc = new Date();
        setDatec(dtc);
        //console.log("for close ", tasktypeid)

        if (reasonST.trim() === "") {
            editSubTask("errReasonST", true)
            editSubTask("SnackNegativeTimer", true)
            return;
            //console.log("bs")
        }

        var obj = {

            //fix update
            subtaskid: tasktypeid,
            stop: dtc,
            reason: reasonST,


        }

        UpdataingTimeSt(obj)
        //console.log("for update",reasonST)

    }


    const setSubSelectedPicture = (event) => {

        const picarr = event.target.files

        for (let i = 0; i < picarr.length; i++) {
            const element = picarr[i];


            setSelectedFile([...selectedFile, element])
        }
    }

    return (
        <Grid>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => {
                    editSubTask("Snack", false);
                }}
                message="Successful Upload"
                action={<React.Fragment></React.Fragment>}
            />

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snackNegative}
                onClose={() => {
                    editSubTask("SnackNegative", false);
                }}
                autoHideDuration={6000}
                message="Failed to Upload"
                action={<React.Fragment></React.Fragment>}
            />

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snackST}
                onClose={() => {
                    editSubTask("SnackTimer", false);
                }}
                autoHideDuration={6000}
                message="Sub-Task Timer Started/Updated"
                action={<React.Fragment></React.Fragment>}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snackNegativeST}
                onClose={() => {
                    editSubTask("SnackNegativeTimer", false);
                }}
                autoHideDuration={6000}
                message="Failed to Stop Timer"
                action={<React.Fragment></React.Fragment>}
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snackTimerUpdate}
                onClose={() => {
                    editSubTask("SnackTimerUpdate", false);
                }}
                autoHideDuration={6000}
                message=" Timer Updated"
                action={<React.Fragment></React.Fragment>}
            />

            <Dialog open={subtaskOpen} onClose={handleClose} fullWidth xl>
                <DialogTitle>
                    {subtask.length > 0 ? subtask[0].taskname : ""}
                </DialogTitle>
                <DialogContent>
                    <b> {subtask.length > 0 ? "" : "No tasks sceduled"}</b>

                    {subtask.map((a) => (
                        <Paper style={{ margin: 10, padding: 5 }}>
                            <Typography>
                                <b>Sub-Task Name: </b>
                                {a.tasktypename}
                            </Typography>
                            <Typography>
                                <b>Description: </b>
                                {a.description}
                            </Typography>
                            <Typography>
                                <b>Task Status: </b>
                                {a.status}
                            </Typography>

                            <h5>Photo's</h5>
                            <input
                                type="file"
                                id="fileUpload"
                                color="primary"
                                multiple
                                onChange={async (event) => {
                                    hideLoader()
                                    editSubTask("SubTaskID", a.tasktypeid)
                                    editSubTask("Snack", false)
                                    editSubTask("SnackSegative", false)
                                    setSubSelectedPicture(event)
                                }}
                            />

                            <Typography>
                                {subtaskid === a.tasktypeid &&
                                    <b>Pictures Taken: {selectedFile.length}</b>
                                }
                            </Typography>


                            <Button
                                color="primary"
                                variant="outlined"
                                size="small"

                                onClick={async (event) => {
                                    for (let i = 0; i < selectedFile.length; i++) {
                                        Compress.imageFileResizer(

                                            selectedFile[i], // the file from input
                                            1920, // width
                                            1080, // height
                                            "JPEG", // compress format WEBP, JPEG, PNG
                                            70, // quality
                                            0, // rotation
                                            (uri) => {
                                                //console.log(uri);
                                                // You upload logic goes here
                                                const formData = new FormData();
                                                formData.append("files", uri);
                                                formData.append("taskid", a.tasktypeid);
                                                fetchHelper.post("api/FileSubTask", formData, (res) => {
                                                    if (res.status === 200) {
                                                        //showLoader();
                                                        editSubTask("fileUpload", '')
                                                        editSubTask("Snack", true)
                                                    }
                                                    else {
                                                        if (res.status === 400) {
                                                            hideLoader()
                                                            editSubTask("SnackNegative", true)
                                                        }
                                                    }
                                                });
                                            },
                                            "file" // blob or base64 default base64
                                        );
                                    };
                                    setSelectedFile([]);
                                    // onClick={async (event) => {
                                    //     //console.log(a.taskid, selectedFile)
                                    //     showLoader();
                                    //     const formData = new FormData();
                                    //     for (let i = 0; i < selectedFile.length; i++) {
                                    //     formData.append("files", selectedFile[i]);
                                    //     formData.append("taskid", a.tasktypeid);
                                    //     //console.log("formdata", formData)
                                    //     fetchHelper.post("api/FileSubTask", formData, success, failure);
                                    //     }

                                    // }}>
                                }}>
                                Submit File
                            </Button>


                            <Typography>
                            <h5>Excel</h5>
                            <input
                                type="file"
                                id="fileUpload"
                                color="primary"
                                multiple
                                onChange={async (event) => {
                                    hideLoader()
                                    editSubTask("SubTaskID", a.tasktypeid)
                                    editSubTask("Snack", false)
                                    editSubTask("SnackSegative", false)
                                    setSubSelectedExcel(event.target.files)
                                }}
                            />
                            </Typography>
                            <Typography>
                                {subtaskid === a.tasktypeid &&
                                    <b>Pictures Taken: {selectedExcel.length}</b>
                                }
                            </Typography>


                            <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                    onClick={async (event) => {
                                        //console.log(a.taskid, selectedFile)
                                        showLoader();
                                        const formData = new FormData();
                                        for (let i = 0; i < selectedExcel.length; i++) {
                                        formData.append("files", selectedExcel[i]);
                                        formData.append("taskid", a.tasktypeid);
                                        //console.log("formdata", formData)
                                        fetchHelper.post("api/FileSubTask", formData, success, failure);
                                        }
                                        setSubSelectedExcel([]);
                                    }}>
                                Submit File
                            </Button>
                            <b><Loader></Loader></b>

                            <Grid>
                                <TextField
                                    name="reasonST"
                                    onChange={changeSTValue}
                                    label="Reason For Close"
                                    variant="standard"
                                    color="primary"
                                    placeholder="Reason For Close"
                                    error={eReasonST}
                                    helperText={eReasonST ? "please enter a value " : ""}

                                >
                                </TextField>
                            </Grid>
                            <Grid>
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    onClick={async () => { handlestartSt(a) }}
                                    disabled={startdisST}
                                >
                                    Start Task
                                </Button>

                                <Button
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    onClick={async () => { handlcloseSt(a.tasktypeid) }}
                                    error={eReasonST}
                                    helperText={eReasonST ? "please enter a value " : ""}
                                >
                                    Close Task
                                </Button>
                            </Grid>
                        </Paper>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button
                        color="primary"
                        variant="outlined"
                        size="small"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    subtask: state.subred.subtask,
    subtaskOpen: state.subred.subtaskOpen,
    snack: state.subred.Snack,
    snackNegative: state.subred.SnackNegative,

    startdisST: state.subred.startdisST,
    closedisST: state.subred.closedisST,
    reasonST: state.subred.reasonST,
    reasondialogST: state.subred.reasondialogST,
    disabledST: state.local.disabledST,

    snackST: state.subred.SnackTimer,
    snackNegativeST: state.subred.SnackNegativeTimer,
    snackTimerUpdate: state.subred.SnackTimerUpdate,
    eReasonST: state.subred.errReasonST,
    subtaskid: state.subred.SubTaskID,

});


export default connect(mapStateToProps, { editSubTask, localAdd, showLoader, hideLoader })(SubTask)
