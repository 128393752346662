import { Button, Container, Grid, InputLabel, makeStyles, Snackbar, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { taskAdd, fetchTask, fetchStatus, fetchJob } from '../../Actions/TaskAction';
import TaskJob from '../../Pages/Task/TaskJob'
import TaskStatus from '../../Pages/Task/TaskStatus';
import { fetchHelper } from '../../RequetsHandler/FetchHandler';

const CreateTask = ({
    taskId,
    taskName,
    taskType,
    startTime,
    endTime,
    statusId,
    jobId,
    task,
    job,
    status,
    taskAdd,
    fetchJob,
    fetchStatus,
    fetchTask,
    snack,
    snackNegative,
    errtaskName,
    errtaskType,
    errstartTime,
    errendTime,
    isEd,
    errjobId,
    errStatId,
    taskpath,
    taskOpen
}) => {
    const btnStyle = makeStyles((theme) => ({

        root: {
            width: '99%',
            margin: 2

        },
        paperHead: {
            margin: '5px',
            marginBottom: '15px',
            fontWeight: "bold"
        },
        table:
        {

            align: "center"
        },

        tableCell: {
            width: 250,
            borderStyle: "none",

        },
        tableHead: {
            paddingLeft: 10,
            borderStyle: 'none'

        },
        paper: {

            margin: '5px'
        }

    }))


    useEffect(() => {
        fetchJob();
        fetchStatus();
        fetchTask();
        return () => {
        }
    }, [fetchJob, fetchStatus, fetchTask])


    const HandleClose = () => {
        taskAdd("isEd", false)
        taskAdd("taskOpen", false);
    };

    const changeValue = (e) => {
        taskAdd(e.target.name, e.target.value);
    }

    function taskSuccess(e) {
        fetchTask();
        taskAdd("Snack", true);
        HandleClose();
    }

    function taskFailure() {
        taskAdd("SnackNegative", true);
    }

    const addingTask = async (ab) => {
        fetchHelper.postData('api/Task', ab, taskSuccess, taskFailure)
    }

    function updateTaskSuccess() {

        taskAdd("TaskID", 0);
        taskAdd("TaskName", "");
        taskAdd("TaskType", "");
        taskAdd("EndTime", "");
        taskAdd("StartTime", "");
        taskAdd("StatusID", 0);
        taskAdd("JobID", 0);
        taskAdd("isEd", false);
        HandleClose();
    }
    function updateTaskFailure() {
        taskAdd("SnackNegative", true);
    }


    const editTask = async (ab) => {
        fetchHelper.updateData('api/Task', ab, updateTaskSuccess, updateTaskFailure)
    };

    const submitValue = () => {

        if (isEd) {
            if (!endTime) {
                taskAdd("errEndTime", true);
                taskAdd("SnackNegative", true);
                return;
            }

            if (!statusId) {
                taskAdd("errStatusID", true);
                taskAdd("SnackNegative", true);
                return;
            }

            var tasks = {
                taskid: taskId,
                taskname: taskName,
                tasktype: taskType,
                starttime: startTime,
                endtime: endTime,
                jobstatusid: statusId,
                jobid: jobId,
                taskpath: taskpath
            }

            editTask(tasks);

        } else {
            var valid = true;

            if ((taskName.trim() === "") || (/[^A-Za-z0-9^<>{}\"|;:.,~!?@#$%^=&*_\\]+/.test(taskName))) {
                valid = false;
                taskAdd("errTaskName", true)
                taskAdd("SnackNegative", true)
                return;

            }

            if (taskType.trim() === "") {
                valid = false;
                taskAdd("errTaskType", true)
                taskAdd("SnackNegative", true)
                return;

            }
            if (startTime.trim() === "") {
                valid = false;
                taskAdd("errStartTime", true)
                taskAdd("SnackNegative", true)
                return;

            }
            if (endTime.trim() === "") {
                valid = false;
                taskAdd("errEndTime", true)
                taskAdd("SnackNegative", true)
                return;

            }

            if (jobId === 0) {
                valid = false;
                taskAdd("errJobID", true)
                taskAdd("SnackNegative", true)
                return;

            }

            if (statusId === 0) {
                valid = false;
                taskAdd("errStatusID", true)
                taskAdd("SnackNegative", true)
                return;

            }

            if (valid) {
                var task = {
                    taskid: 0,
                    taskname: taskName,
                    tasktype: taskType,
                    starttime: startTime,
                    endtime: endTime,
                    jobstatusid: statusId,
                    jobid: jobId,
                    taskpath: taskpath
                }
                addingTask(task);

            } else {
                console.log("Problem");
            }
        }
    }

    const classes = btnStyle();
    const minDate = new Date().toISOString().split("T")[0];


    return (
        <Container maxWidth="xl">
            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => { taskAdd("Snack", false) }}
                message="Task has been added"
                action={
                    <React.Fragment>

                    </React.Fragment>
                } />

            <Snackbar anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
                open={snackNegative}

                onClose={() => { taskAdd("SnackNegative", false) }}
                autoHideDuration={6000}
                message="The Task could not be added"
                action={
                    <React.Fragment>
                    </React.Fragment>
                } />

            <Grid container direction="column" spacing={2}>

                <Grid container spacing={3}>

                    <Dialog name="DialogOpen" open={taskOpen} onClose={HandleClose}>
                        <DialogTitle>Task</DialogTitle>
                        <DialogContent>
                            <Grid item xs={12} container direction="column" spacing={2}>


                                <Grid item >

                                    <TextField name="TaskName"
                                        onChange={changeValue}
                                        className={classes.root}
                                        error={errtaskName}
                                        label="Task Name"
                                        variant="standard"
                                        color="primary"
                                        placeholder="Task Name"
                                        helperText={errtaskName ? "please enter a value " : ''}
                                        value={taskName}
                                        disabled={isEd}
                                    />
                                </Grid>

                                <Grid item>

                                    <TextField name="TaskType"
                                        onChange={changeValue}
                                        error={errtaskType}
                                        className={classes.root}
                                        label="Task Type"
                                        variant="standard"
                                        color="primary"
                                        placeholder="Task Type"
                                        helperText={errtaskType ? "please enter a value " : ''}
                                        value={taskType}
                                        disabled={isEd}
                                    />

                                </Grid>

                                <Grid item >

                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="StartTime"
                                        type="date"
                                        onChange={changeValue}
                                        value={startTime}
                                        fullWidth
                                        inputProps={{ min: minDate }}
                                        error={errstartTime}
                                        label="Start Time"
                                        variant="standard"
                                        color="primary"
                                        placeholder="Start Time"
                                        helperText={errstartTime ? "please enter a value " : ''}
                                        disabled={isEd}
                                    />
                                </Grid>


                                <Grid item >

                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        name="EndTime"
                                        type="date"
                                        onChange={changeValue}
                                        value={endTime}
                                        fullWidth
                                        inputProps={{ min: minDate }}
                                        error={errendTime}
                                        label="End Time"
                                        variant="standard"
                                        color="primary"
                                        placeholder="End Time"
                                        helperText={errendTime ? "please enter a value " : ''} />
                                </Grid>

                                <Grid item >



                                    <InputLabel error={errjobId}>Jobs</InputLabel>

                                    <TaskJob cList={job} onCh={changeValue} />



                                </Grid>

                                <Grid item xs>



                                    <InputLabel error={errStatId} >Status</InputLabel>

                                    <TaskStatus cList={status} onCh={changeValue} />



                                </Grid>




                            </Grid>

                        </DialogContent>
                        <DialogActions>
                            <Button color="primary" onClick={HandleClose}>
                                Cancel
                            </Button>

                            <Button
                                onClick={submitValue}
                                color="primary"
                                variant="contained"
                                style={{ textTransform: "none" }}
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>


                </Grid>
            </Grid>
        </Container>
    )
}

const mapStateToProps = state => ({
    taskId: state.taskRed.TaskID,
    taskName: state.taskRed.TaskName,
    taskType: state.taskRed.TaskType,
    startTime: state.taskRed.StartTime,
    endTime: state.taskRed.EndTime,
    statusId: state.taskRed.StatusID,
    jobId: state.taskRed.JobID,
    task: state.taskRed.task,
    job: state.taskRed.job,
    status: state.taskRed.status,
    snack: state.taskRed.Snack,
    snackNegative: state.taskRed.SnackNegative,
    errtaskName: state.taskRed.errTaskName,
    errtaskType: state.taskRed.errTaskType,
    errstartTime: state.taskRed.errStartTime,
    errendTime: state.taskRed.errEndTime,
    isEd: state.taskRed.isEd,
    errjobId: state.taskRed.errJobID,
    errStatId: state.taskRed.errStatusID,
    taskpath: state.taskRed.taskpath,
    taskOpen: state.taskRed.taskOpen

})

export default connect(mapStateToProps, { taskAdd, fetchStatus, fetchTask, fetchJob })(CreateTask)
