import { FETCH_ITEMS, FETCH_MENU, FETCH_MENU_ITEMS, FECTH_SIDEBAR } from '../Actions/types';

const intitialState = {

    menuItems: [],
    itemItems: [],
    miItems: [],
    item: {},
    sidebar: []

}


export default function red(state = intitialState, action) {
    switch (action.type) {
        case FETCH_MENU:
            return {

                ...state,
                menuItems: action.payload

            }
        case FETCH_ITEMS:

            return {

                ...state,
                itemItems: action.payload

            }


        case FECTH_SIDEBAR:

            return {
                ...state,
                sidebar: action.payload

            }


        case FETCH_MENU_ITEMS:


            return {

                ...state,
                miItems: action.payload

            }

        default:
            return state;

    }

}