import { Container, MenuItem, Select } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { jobAdd, fetchCustomer, custAdd } from '../../Actions/JobAction'


const JobProjSelect = ({usrProj, cList,eProjID, onCh, isEd }) => {

    return (
        <Container>
        <Select
        disabled={isEd}
            name =  "ProjectID"
            error = {eProjID}
            onChange = {onCh}
            fullWidth
            placeholder = "Hello World"
            value = {usrProj}
        >
            <MenuItem value={0}> <em>None</em></MenuItem>
                {cList.map((item) => (

                <MenuItem value={item.projectid}>{item.projectname}</MenuItem>
                
                ))}

        </Select>



        </Container>
    )
}
const mapStateToProps = state =>({
    usrProj : state.jobRed.ProjectID , 
    eProjID  :state.jobRed.errProject,
    isEd: state.jobRed.isEd

})

export default connect(mapStateToProps , {custAdd , fetchCustomer, jobAdd})(JobProjSelect)
