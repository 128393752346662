import React, { useEffect } from 'react'
import { Grid, Button, Paper} from '@material-ui/core';
import { connect } from 'react-redux';
import {fetchVehicleDetails} from '../../Actions/VehicleDetailsAction';
import {vehicelDetailsAdd} from '../../Actions/VehicleDetailsAction';
import { DataGrid } from "@material-ui/data-grid";



const VehicleDetailer = ({
  vehicleDetails,
  vehicelDetailsAdd,
  fetchVehicleDetails,
}) => {

  const rows = vehicleDetails.map((row) => {
    return {
      id: row.companyFleetModelID,
      mileage: row.mileage,
      registration: row.registration,
      vin: row.vin,
      engineNumber: row.engineNumber,
      gvm: row.gvm,
      modelname: row.modelname,
      fleetnumber: row.fleetnumber,
      name: row.name,
      vehicletypename: row.description,
     
      edit: row,
    };
  });

  
  const columns = [
    { field: "id", hide: true },
    {
      field: "registration",
      headerName: "Registration",
      width: 200,
    },
    {
      field: "mileage",
      headerName: "Mileage",
      width: 200,
    },
    
    {
      field: "vin",
      headerName: "Vin",
      width: 200,
    },
    {
      field: "engineNumber",
      headerName: "Colour",
      width: 200,
    },
    {
      field: "gvm",
      headerName: "Year",
      width: 200,
    },
    {
      field: "modelname",
      headerName: "Model",
      width: 200,
    },
    {
      field: "vehicletypename",
      headerName: "Vehicle",
      width: 200,
    },
    {
      field: "fleetnumber",
      headerName: "Company Fleet Number",
      width: 200,
    },
    {
      field: "name",
      headerName: "Driver",
      width: 200,
    },
    
    
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      filterable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <strong>
            <Button
              onClick={() => {
                handleLink(params.value);
              }}
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
            >
              Edit
            </Button>
          </strong>
        );
      },
    },
  ];
    
    useEffect(() => {
        // var check = JSON.stringify(vehicleDetails);
        // console.log(check)
        fetchVehicleDetails()
        return () => {

        }
    }, [fetchVehicleDetails])

    const handleLink = (row) => {

      vehicelDetailsAdd("companyFleetModelID" , row.companyFleetModelID) ; 
      vehicelDetailsAdd("mileage", row.mileage)
      vehicelDetailsAdd("registration",row.registration)
      vehicelDetailsAdd("vin",row.vin)
      vehicelDetailsAdd("engineNo" , row.engineNumber);
      vehicelDetailsAdd("gvm",row.gvm)
      vehicelDetailsAdd("modelId",row.modelid)
      vehicelDetailsAdd("companyFleetId",row.companyfleetid)
      vehicelDetailsAdd("driverId", row.driverid)
      vehicelDetailsAdd("vehicleGroupId" , row.vehiclegroupid)
      vehicelDetailsAdd("isEditing",true);


    }
    
    return (
      <Grid item xs={12}>
      <div style={{ display: "flex", height: "100%" }}>
        <Paper elevation={5} />
        <div style={{ flexGrow: 1, height: "80vh" }}>
          <DataGrid rows={rows} columns={columns} />
        </div>
        <Paper />
      </div>
    </Grid>
    )
};

const mapStateToProps = state =>({
    vehicleDetails: state.vehicleDetail.VehicleDetails
    })

export default connect(mapStateToProps,{fetchVehicleDetails,vehicelDetailsAdd})(VehicleDetailer)
