import {
  Button,
  Container,
  Grid,
  InputLabel,
  Snackbar,
  TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { entryValue, fetchCompany } from "../Actions/CompanyFleetDetailsAction";
import CompSelect from "../CompanyFleetDetails/companySelectCF";
import { fetchCompanyFleet } from "../Actions/FleetDetailsAction";
import { fetchHelper } from '../RequetsHandler/FetchHandler';

const CompanyFleetDetails = ({
  fetchCompany,
  fetchCompanyFleet,
  entryValue,
  FleetNumber,
  Description,
  Location,
  CompanyID,
  eFleetNumber,
  eDescription,
  eLocation,
  companyList,
  snack,
  snackNegative,
  isEd,
  cfid,
  SnackFleetNumber,
}) => {
  useEffect(() => {
    fetchCompany();

    return () => { };
  }, [fetchCompany, FleetNumber]);

  // handling and updating the current state

  const changeValue = (e) => {
    entryValue(e.target.name, e.target.value);
    if (e.target.value === "" || e.target.value === 0) {
      entryValue(`err${e.target.name}`, true);
    } else {
      entryValue(`err${e.target.name}`, false);
    }
  };

  function updateCompanySuccess() {
    fetchCompanyFleet();
    entryValue("snack", true);
    entryValue("isEditing", false);
    entryValue("usrCompanyFleetID", 0);
    entryValue("usrDescription", "");
    entryValue("usrLocation", "");
    entryValue("usrFleetNumber", "");
    entryValue("usrCompanyID", 0);
  }
  function updateCompanyFailure() {
    entryValue("snackNegative", true);
  }


  //userEditing
  const editCompanyDetails = async (ab) => {

    fetchHelper.updateData("api/companyfleet", ab, updateCompanySuccess, updateCompanyFailure);


  };

  //userPosting

  const addCompanyFleetDetails = async (ab) => {

    fetchHelper.postData('api/companyFleet', ab, (res) => {
      console.log(res.status)
      if (res.status === 200) {
        fetchCompanyFleet();
        entryValue("snack", true);
        entryValue("usrCompanyFleetID", 0);
        entryValue("usrFleetNumber", "");
        entryValue("usrDescription", "");
        entryValue("usrLocation", "");
        entryValue("usrCompanyID", 0);
      }
      else {
        if (res.status === 400) {
          entryValue("SnackFleetNumber", true)
        }
      }
    }, () => { entryValue("snackNegative", true) })
  };

  const submitValues = () => {
    if (isEd) {
      if (Description.trim() === "" | (!(/^[0-9a-z A-Z]*$/.test(Description)))) {
        entryValue("errusrDescription", true);
        entryValue("snackNegative", true);
        return;
      }

      if (Location.trim() === "" | (!(/^[0-9a-z A-Z]*$/.test(Location)))) {
        entryValue("errusrLocation", true);
        entryValue("snackNegative", true);
        return;
      }
      var obj = {
        companyFleetID: cfid,
        fleetNumber: FleetNumber,
        description: Description,
        location: Location,
        companyID: CompanyID,
      };

      editCompanyDetails(obj);
      entryValue("isEditing", false);
    } else {
      if (
        (FleetNumber !== "") &
        (Description !== "") &
        (Location !== "") &
        (CompanyID !== 0)
      ) {
        var companyFleetDetail = {
          companyFleetID: 0,
          fleetNumber: parseInt(FleetNumber, 10),
          description: Description,
          location: Location,
          companyID: CompanyID,
        };
        addCompanyFleetDetails(companyFleetDetail)
          .then(() => {
            entryValue("snack", true);
          })
          .catch(() => {
            entryValue("snackNegative", true);
          });
        entryValue("isEditing", false);
      } else {
        if ((FleetNumber.trim() === "")) {
          entryValue("errusrFleetNumber", true);
          entryValue("snackNegative", true);
          return;
        }

        if (Description.trim() === "" | (!(/^[0-9a-z A-Z]*$/.test(Description)))) {
          entryValue("errusrDescription", true);
          entryValue("snackNegative", true);
          return;
        }

        if (Location.trim() === "" | (!(/^[0-9a-z A-Z]*$/.test(Location)))) {
          entryValue("errusrLocation", true);
          entryValue("snackNegative", true);
          return;
        }
        if (CompanyID === 0) {
          entryValue("errusrCompanyID", true);
          entryValue("snackNegative", true);
          return;
        }
      }
    }
  };

  return (
    <Container>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snack}
        autoHideDuration={6000}
        onClose={() => {
          entryValue("snack", false);
        }}
        message="Company Fleet Details has been added"
        action={<React.Fragment></React.Fragment>}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={snackNegative}
        onClose={() => {
          entryValue("snackNegative", false);
        }}
        autoHideDuration={6000}
        message="The Company Fleet Details could not be added"
        action={<React.Fragment></React.Fragment>}
      />

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={SnackFleetNumber}
        onClose={() => {
          entryValue("SnackFleetNumber", false);
        }}
        autoHideDuration={6000}
        message="The Fleet Number is taken"
        action={<React.Fragment></React.Fragment>}
      />

      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            disabled={isEd}
            name="usrFleetNumber"
            onChange={changeValue}
            error={eFleetNumber}
            type="Number"
            label="Fleet Number"
            variant="standard"
            fullWidth
            color="primary"
            value={FleetNumber}
            placeholder="Fleet Number"
            helperText={eFleetNumber ? "please enter a value " : ""}
          />
        </Grid>
        <Grid item>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            name="usrDescription"
            onChange={changeValue}
            fullWidth
            error={eDescription}
            label="Description"
            variant="standard"
            value={Description}
            color="primary"
            placeholder="Description"
            helperText={eDescription ? "please enter a value " : ""}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            name="usrLocation"
            onChange={changeValue}
            error={eLocation}
            label="Location"
            variant="standard"
            value={Location}
            color="primary"
            placeholder="Location"
            helperText={eLocation ? "please enter a value " : ""}
          />
        </Grid>
        <Grid item>
          <InputLabel>Select Company</InputLabel>

          <CompSelect cList={companyList} onCh={changeValue} />
        </Grid>
        <Grid item>
          <Button
            type="reset"
            onClick={submitValues}
            fullWidth
            variant="contained"
            color="primary"
            style={{ textTransform: "none" }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  CFleet: state.companyFleetDetail.CompanyFleetDetails,
  FleetNumber: state.companyFleetDetail.usrFleetNumber,
  Description: state.companyFleetDetail.usrDescription,
  Location: state.companyFleetDetail.usrLocation,
  CompanyID: state.companyFleetDetail.usrCompanyID,
  companyList: state.companyFleetDetail.companyList,
  cfid: state.companyFleetDetail.usrCompanyFleetID,

  isEd: state.companyFleetDetail.isEditing,
  eFleetNumber: state.companyFleetDetail.errusrFleetNumber,
  eDescription: state.companyFleetDetail.errusrDescription,
  eLocation: state.companyFleetDetail.errusrLocation,
  snack: state.companyFleetDetail.snack,
  snackNegative: state.companyFleetDetail.snackNegative,
  SnackFleetNumber: state.companyFleetDetail.SnackFleetNumber,
});
export default connect(mapStateToProps, {
  entryValue,
  fetchCompany,
  fetchCompanyFleet,
})(CompanyFleetDetails);
