import { ADDSERVICE, FETCHSERVICEDETAILS, FETCHCFM, FETCHSERVICETYPE} from './types';
import {API_ADDR} from '../DatabaseStrings/dbStrings';
import {fetchHelper} from '../RequetsHandler/FetchHandler';
import auth from '../Authentication/auth'

export const serviceAdd = (name , value) => dispatch => {
    //console.log({name , value})    

dispatch({
        type : ADDSERVICE ,
        payload : value ,
        objname : name,
    })
}

export const fetchServices = () => dispatch => {

fetchHelper.GetData('api/services' , (res)=>{console.log(res.json())} , () => {});


    //console.log("in fetch service");
    fetch(`${API_ADDR}/api/Services`
    ,
    {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
        'Accept':'application/json',
        
    }}).then(res => res.json())
.then(data => dispatch(
    {
        type : FETCHSERVICEDETAILS,
        payload : data
    })).catch(err => console.log(err))

}

export const fetchCFM = () => dispatch => {
    //console.log("in fetch service");
    fetch(`${API_ADDR}/api/CompanyFleetModel`
    , {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
        'Accept':'application/json',
        
    }}).then(res => res.json())
.then(data => dispatch(
    {
        type : FETCHCFM,
        payload : data
    })).catch(err => console.log(err))

}

export const fetchServiceTypes = () => dispatch => {
    //console.log("in fetch service");
    fetch(`${API_ADDR}/api/ServiceType`
    , {
        mode:'cors',
        credentials:'include',
        referrerPolicy:'strict-origin-when-cross-origin',
        
        headers:{
            Authorization: `Bearer ${auth.getToken()}`,
        'Accept':'application/json',
        
    }}).then(res => res.json())
.then(data => dispatch(
    {
        type : FETCHSERVICETYPE,
        payload : data
    })).catch(err => console.log(err))

}