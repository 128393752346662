import {ADDCOMPANYFLEETMODEL, FETCHVEHICLEGROUP,FETCHCOMPANYFLEET,FETCHDRIVER, FETCHMODEL, FETCHVEHICLEDETAILS} from '../Actions/types'

const initialState={
    mileage : '',
    registration : '',
    vin : '',
    engineNo : '',
    gvm : '',
    modelId : 0,
    companyFleetId : 0,
    driverId : 0,
    vehicleGroupId : 0,
    vDriver : [],
    vVehicleGroup : [],
    vModel : [],
    vCompanyFleet : [],
    vVehicleGroupOpen : false,
    vVehicleGroupName: '',
    VehicleDetails: [],
    errmileage : false ,
    errregistration : false ,
    errvin : false ,
    errengineNo : false ,
    errgvm : false ,
    errvehicleGroupId : false,
    errmodelId : false ,
    errcompanyFleetId : false ,
    errdriverId  : false , 
    isEditing :false ,
    companyFleetModelID : 0  , 
    SnackNegative : false  ,
    Snack : false
    
}

export default function red(state = initialState, action){
    switch(action.type){
        case ADDCOMPANYFLEETMODEL :
            // console.log(action.payload);
            return{
                ...state ,
                [action.objname] : action.payload
            }
            case FETCHMODEL :
            // console.log(action.payload);
            return{
                ...state ,
                vModel : action.payload
            }
            case FETCHVEHICLEGROUP :
            // console.log(action.payload);
            return{
                ...state ,
                vVehicleGroup : action.payload
            }
            case FETCHCOMPANYFLEET :
            // console.log(action.payload);
            return{
                ...state ,
                vCompanyFleet : action.payload
            }
            case FETCHDRIVER :
            // console.log(action.payload);
            return{
                ...state ,
                vDriver : action.payload
            }
            case FETCHVEHICLEDETAILS:
            return{
                ...state,
                VehicleDetails: action.payload
            }      
            default:
                return state ;   
        }
    }
