import { MenuItem, Select, Container } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { taskAdd, fetchStatus } from '../../Actions/TaskAction'


const TaskStatusSelect = ({ usrStat, cList, eStatID, onCh}) => {

    return (
        <Container>
            <Select
                value={usrStat}
                name="StatusID"
                error={eStatID}
                onChange={onCh}
                fullWidth
                placeholder="Hello World"
                
            >
                <MenuItem value={0}> <em>None</em></MenuItem>
                {cList.map((item) => (

                    <MenuItem value={item.jobstatusid}>{item.status}</MenuItem>

                ))}

            </Select>



        </Container>
    )
}
const mapStateToProps = state => ({
    usrStat: state.taskRed.StatusID,
    eStatID: state.taskRed.errStatusID

})

export default connect(mapStateToProps, { taskAdd, fetchStatus })(TaskStatusSelect)
