import {ADDTEAMMEM, FETCHTEAMMEM, FETCHTEAMMEMBERASSIGNED} from '../Actions/types';
import {API_ADDR} from '../DatabaseStrings/dbStrings';
import auth from '../Authentication/auth'

export const teamAdd = (objName, value) => dispatch => {
    dispatch({
        type: ADDTEAMMEM,
        payload: value,
        objName: objName
    });
}

export const fetchTeamMem = () => async (dispatch) =>{
    
    fetch(`${API_ADDR}/api/TeamMembers`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHTEAMMEM , 
            payload: data
        })).catch(err => console.error(err))
}

export const fetchTeamMemberAssigned = () => async (dispatch) =>{
    
    fetch(`${API_ADDR}/api/TeamAssigned`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHTEAMMEMBERASSIGNED , 
            payload: data
        })).catch(err => console.error(err))
}