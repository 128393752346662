import { ADDDRIVER } from '../Actions/types';

const initialState = {
    Driver: '',
    DriverID : 0 ,
    Name: '',
    Surname: '',
    IdNo: '',
    PdpExp: '',
    Address: '',
    PhNumber: '',
    LicExp: '',
    DgdpExp: '',
    isEditing : false , 
    errName: false,
    errSurname: false,
    errIdNo: false,
    errPdpExp: false,
    errAddress: false,
    errPhNumber: false,
    errLicExp: false,
    errDgdpExp: false,
    Snack : false ,
    SnackNegative : false ,
    SnackDriverID: false
}

export default function red(state = initialState, action){
    switch(action.type){
        case ADDDRIVER:
            return{
                ...state,
                [action.objName] : action.payload
            }
            default:
                 return state ;
    }
}