import { Container, MenuItem, Select } from '@material-ui/core';
import React from 'react'
import { connect } from 'react-redux';
import { jobAdd, fetchCustomer, custAdd } from '../../Actions/JobAction'


const ClientFilter = ({usrCust, cList,eCustID, onCh, isEd}) => {

    return (
        <Container>
        <Select
        
            name =  "CustomerID"
            error = {eCustID}
            onChange = {onCh}
            fullWidth
            placeholder = "Hello World"
            value = {usrCust}
        >
            <MenuItem value={0}> <em>None</em></MenuItem>
                {cList.map((item) => (

                <MenuItem value={item.customerid}>{item.customername}</MenuItem>
                
                ))}

        </Select>



        </Container>
    )
}
const mapStateToProps = state =>({
    usrCust : state.jobRed.CustomerID , 
    eCustID  :state.jobRed.errCustomer,
    isEd: state.sitecom.isEd,
    
})

export default connect(mapStateToProps , {custAdd , fetchCustomer, jobAdd})(ClientFilter)
