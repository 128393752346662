import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import {
  Link,
  Box,
  TextField,
  Typography,
  Container,
  CssBaseline,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import auth from "../Authentication/auth";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  loginvalue,
  failLogin,
  showLoader,
  hideLoader,
} from "../Actions/LoginAction";
import Loader from "./Loader";
import logo from "../resFiles/Comstruct_logo.jpg";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://Comstruct.co.za/">
        Comstruct
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logo: {
    width: "300px",
    height: "130px",
  },
}));

const Login = ({
  usrName,
  password,
  usrNameErr,
  passErr,
  loginvalue,
  showLoader,
  hideLoader,
  disable,
}) => {
  const [openLoginErrorToaster, setOpenLoginErrorToaster] =
    React.useState(false);
  const hist = useHistory();
  const classes = useStyles();
  const oChange = (e) => {
    loginvalue(e.target.name, e.target.value);
  };

  // const handleClick = () => {
  //   setOpenLoginErrorToaster(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenLoginErrorToaster(false);
  };

  useEffect(() => {
    return () => {};
  }, []);

  const onKeyUp = (event) => {
    if (event.charCode === 13) {
      loginuser();
    }
  };

  const loginuser = () => {
    handleClose();

    if (usrName === "") {
      loginvalue("UsrNameErr", true);
    }

    if (password === "") {
      loginvalue("PassErr", true);
    }

    if (usrName === "" || password === "") {
      return;
    }

    showLoader();

    auth.login(usrName, password, () => {
      if (auth.userInfo.token && auth.getRoleID() === 1) {
        hist.replace("/Dashboard");
        hideLoader();
        return;
      }
      if (auth.userInfo.token && auth.getRoleID() === 73) {
        hist.replace("/Dashboard/SiteSurvey");
        hideLoader();
        return;
      } 
      if (auth.userInfo.token && auth.getRoleID() === 77) {
        hist.replace("/Dashboard/ViewManagement");
        hideLoader();
        return;
      } else {
        hideLoader();
        return;
      }
    });
  };

  return (
    <Container component="main" maxWidth="xs">
      {/* <FolderExplore/> */}
      <CssBaseline />
      <div className={classes.paper}>
        {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar> */}
        {/* <Typography component="h2" variant="h4">
            Welcome to
          </Typography> */}
        <img src={logo} alt="" className={classes.logo}></img>
        {openLoginErrorToaster ? (
          <Alert severity="error">
            Oops! <b>Invalid Login Details</b>
          </Alert>
        ) : (
          ""
        )}

        <div className={classes.form} noValidate>
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            placeholder="Username"
            name="UsrName"
            label="Username"
            autoComplete="email"
            autoFocus
            error={usrNameErr}
            onChange={oChange}
            helperText={usrNameErr ? "Username is Required" : ""}
            onKeyPress={onKeyUp}
          />
          <TextField
            variant="standard"
            margin="normal"
            required
            fullWidth
            autoComplete="current-password"
            error={passErr}
            onChange={oChange}
            type="password"
            label="Password"
            color="primary"
            placeholder="Password"
            name="Password"
            helperText={passErr ? "Password is Required" : ""}
            onKeyPress={onKeyUp}
          />
          <Button
            onClick={loginuser}
            fullWidth
            variant="contained"
            color="primary"
            disabled={disable}
            onChange={oChange}
          >
            Login
          </Button>
          {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
        </div>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
      <Loader></Loader>
    </Container>
  );
};
const mapStateToProps = (state) => ({
  loginApproved: state.loginCred.credentials,
  usrName: state.loginCred.UsrName,
  password: state.loginCred.Password,
  usrNameErr: state.loginCred.UsrNameErr,
  passErr: state.loginCred.PassErr,
  failLogin: state.loginCred.LoginFailed,
  loading: state.loginCred.loading,
  disable: state.loginCred.disable,
});

export default connect(mapStateToProps, {
  loginvalue,
  failLogin,
  showLoader,
  hideLoader,
})(Login);
