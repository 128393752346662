import React from 'react'
import { connect } from 'react-redux'
import { CircularProgress } from '@material-ui/core'

const PageLoad = (
    {
        loading
    }
) => {
    if(!loading) return null;

    return (
        <div class="loader-container">
            <div className="loader">
                {/* <img src={load} height="75px"/> */}
                <CircularProgress />
            </div>    
        </div>
    )
}

const mapStateToProps= state =>({
    loading: state.loginCred.loading
})

export default connect(mapStateToProps)(PageLoad)
