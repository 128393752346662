import { API_ADDR } from "../DatabaseStrings/dbStrings";
import auth from '../Authentication/auth'

const axios = require("axios");

export class fetchHelper {
  static GetData(url, successCall, failureCall) {
    const reqUrl = `${API_ADDR}/${url}`;

    return new Promise((resolve) => {
      fetch(reqUrl, {
        mode: "cors",
        credentials: "include",
        referrerPolicy: "strict-origin-when-cross-origin",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
          Accept: "application/json",
        },
      })
        .then((res) => {
          resolve();
          successCall(res.json());
        })
        .catch((errr) => failureCall(errr));
    });
  }

  static postData(url, body, successCall, failureCall) {
    const reqUrl = `${API_ADDR}/${url}`;
    return new Promise((resolve) => {
      fetch(reqUrl, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        referrerPolicy: "strict-origin-when-cross-origin",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res)
        .then((val) => {
          successCall(val);
          resolve();
        })
        .catch((errr) => failureCall(errr));
    });
  }

  static post(url, body, successCall, failureCall) {
    const reqUrl = `${API_ADDR}/${url}`;
    return axios
      .post(reqUrl, body,{
        method: "POST",
        mode: "cors",
        credentials: "include",
        referrerPolicy: "strict-origin-when-cross-origin",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
          "Content-Type": "application/json",
        }})
      .then((res) => {
        successCall(res)
      })
      .catch((err) => failureCall(err));
  }

  static updateData(url, body, successCall, failureCall) {
    const reqUrl = `${API_ADDR}/${url}`;

    return new Promise((resolve) => {
      fetch(reqUrl, {
        method: "PATCH",
        mode: "cors",
        credentials: "include",
        referrerPolicy: "strict-origin-when-cross-origin",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        
      })
        .then((res) => {
          resolve();
          successCall(res.text);
          //console.log(body)
        })
        .catch((err) => failureCall(err));
    });
  }

  
  static deleteData(url, body, successCall, failureCall) {
    const reqUrl = `${API_ADDR}/${url}`;
    return new Promise((resolve) => {
      fetch(reqUrl, {
        method: "DELETE",
        mode: "cors",
        credentials: "include",
        referrerPolicy: "strict-origin-when-cross-origin",
        headers: {
          Authorization: `Bearer ${auth.getToken()}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => res)
        .then((val) => {
          successCall(val);
          resolve();
        })
        .catch((errr) => failureCall(errr));
    });
  }
}
