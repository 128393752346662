import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';

import { fetchTeamMem } from '../Actions/TeamMemberAction'
import { teamAssignedAdd, teamNameAdd } from '../Actions/JobAction'


const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  paper: {
    width: 200,
    height: 230,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));



function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const TransferList = ({ iList, teamMemb, teamAssignedAdd, addTeamAssign }) => {


  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);


  useEffect(() => {

    setLeft(iList);


    return () => {

    }
  }, [iList, teamMemb])

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {

    teamAssignedAdd(right.concat(left))

    setRight(right.concat(left));
    console.log(addTeamAssign);
    setLeft([]);
  };

  const handleCheckedRight = () => {
    teamAssignedAdd(right.concat(leftChecked))
    setRight(right.concat(leftChecked));
    console.log(addTeamAssign);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    teamAssignedAdd(not(right, rightChecked))
    setRight(not(right, rightChecked));
    console.log(addTeamAssign);
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    teamAssignedAdd([])
    setRight([]);
    console.log(addTeamAssign);
  };

  const customList = (items) => (



    <Paper className={classes.paper}>
      <List component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value.name}-label`;

          return (


            <ListItem key={value.itemID} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={` ${value.name}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <Grid container spacing={2} justify="center" alignItems="center" className={classes.root}>
      <Grid item>
        <p style={{ textAlign: 'center' }}>Available Team Members </p>
        {customList(left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="standard"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="standard"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="standard"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
          <Button
            variant="standard"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <p style={{ textAlign: 'center' }}>Added Team Members</p>
        {customList(right)}
      </Grid>
    </Grid>
  );
}

const mapStateToProps = state => ({

  teamMemb: state.teamMemRed.TeamMem,
  addTeamAssign: state.jobRed.addTeamAssigned

})


export default connect(mapStateToProps, { fetchTeamMem, teamNameAdd, teamAssignedAdd })(TransferList)