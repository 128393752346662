import { MenuItem } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import {editValue} from '../Actions/signUpAction'
import {Select, Container} from '@material-ui/core';
const RoleSelect = ({rList  , editValue , usrRole, edi}) => {



const changeValue = (e) =>{


editValue(e.target.name , e.target.value)


}
    return (
        <Container>
        <Select 
         disabled={edi}
        name="UsrRole"
        onChange={changeValue}
        fullWidth
        placeholder="Hello world"
        value={usrRole}
            >
            <MenuItem value={0}><em>None</em></MenuItem>
           
            {rList.map((item) =>(
            <MenuItem value={item.roleID}>{item.roleName}</MenuItem>
                                ))}
        
        </Select>

        </Container>
    )
}

const mapStateToProps = state =>({

    edi  :state.signUpPage.isEditing , 
    usrRole : state.signUpPage.UsrRole



})


export default connect(mapStateToProps ,{ editValue})(RoleSelect)
