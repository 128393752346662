import {ADDPROJ, FETCHSITES, FETCHSTATUS, FETCHPROJ, FETCHPROJSITE} from '../Actions/types';
import {API_ADDR} from '../DatabaseStrings/dbStrings';
import auth from '../Authentication/auth'

export const projAdd = (objName, value) => dispatch => {
    dispatch({
        type: ADDPROJ,
        payload: value,
        objName: objName
    });
}

export const fetchProj = () => async (dispatch) =>{
    
    fetch(`${API_ADDR}/api/Project`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHPROJ , 
            payload: data
        })).catch(err => console.error(err))
}

export const fetchProjSite = (siteid) => async (dispatch) =>{

    fetch(`${API_ADDR}/api/ProjSiteSpec?SiteID=${siteid}`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHPROJSITE , 
            payload: data
        })).catch(err => console.error(err))
}

export const fetchSites = () => async (dispatch) =>{
    
    fetch(`${API_ADDR}/api/Site`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHSITES , 
            payload: data
        })).catch(err => console.error(err))
}

export const fetchStatus = () => async (dispatch) =>{
    
    fetch(`${API_ADDR}/api/ProjectStatus`,
         {  mode:'cors',
         credentials:'include',
         referrerPolicy:'strict-origin-when-cross-origin',
             headers:{
                Authorization: `Bearer ${auth.getToken()}`,
                'Accept': 'application/json',
                
             }
         }).then(res =>res.json())
         .then(data => dispatch({
            type:FETCHSTATUS , 
            payload: data
        })).catch(err => console.error(err))
}