import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Input,
    Paper,
    Typography,
    Grid,
    Snackbar,
    Table,
} from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { editSubTask, fetchSubTaskTracker } from '../Actions/SubTaskAction';
import { fetchHelper } from "../RequetsHandler/FetchHandler";
import Tooltip from '@material-ui/core/Tooltip';
import PageviewIcon from '@material-ui/icons/Pageview';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { makeStyles } from '@material-ui/core/styles';

const SubTask = ({ subtask, DisplaySubTasks, editSubTask, snack, snackNegative, fetchSubTaskTracker, taskTrackerST, trackerdialogST }) => {

    const useStyles = makeStyles((theme) => ({
        table: {
            align: "center",
        },
        tableCell: {
            border: "1px solid black",
            borderStyle: "paper",
            width: 250,
            fontSize: '8pt',
        },
        tableHead: {
            paddingLeft: 10,
            borderStyle: "paper",

        },
        heading: {

            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
        },
        root: {
            flexGrow: 1,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: "left",
            color: theme.palette.text.primary,
        },
    }));

    const classes = useStyles();
    const [selectedFile, setSelectedFile] = useState(null);

    const handleClose = () => {
        editSubTask("DisplaySubTasks", false);
    };

    const success = () => {
        editSubTask("Snack", true)
        handleClose()
    }

    const failure = () => {
        editSubTask("SnackNegative", true)
    }


    const handleSubTaskTracker = (a) => {
        console.log(a)

        fetchSubTaskTracker(a)

        console.log("sub task timer array ", taskTrackerST)
        editSubTask("trackerdialogST", true)
        console.log(trackerdialogST)
    }

    const handleSubTaskTrackerclose = (a) => {
        editSubTask("trackerdialogST", false)

    }

    return (
        <Grid>

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snack}
                autoHideDuration={6000}
                onClose={() => {
                    editSubTask("Snack", false);
                }}
                message="Successful Upload"
                action={<React.Fragment></React.Fragment>}
            />

            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                open={snackNegative}
                onClose={() => {
                    editSubTask("SnackNegative", false);
                }}
                autoHideDuration={6000}
                message="Failed to Upload"
                action={<React.Fragment></React.Fragment>}
            />
            <Grid>
                <Dialog open={DisplaySubTasks} onClose={handleClose} fullWidth xl>
                    <DialogTitle>
                        {subtask.length > 0 ? subtask[0].taskname : ""}
                    </DialogTitle>
                    <DialogContent>
                        <b> {subtask.length > 0 ? "" : "No Sub-Tasks sceduled"}</b>

                        {subtask.map((a) => (
                            <Paper style={{ margin: 10, padding: 5 }}>
                                <Typography>
                                    <b>Sub-Task Name: </b>
                                    {a.tasktypename}
                                </Typography>
                                <Typography>
                                    <b>Description: </b>
                                    {a.description}
                                </Typography>
                                <Typography>
                                    <b>Task Status: </b>
                                    {a.status}
                                </Typography>
                                <Typography>
                                    <b>Track Sub-Task Status: </b>
                                    <Tooltip title="Track Sub-Task"><PageviewIcon color="primary" onClick={() => { handleSubTaskTracker(a.tasktypeid) }} /></Tooltip>
                                </Typography>

                                <Input
                                    type="file"
                                    id="fileUpload"
                                    color="primary"
                                    onChange={(event) => setSelectedFile(event.target.files[0])}
                                />
                                <Button onClick={async (event) => {
                                    //console.log(a.taskid, selectedFile)
                                    const formData = new FormData();
                                    formData.append("files", selectedFile);
                                    formData.append("taskid", a.tasktypeid);
                                    //console.log("formdata", formData)
                                    fetchHelper.post("api/FileSubTask", formData, success, failure);

                                }}>
                                    Submit File
                                </Button>
                            </Paper>
                        ))}
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary"
                            variant="outlined"
                            size="small"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>

            <Grid>
                <Dialog open={trackerdialogST} onClose={handleSubTaskTrackerclose} >
                    <DialogTitle>
                        {taskTrackerST.length > 0 ? taskTrackerST[0].duration : ""}
                    </DialogTitle>
                    <DialogContent>
                        <b> {taskTrackerST.length > 0 ? "" : "No Sub-Tasks Logged"}</b>
                        <Table>
                            <TableBody>
                                {taskTrackerST.map((licRow) => (
                                    <Paper className={classes.paper}>
                                        <TableRow key={licRow.durationid}>

                                            <Grid item >
                                                <b>Duration:</b> {licRow.duration}
                                            </Grid>
                                            <Grid item >
                                                <b>Reason:</b> {licRow.reason}
                                            </Grid>

                                        </TableRow>
                                    </Paper>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button color="primary"
                            variant="outlined"
                            size="small"
                            onClick={handleSubTaskTrackerclose}
                        >Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state) => ({
    subtask: state.subred.subtask,
    DisplaySubTasks: state.subred.DisplaySubTasks,
    snack: state.subred.Snack,
    snackNegative: state.subred.SnackNegative,
    taskTrackerST: state.subred.taskTrackerST,
    trackerdialogST: state.subred.trackerdialogST,
});


export default connect(mapStateToProps, { editSubTask, fetchSubTaskTracker })(SubTask)
