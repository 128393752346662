import React, { useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  TableBody,
  Box
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { fetchMenu, fetchSidebar } from "../Actions/MenuActions";
import { fetchSite } from '../Actions/SiteAction';
import { fetchProj } from "../Actions/ProjectAction";
import { fetchTask } from '../Actions/TaskAction';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuTypography: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


const DashboardDisplay = ({

  proj,
  siteList,
  task,
  fetchMenu,
  fetchSidebar,
  fetchProj,
  fetchSite,
  fetchTask,



}) => {

  const classes = useStyles();



  useEffect(() => {
    fetchMenu();
    fetchSidebar(1);
    fetchProj();
    fetchSite();
    fetchTask();
    return () => {

    }
  }, [fetchMenu,fetchSidebar,fetchProj,fetchSite,fetchTask])


  return (
    <Card sx={{ height: "100%", width: "10%" }}>
      <CardContent>
        <Grid container spacing={5} >
          

          <Grid container item xs={3} sm={4}>

            <TableContainer>
              <Table id="site">
                
                  <TableHead className={classes.tableHead}>

                    <TableRow>
                      <TableCell className={classes.tableCell} align="center"> <Box fontSize={20} fontWeight="fontWeightBold">Site </Box></TableCell>
                    </TableRow>

                  </TableHead>
                
                <TableBody>
                  {siteList.map((row) => (
                    
                      <TableRow key={row.siteid}>
                        <TableCell className={classes.tableCell} align="center" component="th" scope="row">{row.sitename}</TableCell>
                      </TableRow>
                    
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

          </Grid>

          <Grid container item xs={3} sm={4}>
            <TableContainer align="center" >
              <Table align="center" >
                
                  <TableHead>

                    <TableRow >
                      <TableCell className={classes.tableCell} align="center"> <Box fontSize={20} fontWeight="fontWeightBold"> Project </Box></TableCell>
                    </TableRow>

                  </TableHead>
                
                <TableBody>
                  {proj.map((row) => (
                    
                      <TableRow key={row.projectid} >
                        <TableCell className={classes.tableCell} align="center" component="th" scope="row">{row.projectname}</TableCell>
                      </TableRow>
                    
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid container item xs={3} sm={4}>
            <TableContainer >

              <Table >
                
                  <TableHead className={classes.tableHead}>

                    <TableRow>
                      <TableCell className={classes.tableCell} align="center" > <Box fontSize={20} fontWeight="fontWeightBold"> Task </Box></TableCell>
                    </TableRow>
                  </TableHead>
               

                <TableBody>
                  {task.map((row) => (
                    
                      <TableRow key={row.taskid}>
                        <TableCell className={classes.tableCell} align="center" component="th" scope="row" >{row.taskname}</TableCell>
                      </TableRow>
                  
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </CardContent>

    </Card>

  );
};



const mapStateToProps = (state) => ({


  menu: state.menu.menuItems,
  sb: state.menu.sidebar,
  proj: state.projRed.Project,
  siteList: state.sitecom.SiteList,
  task: state.taskRed.task,

})

export default connect(mapStateToProps, { fetchMenu, fetchSidebar, fetchProj, fetchSite, fetchTask })(DashboardDisplay);
