import {EDIT_VALUE ,FETCH_ROLES, COMPANY_DISPLAY, ROLENAMECHANGE , ADDITEMTOROLE , NEWCOMPANYNAME, FETCH_USERS } from '../Actions/types' ;


const initialState = {
    UserId: 0,
    UsrName : '',
    UsrSurname : '' ,
    UsrEmail :'',
    UsrIDNo : '' ,
    UsrUsername :'' ,
    UsrPassword : '',
    UsrRePass : '',
    UsrRole : 0 ,
    UsrCompany : 0 ,
    eerName : false ,
    eerSurname : false ,
    eerEmail :false ,
    eerUsername : false ,
    eerPassword : false ,
    eerRePassword : false ,
    eerIDNumber : false ,
    DialogRole : false ,
    DialogCompany : false ,
    Snack : false ,
    SnackNegative : false ,  
    RoleList : [] ,
    companyList : [] ,
    addItemRoleList:[],
    roleAdd : '',
    companyAdd : '' ,
    UserList: [],
    SnackUsername: false,
    isEditing : false , 

}


export default function red(state = initialState ,action){
    switch(action.type){

        case EDIT_VALUE : 
            //console.log(action.objName, action.payload);
            return{
              ...state , 
              [action.objName] : action.payload

            }

        case ROLENAMECHANGE : 
            return{
                ...state ,
                roleAdd:action.payload
            }
        
        case ADDITEMTOROLE : 
            return{

                ...state , 
                    addItemRoleList : action.payload

            }
           
        case NEWCOMPANYNAME:
            return{

                ...state , 
                    companyAdd : action.payload

            }

        case COMPANY_DISPLAY :
            return {

                    ...state ,
                    companyList : action.payload

            }
        case FETCH_ROLES : 
        return {

                ...state ,
                 RoleList : action.payload

        }
        case FETCH_USERS : 
        //console.log(action.payload);
        return {

                ...state ,
                 UserList : action.payload

        }
            default:
                 return state ; 

    }

}





