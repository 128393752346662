import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { saveState } from './Store/localstorage';
import stores from './Store/localstore';
import { throttle } from 'lodash';
import { Provider } from 'react-redux';
import swDev from './swDev';

stores.subscribe(throttle(() => {
  saveState({
    disabled: stores.getState().local
  })
}, 1000))

ReactDOM.render(
  <Provider store={stores}>
    <App />
  </Provider>
  ,

  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
swDev();
