import { ADDNSB, FETCHNSB } from "../Actions/types";

const initialState = {
    nsbid: 0,
    folderid: 0,
    foldername:'',
    nsbname: '',
    SnackAdd: false,
    SnackUpdate: false,
    SnackDelete: false,
    SnackNegative: false,
    nsb: [],
    AddDialog: false,
    UpdateDialog: false,
    DeleteDialog: false,
    AddFolderDialog: false,
    isEd: false,
}

export default function nsb(state = initialState, action) 
{
    switch (action.type) 
    {
        case ADDNSB:
            return{
            ...state,
            [action.objName] : action.payload
            }
        
            case FETCHNSB :
                return {
                ...state ,
                nsb : action.payload
                }

        default:
        return state;
    }
}