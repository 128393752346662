import { ADDSITE, FETCHSCOMP, FETCHSITE, FETCHSITESPEC } from '../Actions/types';

const initialState = {
    SiteID: 0,
    SiteName: '',
    SiteRef: '',
    CompanyID: 0,
    errSiteName: false,
    errSiteRef: false,
    SiteList: [],
    isEd: false,
    errCompID: false,
    Snack: false,
    SnackNegative: false,
    SnackPath: false,
    companyList: [],
    CustID: 0,
    dialogOpen: false,
    sitelist: [],
    Displaysite: false,
    opens: false,
    sitespec: [],
    activeRowId: null,
    status: [0],
    errStatusId: false,
    SnackFilter: false,
    dialogPreview: false,
}

export default function red(state = initialState, action) {
    switch (action.type) {
        case ADDSITE:
            return {
                ...state,
                [action.objName]: action.payload
            }
        case FETCHSCOMP:
            return {
                ...state,
                companyList: action.payload
            }
        case FETCHSITE:

            return {
                ...state,
                SiteList: action.payload
            }
        case FETCHSITESPEC:

            return {
                ...state,
                sitespec: action.payload
            }

        default:
            return state;
    }
}