import { MenuItem, Select, Container } from '@material-ui/core'
import React from 'react'
import { connect } from 'react-redux'
import {vehicelDetailsAdd} from '../../Actions/VehicleDetailsAction'

const vehicleGroup = ({arr , vehicleGroupChoice ,onCH,eVGID, vehicelDetailsAdd , isEd}) => {
  
    // const c = (e) => {

    //     console.log(e.target.value)
    //     console.log(e.target.name);
    //     console.log(arr);
    //     vehicelDetailsAdd(e.target.name , e.target.value)
    // }  
  
    return (
        <Container>

        <Select item xs={12}
        error={eVGID} 
        disabled={isEd}
        name="vehicleGroupId" value={vehicleGroupChoice} variant="standard" onChange={onCH} fullWidth={true} placeholder="Vehicle Group" >
                <MenuItem value={0}><em>None</em></MenuItem>
            {arr.map(x => (

                <MenuItem 
                key={x.vehicleGroupID} 
                value={x.vehicleGroupID}>

                     {x.description}
                     
                     </MenuItem>

            ))}
        </Select>
        </Container>
    )
}

const mapStateToProps = state => ({

    vehicleGroupChoice : state.vehicleDetail.vehicleGroupId , 
    arr : state.vehicleDetail.vVehicleGroup ,  
    eVGID : state.vehicleDetail.errvehicleGroupId,
    isEd  :state.vehicleDetail.isEditing


})


export default connect(mapStateToProps , {vehicelDetailsAdd})(vehicleGroup)
