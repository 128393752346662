import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import auth from "../Authentication/auth";
import RouteLayout from "../AllRoutes/RouteLayout";
import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import LockIcon from "@material-ui/icons/Lock";
import { connect } from "react-redux";
import {
  fetchMenu,
  fecthItems,
  fecthMI,
  fetchSidebar,
} from "../Actions/MenuActions";
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
}));

const Dashboard = ({
  menu,
  fetchMenu,
  fecthItems,
  fecthMI,
  sb,
  roleid,
  fetchSidebar,
}) => {

  const hist = useHistory();
  let { url } = useRouteMatch();
  const handleLeaving = () => {
    auth.logout(() => {
      hist.push("/");
      window.location.reload(false);
    });
  };

  const classes = useStyles();

  useEffect(() => {
    fecthItems();
    fecthMI();
    fetchMenu();
    fetchSidebar(JSON.parse(localStorage.getItem("userInfo")).roleID);

    return () => {};
  }, [fecthItems,fecthMI,fetchMenu,fetchSidebar]);

  return (
    <Container style={{ padding: 0 }}>
      <CssBaseline />
      <Navbar bg="primary" variant="dark" expand="lg">
        <Navbar.Brand href="/Dashboard">
          {JSON.parse(localStorage.getItem("userInfo")).username}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            {[...new Set(sb.map((x) => x.menuid))].map((id) => (
              <NavDropdown title={sb.find((x) => x.menuid === id).menuname}>
                {sb
                  .filter((x) => x.menuid === id)
                  .map((element) => (
                    <NavDropdown.Item
                      key={sb.filter((x) => x.menuid === id).id}
                      href={`${url}/${element.itemname.replaceAll(" ", "")}`}
                    >
                      {element.itemname}
                    </NavDropdown.Item>
                  ))}
              </NavDropdown>
            ))}
          </Nav>
        </Navbar.Collapse>
         
        <Link to="/ComStruct User Manual V 1.pdf" style={{ color:"inherit" }} target="_blank" >User Manual</Link>
        <IconButton color="inherit" onClick={handleLeaving}>
          <LockIcon />
        </IconButton>
        
      </Navbar>
      <div className={classes.root}>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <RouteLayout></RouteLayout>
          </Container>
        </main>
      </div>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  menu: state.menu.menuItems,
  items: state.menu.itemItems,
  miItems: state.menu.miItems,
  sb: state.menu.sidebar,
});

export default connect(mapStateToProps, {
  fetchMenu,
  fecthItems,
  fecthMI,
  fetchSidebar,
})(Dashboard);
