import {ADDTEAMMEM, FETCHTEAMMEM} from '../Actions/types';

const initialState = {
    TeamMemberID:0,
    MemName:"",
    MemSurname:"",
    MemDesc:"",
    errMemName: false,
    errMemeSurname: false,
    errMemDesc: false,
    TeamMem: [],
    isEd: false,
    Snack: false,
    SnackNegative: false,
    
}

export default function red(state = initialState, action){
    switch(action.type)
    {
        case ADDTEAMMEM:
            return{
            ...state,
            [action.objName] : action.payload
            }
        case FETCHTEAMMEM :
            return {
            ...state ,
            TeamMem : action.payload
            }
         
        default:
            return state;
    }
}